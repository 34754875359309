import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseAuthConfig = {
  apiKey: "AIzaSyBgYfVSvtGRc_OU9jRxKXGwkHkdKHXDFGQ",
  authDomain: "stupa-games.firebaseapp.com",
  projectId: "stupa-games",
  storageBucket: "stupa-games.appspot.com",
  messagingSenderId: "355502877981",
  appId: "1:355502877981:web:3c00977c5b1915e18dc5f4",
  measurementId: "G-5B2SGXVP5H",
};

const app = initializeApp(firebaseAuthConfig);
export const auth = getAuth(app);
