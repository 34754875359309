import React, { useEffect } from "react";
import "./WinPercentInfo.scss";

interface WinPercentInfoProps {
  closeInfo: () => void;
}

const WinPercentInfo: React.FC<WinPercentInfoProps> = ({ closeInfo }) => {
  return (
    <div className="win-percent-info-container p-20 flex-column gap-16">
      <p
        className="font-large bold"
        style={{ color: "#000000", textAlign: "center" }}
      >
        Win %
      </p>
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-close+2.svg"
        alt="close"
        width={10}
        height={10}
        className="close-btn"
        onClick={closeInfo}
      />
      <div className="info-details py-12 px-20 flex-column gap-24">
        <p className="font-mid bolder" style={{ color: "#5F5F5F" }}>
          How is Win % calculated?
        </p>
        <div className="flex-row gap-10 content align-items-center">
          <div className="flex-column gap-4 align-items-center">
            <p className="font-xsmall bold" style={{ color: "#000000" }}>
              Total Matches Won
            </p>
            <div
              style={{ width: "100%", height: "1px", background: "#E2E2E2" }}
            ></div>
            <p className="font-xsmall bold" style={{ color: "#000000" }}>
              Total Matches Played
            </p>
          </div>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240708060555-grey_close.svg"
            alt="greyCross"
            width={10}
            height={10}
          />
          <p className="font-large bold" style={{ color: "#3a3A3A" }}>
            100
          </p>
        </div>
      </div>
      <div className="inference p-6">
        <div className="flex-row gap-4 align-items-center">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707200640-Conclusion+Contract.svg"
            alt="contract"
            width={16}
            height={16}
          />
          <p className="font-xsmall bold" style={{ color: "#000000" }}>
            Inference
          </p>
        </div>
        <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
          To improve Win %, focus on winning more matches consistently.
        </p>
      </div>
      <div
        className="flex-column gap-4"
        style={{ marginTop: "16px", marginBottom: "20px" }}
      >
        <p className="font-small bolder" style={{ color: "#5F5F5F" }}>
          What is Win%?
        </p>
        <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
          Win percentage (Win%) is the ratio of matches won to total matches
          played.
        </p>
      </div>
    </div>
  );
};

export default WinPercentInfo;
