import { Suspense, useEffect, useState } from "react";
import AvailableRoutes from "./utils/AvailableRoutes";
import "./App.scss";
import "./utils/HelperClasses.scss";
import ApiService from "./services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, logOutUser } from "./redux/user/userActions";
import LoadScreen from "./components/LoadScreen/LoadScreen";
import { isProfileLoading, selectAuthToken } from "./redux/user/userSelectors";
import { getCookie } from "./services/cookieService";

function App() {
  const [loadSplash, setLoadSplash] = useState(true);
  const authToken = useSelector(selectAuthToken);
  const userLoading = useSelector(isProfileLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    const statusSub = ApiService().statusSubject.subscribe((res) => {
      if (res === 401) {
        dispatch(logOutUser());
      }
    });
    return () => {
      statusSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (authToken) {
      const court_number = window.location.pathname.substring(1);
      console.log("COURT_NUMBER:", court_number);
      if (court_number !== "" && !isNaN(Number(court_number))) {
        if (getCookie("MATCH_COURT_ID") !== court_number) {
          dispatch(logOutUser());
        }
        return;
      }
      dispatch(getUserDetails());
      setLoadSplash(true);
      setTimeout(() => {
        setLoadSplash(false);
      }, 5000);
    } else {
      setLoadSplash(false);
    }
  }, [authToken]);

  return (
    <div>
      {loadSplash || userLoading ? (
        <LoadScreen />
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <AvailableRoutes />
        </Suspense>
      )}
    </div>
  );
}

export default App;
