import React, { useEffect, useState } from "react";
import "./EngagementRate.scss";
import { WinningTrendMessage } from "../../data/WinningTrend.data";
import { Chart } from "chart.js/auto";

interface EngagementRateProps {
  openInfo: () => void;
  weeklyEngagementRateData: {
    period: string;
    engagement_rate: number;
    active_time: number;
  }[];
  monthlyEngagementRateData: {
    period: string;
    engagement_rate: number;
    active_time: number;
  }[];
}

const EngagementRate: React.FC<EngagementRateProps> = ({
  openInfo,
  weeklyEngagementRateData,
  monthlyEngagementRateData,
}) => {
  const user = {
    name: "Hemant",
    completionPercent: 50,
    completionStatus: "Completed",
  };
  const [winMessage, setWinMessage] = useState<{
    icon: string;
    message: string;
  }>();
  const [tab, setTab] = useState<"weekly" | "monthly">("weekly");
  const [engagementRateData, setEngagementRateData] = useState<
    { period: string; engagement_rate: number; active_time: number }[]
  >([]);

  useEffect(() => {
    if (tab === "weekly") {
      setWinMessage(WinningTrendMessage.weekly);
      setEngagementRateData(weeklyEngagementRateData);
    }
    if (tab === "monthly") {
      setWinMessage(WinningTrendMessage.monthly);
      setEngagementRateData(monthlyEngagementRateData);
    }
  }, [tab, weeklyEngagementRateData, monthlyEngagementRateData]);

  useEffect(() => {
    weeklyGraphRender();
  }, [engagementRateData]);

  const weeklyGraphRender = async () => {
    await clearChart();
    if (engagementRateData.length === 0) {
      return;
    }
    const data = engagementRateData.slice(0, 7);

    const canvas = document.getElementById("engagement-rate-graph");
    if (canvas) {
      const ctx = new Chart(canvas as HTMLCanvasElement, {
        type: "bar",
        data: {
          labels: data.map((row) => row.period),
          datasets: [
            {
              label: "Active",
              data: data.map((row) => row.engagement_rate),
              backgroundColor: "#97EF85",
              borderRadius: 12,
              barThickness: 9,
              borderSkipped: false,
            },
            {
              label: "Idle",
              data: data.map((row) =>
                row.active_time === 0 ? 0 : 100 - row.engagement_rate,
              ),
              backgroundColor: "#5F5F5F",
              borderRadius: 12,
              barThickness: 9,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              stacked: true,
              beginAtZero: true,
              max: 100,
              grid: {
                color: "#E2E2E2",
                tickColor: "transparent",
              },
              border: {
                display: true,
                dash: [8, 4],
                color: "transparent",
              },
              ticks: {
                color: "#AFAFAF",
                font: {
                  size: 10,
                  weight: 500,
                  family: "Poppins",
                },
              },
            },
            x: {
              stacked: true,
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
              ticks: {
                color: "#AFAFAF",
                font: {
                  size: 10,
                  weight: 500,
                  family: "Poppins",
                },
              },
            },
          },
        },
      });
    }
  };

  const clearChart = async () => {
    const chartStatus = Chart.getChart("engagement-rate-graph"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
  };

  return (
    <div className="engagement-rate-details-container px-12 py-6 flex-column align-items-center">
      <div className="backdrop-image" />
      <div className="flex-row align-items-end full-width">
        <div
          className="flex-row justify-content-space-between full-width"
          style={{ zIndex: "20" }}
        >
          <div className="flex-column gap-2">
            <div className="flex-row gap-12 align-items-center">
              <p className="font-large bold" style={{ color: "#222222" }}>
                Engagement Rate
              </p>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626091405-infop.svg"
                alt="info"
                width={12}
                height={12}
                onClick={openInfo}
              />
            </div>
            <div className="flex-row gap-8 align-items-center">
              <div className="flex-row gap-4 align-items-center">
                <div
                  className="legend-dot"
                  style={{ background: "#97EF85" }}
                ></div>
                <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                  Active Time
                </p>
              </div>
              <div className="flex-row gap-4 align-items-center">
                <div
                  className="legend-dot"
                  style={{ background: "#5F5F5F" }}
                ></div>
                <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                  Idle Time
                </p>
              </div>
            </div>
          </div>
          <div className="period-selector-container px-2 py-8 flex-row">
            <p
              className="font-xsmall bold px-10 py-6"
              style={{
                color: `${tab === "weekly" ? "#ffffff" : "#3A3A3A"}`,
                background: `${tab === "weekly" ? "#000000" : "#ffffff"}`,
                borderRadius: "25px",
              }}
              onClick={() => setTab("weekly")}
            >
              {tab === "weekly" ? "Weekly" : "W"}
            </p>
            <p
              className="font-xsmall bold px-10 py-6"
              style={{
                color: `${tab === "monthly" ? "#FFFFFF" : "#3A3A3A"}`,
                background: `${tab === "monthly" ? "#000000" : "#ffffff"}`,
                borderRadius: "25px",
              }}
              onClick={() => setTab("monthly")}
            >
              {tab === "monthly" ? "Monthly" : "M"}
            </p>
          </div>
        </div>
      </div>
      <div className="trend-graph-container">
        <canvas id="engagement-rate-graph"></canvas>
      </div>
      <div
        className="flex-row gap-18 align-items-center full-width border-box px-18 py-6"
        style={{
          background: "#EEEEEE",
          borderRadius: "10px",
          marginTop: "10px",
        }}
      >
        {winMessage && (
          <>
            <img src={winMessage.icon} width={26} height={26} />
            <p className="font-xsmall bold" style={{ color: "#5f5f5f" }}>
              {winMessage.message}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default EngagementRate;
