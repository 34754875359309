import React, { useEffect, useState } from "react";
import "./AboutSelf.scss";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";
import { useDispatch, useSelector } from "react-redux";
import { isUserLoading, selectUserName } from "../../redux/user/userSelectors";
import { updateUserDetails } from "../../redux/user/userActions";
import { setCookie } from "../../services/cookieService";
import ApiService from "../../services/apiService";
import ApiLoader from "../../components/ApiLoader/ApiLoader";

const AboutSelf: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUserName = useSelector(selectUserName);
  const [userName, setUserName] = useState(currentUserName || "");
  const loading = useSelector(isUserLoading);
  const [apiloading, seApiLoading] = useState(false);

  useEffect(() => {
    const loadingSub = ApiService().loadingSubject.subscribe((res) => {
      seApiLoading(res > 0);
    });
    return () => {
      loadingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!loading && userName !== "") {
      if (currentUserName != null) {
        navigate(PATHS.AVATAR);
      }
    }
  }, [loading, currentUserName]);

  const nameUpdate = () => {
    if (checked && userName.length > 0) {
      dispatch(updateUserDetails({ first_name: userName }));
    }
  };

  const openTermsCondition = () => {
    setCookie("USERNAME", userName);
    navigate(PATHS.TERMS_AND_CONDITIONS);
  };

  return (
    <div className="sign-in-container overflow-hidden">
      {apiloading && <ApiLoader position="top" />}
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617220257-Topology-1.svg"
        alt="top-right-pattern"
        className="top-right-pattern"
      />
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617220056-Topology-2.svg"
        alt="bottom-left-pattern"
        className="bottom-left-pattern"
      />
      <div className="top-row-details">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240611193440-Quote+1.svg"
          alt="quotes"
          width={94}
          height={64}
        />
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240705110302-Logo.webp"
          alt="stupa-logo"
          width={54}
        />
      </div>
      <div className="about-self-container">
        <div className="name-container">
          <p className="title-msg">TELL US ABOUT YOURSELF</p>
          <input
            className="name-input"
            placeholder="Enter your name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="btn-container">
          <div className="accept-criteria-container">
            {!checked && (
              <div className="checkbox" onClick={() => setChecked(true)}></div>
            )}
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240613150443-checkboc-filled.svg"
              alt="checked"
              width={20}
              height={20}
              style={{ display: `${checked ? "block" : "none"}` }}
              onClick={() => setChecked(false)}
            />
            <p className="accept-msg">
              I accept the terms and conditions{" "}
              <span style={{ color: "#62ABDF" }} onClick={openTermsCondition}>
                Read
              </span>
            </p>
          </div>
          <div
            className={`name-btn-container ${
              userName.length > 0 && checked && "active-name"
            }`}
            onClick={nameUpdate}
          >
            <p className="message">Next</p>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240612073505-right-arrow-white.svg"
              alt="right-arrow"
              width={20}
              height={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSelf;
