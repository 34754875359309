import React, { useEffect, useState } from "react";
import "./AddPlayerDoubles.scss";
import RecordMatchHeader from "../RecordMatchHeader/RecordMatchHeader";
import { formatTime, timeDisplay } from "../../utils/Helper";
import PlayerSelect from "../PlayerSelect/PlayerSelect";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { useSelector } from "react-redux";
import {
  selectUserAvatar,
  selectUserDetails,
  selectUserEmail,
} from "../../redux/user/userSelectors";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";
import { Court } from "../../models/Court";

interface Player {
  name: string | null;
  avatar: string;
  id?: number;
}

interface AddPlayerDoublesProps {
  setGame: () => void;
  matchId: number;
  court: Court | undefined;
}

const AddPlayerDoubles: React.FC<AddPlayerDoublesProps> = ({
  setGame,
  matchId,
  court,
}) => {
  const navigate = useNavigate();
  const user = useSelector(selectUserDetails);
  const [width, setWidth] = useState<number>(0);
  const [teamSelection, setTeamSelection] = useState("NONE");
  const [teamStatus, setTeamStatus] = useState<boolean[]>([false, false]);
  const [serverSelected, setServerSelected] = useState<{
    team: string;
    player: Player;
  }>();
  const [teams, setTeams] = useState<{ A: Player[]; B: Player[] }>({
    A: [],
    B: [],
  });
  const [teamIds, setTeamIds] = useState<{ A: number; B: number }>({
    A: -1,
    B: -1,
  });
  const [matchLength, setMatchLength] = useState<number>(0);
  const [matchTimer, setMatchTimer] = useState<NodeJS.Timer>();
  const [savePopup, setSavePopup] = useState(false);
  const [addScoresPopup, setAddScoresPopup] = useState(false);
  const [saveScoresPopup, setSaveScoresPopup] = useState(false);
  const [matchDate, setMatchDate] = useState<string>("");
  const [scoreCard, setScoreCard] = useState<{ A: string; B: string }[]>([
    { A: "", B: "" },
  ]);
  const [finalResult, setFinalResult] = useState<{ A: number; B: number }>({
    A: 0,
    B: 0,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState(0);
  const [triggerSelect, setTriggerSelect] = useState<"A" | "B" | undefined>();
  const [courtImages, _setCourtImages] = useState(
    court?.sport_id === 2
      ? {
          topView:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240725155111-Frame+1261158835+%281%29.svg",
          frontViewHalf:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240725155147-Group+1000004707.svg",
          frontViewFull:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240725155223-Group+1000004679.svg",
          serviceView:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240725155323-Group+1000004679+%281%29.svg",
          topCourt:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240725155404-2+%281%29.webp",
          bottomCourt:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240725155404-4+%281%29.webp",
        }
      : {
          topView:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617063235-Frame+1261158218.svg",
          frontViewHalf:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617063235-Group+1000004666.svg",
          frontViewFull:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617114937-Group+1000004666.svg",
          serviceView:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707175617-Court3.webp",
          topCourt:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707175617-Court1.webp",
          bottomCourt:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707175617-Court2.webp",
        },
  );

  useEffect(() => {
    const result: boolean[] = [false, false];
    if (teams.A[0]?.name != null && teams.A[1]?.name != null) {
      result[0] = true;
    }
    if (teams.B[0]?.name != null && teams.B[1]?.name != null) {
      result[1] = true;
    }
    if (result[0] && result[1]) {
      setTimeout(() => {
        setTeamSelection("COMPLETED");
      }, 1000);
    }
    setTeamStatus(result);
  }, [teams]);

  useEffect(() => {
    setTimeout(() => {
      setTeamSelection("TEAM_START");
    }, 1000);
    const elm = document.getElementById("court-details-container");
    const width = elm?.getBoundingClientRect().width;
    if (width) {
      setWidth(width - 80);
    }
    setTeams({
      A: [
        {
          name: null,
          avatar:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg",
        },
        {
          id: user.id || 1,
          name: "You",
          avatar: user.avatar || "",
        },
      ],
      B: [
        {
          name: null,
          avatar:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg",
        },
        {
          name: null,
          avatar:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg",
        },
      ],
    });
    return () => {
      if (matchTimer) {
        clearInterval(matchTimer);
      }
    };
  }, []);

  const selectPlayer = (players: Player[]) => {
    if (triggerSelect === "A") {
      setTeams((val) => {
        const A = [...val.A];
        A[0] = players[0];
        return { A, B: val.B };
      });
    }
    if (triggerSelect === "B") {
      setTeams((val) => {
        return { A: val.A, B: players };
      });
    }
    setTriggerSelect(undefined);
  };

  const addTeamA = () => {
    setTriggerSelect("A");
  };

  const addTeamB = () => {
    setTriggerSelect("B");
  };

  const switchPlayers = (team: string) => {
    setTeams((val) => {
      let A = [...val.A];
      let B = [...val.B];
      if (team === "A") {
        A = A.reverse();
      }
      if (team === "B") {
        B = B.reverse();
      }
      return { A, B };
    });
  };

  const switchSides = () => {
    setTeams((val) => {
      const A = [...val.A];
      const B = [...val.B];
      return { A: B, B: A };
    });
  };

  const proceedService = async () => {
    setTeamSelection("SERVICE_SELECT");
    try {
      const res = await ApiService().client.post(URL.MATCH.CREATE_TEAM, {
        match_id: matchId,
        team_A_data: {
          player_1_id: teams.A[0].id,
          player_1_name: teams.A[0].name,
          player_2_id: teams.A[1].id,
          player_2_name: teams.A[1].name,
        },
        team_B_data: {
          player_1_id: teams.B[0].id,
          player_1_name: teams.B[0].name,
          player_2_id: teams.B[1].id,
          player_2_name: teams.B[1].name,
        },
      });
      if (res.status === 200) {
        setTeamIds((val) => {
          return {
            ...val,
            A: res.data.data.team_A_id,
            B: res.data.data.team_B_id,
          };
        });
      }
    } catch (e) {
      console.log("Erorr:", e);
    }
  };

  const serviceSelection = (e: React.MouseEvent) => {
    const target = e.currentTarget.getBoundingClientRect();
    const middle = target.top + target.height * 0.38;
    if (e.clientY < middle) {
      setServerSelected({ team: "A", player: teams.A[0] });
    } else {
      setServerSelected({ team: "B", player: teams.B[1] });
    }
  };

  const startMatch = async () => {
    try {
      await ApiService().client.patch(
        URL.MATCH.UPDATE,
        {
          match_type: "DOUBLES",
          start_match: true,
        },
        {
          params: {
            match_id: matchId,
          },
        },
      );
    } catch (e) {
      console.log("Erorr:", e);
    }
    setTeamSelection("MATCH_STARTED");
    const interval = setInterval(() => {
      setMatchLength((len) => len + 1);
    }, 1000);
    setMatchTimer(interval);
    const options: any = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    const today = new Date();
    const date = today.toLocaleDateString("en-GB", options);
    const day = today.toLocaleDateString("en-GB", {
      weekday: "short",
    });
    setMatchDate(`${date} | ${day}`);
  };

  const stopMatch = () => {
    setSavePopup(true);
  };

  const saveScores = async () => {
    try {
      await ApiService().client.patch(
        URL.MATCH.UPDATE,
        {
          match_type: "DOUBLES",
          end_match: true,
        },
        {
          params: {
            match_id: matchId,
          },
        },
      );
    } catch (e) {
      console.log("Erorr:", e);
    }
    clearInterval(matchTimer);
    setSavePopup(false);
    setAddScoresPopup(true);
  };

  const deleteSet = (index: number) => {
    setScoreCard((val) => {
      const entry = [...val];
      entry.splice(index, 1);
      return entry;
    });
  };

  const updateSet = (index: number, team: "A" | "B", value: string) => {
    setScoreCard((val) => {
      const entry = [...val];
      entry[index][team] = value;
      return entry;
    });
  };

  const addSet = () => {
    if (scoreCard.at(-1)?.A !== "" && scoreCard.at(-1)?.B !== "") {
      setScoreCard((val) => [...val, { A: "", B: "" }]);
    }
  };

  const openSaveScore = () => {
    if (scoreCard.at(-1)?.A === "" || scoreCard.at(-1)?.B === "") {
      return;
    }
    setAddScoresPopup(false);
    setSaveScoresPopup(true);
    const aWon = scoreCard.filter(
      (entry) => parseInt(entry.A) > parseInt(entry.B),
    ).length;
    const bWon = scoreCard.filter(
      (entry) => parseInt(entry.B) > parseInt(entry.A),
    ).length;
    setFinalResult({ A: aWon, B: bWon });
  };

  const editScoreConfirm = () => {
    setAddScoresPopup(true);
    setSaveScoresPopup(false);
  };

  const saveScoreConfirm = async () => {
    const scores: any = [];
    scoreCard.forEach((row, index) => {
      if (row.A !== "" && row.B !== "") {
        scores.push({
          set_number: index + 1,
          set_score: [
            { team_id: teamIds.A, score: parseInt(row.A) },
            { team_id: teamIds.B, score: parseInt(row.B) },
          ],
        });
      }
    });
    try {
      const res = await ApiService().client.post(URL.MATCH.ADD_MATCH_SCORE, {
        match_id: matchId,
        match_score: scores,
      });

      if (res.status === 200) {
        setSaveScoresPopup(false);
        navigate(PATHS.EMAIL);
      }
    } catch (e) {
      console.log("Erorr:", e);
    }
  };

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    const elm = document
      .getElementById("start-match-container")
      ?.getBoundingClientRect();
    if (elm) {
      const left = elm.left;
      if (position > left - 32) {
        startMatch();
      } else {
        setPosition(0);
      }
    }
  };

  const handleMouseMove = (e: React.TouchEvent) => {
    if (isDragging) {
      const pos = e.touches[0].clientX - 12;
      const elm = document
        .getElementById("start-match-container")
        ?.getBoundingClientRect();
      if (elm) {
        const left = elm.left;
        if (pos > 0 && pos < left) {
          setPosition(pos);
        }
      }
    }
  };

  const backPress = () => {
    if (teamSelection === "TEAM_START") {
      if (!teamStatus[0]) {
        setGame();
      } else {
        setTeamStatus([false, false]);
        setTeams({
          A: [
            {
              name: null,
              avatar:
                "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg",
            },
            {
              id: user.id || 1,
              name: "You",
              avatar: user.avatar || "",
            },
          ],
          B: [
            {
              name: null,
              avatar:
                "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg",
            },
            {
              name: null,
              avatar:
                "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg",
            },
          ],
        });
      }
    }
    if (teamSelection === "COMPLETED") {
      setTeamSelection("TEAM_START");
      setTeams((val) => {
        const B = [
          {
            name: null,
            avatar:
              "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg",
          },
          {
            name: null,
            avatar:
              "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg",
          },
        ];
        return { A: val.A, B };
      });
    }
    if (teamSelection === "SERVICE_SELECT") {
      setServerSelected(undefined);
      setTeamSelection("COMPLETED");
    }

    if (teamSelection === "MATCH_STARTED") {
      setServerSelected(undefined);
      setTeamSelection("SERVICE_SELECT");
      setMatchLength(0);
      setPosition(0);
      clearInterval(matchTimer);
    }
  };

  return (
    <div
      className={`add-players-doubles-container flex-column ${
        teamSelection === "MATCH_STARTED" && "match-started"
      }`}
    >
      <RecordMatchHeader showBack={true} onBack={backPress} />
      <div className="title-container px-20 flex-column gap-8">
        {(teamSelection === "NONE" || teamSelection === "TEAM_START") && (
          <>
            <p className="make-team-msg bolder">Make your team</p>
            <p className="add-msg">Click and add players</p>
          </>
        )}
        {teamSelection === "COMPLETED" && (
          <p className="make-team-msg bolder">Make your team</p>
        )}
        {teamSelection === "SERVICE_SELECT" && (
          <>
            <p className="make-team-msg bolder">Select Service Court</p>
            {/* <p className="add-msg">Select the court that will serve first.</p> */}
          </>
        )}
        {teamSelection === "MATCH_STARTED" && (
          <>
            <p className="make-team-msg bolder">
              {addScoresPopup ? "Add your scores" : "Match is in progress.."}
            </p>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618074425-bottom-pattern.svg"
              alt="back-pattern"
              className="match-started-pattern"
            />
          </>
        )}
      </div>
      {teamSelection === "NONE" && (
        <div
          className="court-details-container px-32 flex-column align-items-center"
          id="court-details-container"
        >
          <img
            id="court-top-image"
            src={courtImages.topView}
            alt="court"
            className="court-top"
          />
          <div
            className="team-container py-12 flex-row align-items-center gap-16 justify-content-space-between px-12"
            style={{ width: `${width}px`, left: `calc(50% - ${width / 2}px)` }}
          >
            <div className="flex-column gap-4 align-items-center">
              <img
                src={teams.A[0]?.avatar || ""}
                alt="no-user"
                width={40}
                height={40}
              />
              {teams.A[0]?.name && (
                <p className="player-name">{teams.A[0].name}</p>
              )}
            </div>
            <div className="add-team-msg-container px-12 py-8 flex-row gap-4 align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617071343-Frame.svg"
                alt="add-user"
                width={30}
                height={30}
              />
              <p className="add-team-msg">Add Team A </p>
            </div>
            <div className="flex-column gap-4 align-items-center">
              <img
                src={teams.A[1]?.avatar}
                alt="no-user"
                width={40}
                height={40}
              />
              {teams.A[1]?.name && (
                <p className="player-name">{teams.A[1].name}</p>
              )}
            </div>
          </div>
          <div
            className="team-container team-b py-12 flex-row align-items-center gap-16 justify-content-space-between px-12"
            style={{ width: `${width}px`, left: `calc(50% - ${width / 2}px)` }}
          >
            <div className="flex-column gap-4 align-items-center">
              <img
                src={teams.B[0]?.avatar}
                alt="no-user"
                width={40}
                height={40}
              />
              {teams.B[0]?.name && (
                <p className="player-name">{teams.B[0].name}</p>
              )}
            </div>
            <div className="add-team-msg-container px-12 py-8 flex-row gap-4 align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617071343-Frame.svg"
                alt="add-user"
                width={30}
                height={30}
              />
              <p className="add-team-msg">Add Team B </p>
            </div>
            <div className="flex-column gap-4 align-items-center">
              <img
                src={teams.B[1]?.avatar}
                alt="no-user"
                width={40}
                height={40}
              />
              {teams.B[1]?.name && (
                <p className="player-name">{teams.B[1].name}</p>
              )}
            </div>
          </div>
        </div>
      )}
      {teamSelection !== "NONE" && (
        <div
          className={`select-team-container flex-row align-items-center justify-content-center ${
            teamSelection !== "TEAM_START" && "team-completed"
          }`}
        >
          {teamSelection === "COMPLETED" && (
            <p className="message">Perfect! All Players Added</p>
          )}
          {teamSelection === "TEAM_START" && (
            <p className="message">
              {teamStatus[0] ? "Great! Now Add Team B" : "First Add Team A"}
            </p>
          )}
          {teamSelection === "COMPLETED" && (
            <div
              className="switch-sides-container flex-row gap-6 px-8 py-6"
              onClick={switchSides}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617201449-Frame+1261158085.svg"
                alt="switchSides"
                width={20}
                height={12}
              />
              <p className="switch-text">Switch Sides</p>
            </div>
          )}
          {serverSelected && (
            <div className="selected-server-container flex-column gap-8 align-items-center">
              <div className="flex-row gap-4 align-items-center">
                <img
                  src={serverSelected.player.avatar}
                  alt="server"
                  width={30}
                  height={30}
                />
                <p className="server-name">{serverSelected.player.name}</p>
              </div>
              <p className="server-message">
                From Team {serverSelected.team} begins the service
              </p>
            </div>
          )}
          <div
            className={`team-container flex-row align-items-center gap-16 justify-content-space-between team-selection-half-a ${
              teamStatus[0] && "team-selection-full-a"
            } ${
              (teamSelection === "SERVICE_SELECT" ||
                teamSelection === "MATCH_STARTED") &&
              "service-select-a"
            }`}
          >
            <div className="flex-column gap-4 align-items-center">
              <img
                src={teams.A[0]?.avatar || ""}
                alt="user-1"
                width={40}
                height={40}
              />
              {teams.A[0]?.name && (
                <p className="player-name">{teams.A[0].name.split(" ")[0]}</p>
              )}
            </div>
            {!teamStatus[0] && (
              <div
                className="add-team-msg-container px-12 py-8 flex-row gap-10 align-items-center"
                onClick={addTeamA}
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617071343-Frame.svg"
                  alt="add-user"
                  width={30}
                  height={30}
                />
                <p className="add-team-msg">Add Team A</p>
              </div>
            )}
            {teamSelection === "COMPLETED" && (
              <div
                className="switch-container px-12 py-8 flex-row gap-10 align-items-center"
                onClick={() => switchPlayers("A")}
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617201449-Frame+1261158085.svg"
                  alt="switch-user"
                  width={40}
                  height={26}
                />
              </div>
            )}
            <div className="flex-column gap-4 align-items-center">
              <img
                src={teams.A[1]?.avatar || ""}
                alt="user-2"
                width={40}
                height={40}
              />
              {teams.A[1]?.name && (
                <p className="player-name">{teams.A[1].name.split(" ")[0]}</p>
              )}
            </div>
          </div>
          <div
            className={`team-container py-12 flex-row align-items-center gap-16 justify-content-space-between px-12 team-selection-half-b ${
              teamStatus[0] && "team-selection-full-b"
            } ${teamStatus[1] && "team-selection-b-completed"} ${
              (teamSelection === "SERVICE_SELECT" ||
                teamSelection === "MATCH_STARTED") &&
              "service-select-b"
            }`}
          >
            <div className="flex-column gap-4 align-items-center">
              <img
                src={teams.B[0]?.avatar || ""}
                alt="userb-1"
                width={40}
                height={40}
              />
              {teams.B[0]?.name && (
                <p className="player-name">{teams.B[0].name.split(" ")[0]}</p>
              )}
            </div>
            {!teamStatus[1] && (
              <div
                className="add-team-msg-container px-12 py-8 flex-row gap-10 align-items-center"
                onClick={addTeamB}
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617071343-Frame.svg"
                  alt="add-user"
                  width={30}
                  height={30}
                />
                <p className="add-team-msg">Add Team B</p>
              </div>
            )}
            {teamSelection === "COMPLETED" && (
              <div
                className="switch-container px-12 py-8 flex-row gap-10 align-items-center"
                onClick={() => switchPlayers("B")}
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617201449-Frame+1261158085.svg"
                  alt="switch-user"
                  width={40}
                  height={26}
                />
              </div>
            )}
            <div className="flex-column gap-4 align-items-center">
              <img
                src={teams.B[1]?.avatar || ""}
                alt="userb-2"
                width={40}
                height={40}
              />
              {teams.B[1]?.name && (
                <p className="player-name">{teams.B[1].name.split(" ")[0]}</p>
              )}
            </div>
          </div>
          {teamSelection === "TEAM_START" && (
            <>
              <img
                src={courtImages.frontViewFull}
                alt="front-view"
                className="front-view-court-full"
                style={{ display: `${teamStatus[0] ? "block" : "none"}` }}
              />
              <img
                src={courtImages.frontViewHalf}
                alt="front-view"
                className="front-view-court-half"
                style={{ display: `${teamStatus[0] ? "none" : "block"}` }}
              />
            </>
          )}
          {teamSelection === "COMPLETED" && (
            <>
              <img
                src={courtImages.serviceView}
                alt="service-view"
                className="service-view"
              />
              <div className="proceed-container">
                <div
                  className="proceed-btn flex-row justify-content-center"
                  onClick={proceedService}
                >
                  <p>Proceed</p>
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617193647-Frame.svg"
                    alt="right"
                    width={20}
                    height={20}
                  />
                </div>
              </div>
            </>
          )}
          {(teamSelection === "SERVICE_SELECT" ||
            teamSelection === "MATCH_STARTED") && (
            <>
              <img
                src={courtImages.serviceView}
                alt="service-view"
                className="service-view"
                onClick={serviceSelection}
                style={{
                  display: `${serverSelected?.team == null ? "block" : "none"}`,
                }}
              />
              <img
                src={courtImages.topCourt}
                alt="service-view"
                className="service-view"
                style={{
                  display: `${serverSelected?.team == "A" ? "block" : "none"}`,
                }}
                onClick={serviceSelection}
              />
              <img
                src={courtImages.bottomCourt}
                alt="service-view"
                className="service-view"
                onClick={serviceSelection}
                style={{
                  display: `${serverSelected?.team == "B" ? "block" : "none"}`,
                }}
              />
              {teamSelection === "SERVICE_SELECT" ? (
                <>
                  {serverSelected && (
                    <div className="start-recording-container flex-row align-items-center justify-content-space-between">
                      <div
                        className="recorder-container flex-row align-items-center justify-content-center"
                        style={{
                          transform: `translateX(${position}px)`,
                        }}
                        onTouchStart={handleMouseDown}
                        onTouchEnd={handleMouseUp}
                        onTouchMove={handleMouseMove}
                      >
                        <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617214315-recorder.svg"
                          alt="recorder"
                          width={20}
                          height={20}
                        />
                      </div>
                      <p className="start-match-msg">Start match recording</p>
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617214428-three_arrow.svg"
                        alt="arrow"
                      />
                      <div
                        className="start-container flex-row align-items-center justify-content-center"
                        id="start-match-container"
                      >
                        <div className="start"></div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="stop-recording-container flex-row align-items-center justify-content-space-between">
                  <p className="length-msg">{formatTime(matchLength)}</p>
                  <div
                    className="stop-container flex-row align-items-center justify-content-center"
                    onClick={stopMatch}
                  >
                    <div className="stop"></div>
                  </div>
                </div>
              )}
              {savePopup && (
                <div className="save-match-popup flex-column gap-33 align-items-center">
                  <p className="save-title">Save Match #{matchId}</p>
                  <p className="save-msg">
                    Are you sure you want to save this?
                  </p>
                  <div className="flex-row gap-12">
                    <p
                      className="px-18 py-14 dont-save-btn"
                      onClick={() => setSavePopup(false)}
                    >{`Don't Save`}</p>
                    <p className="px-18 py-14 save-btn" onClick={saveScores}>
                      Save
                    </p>
                  </div>
                </div>
              )}
              {addScoresPopup && (
                <div className="add-scores-popup flex-column gap-27 align-items-center">
                  <p className="add-score-title bold">Add Scores</p>
                  <div className="scoreset-container flex-column gap-10">
                    <div className="score-grid">
                      <div></div>
                      <p className="team-name">Team A</p>
                      <p className="team-name">Team B</p>
                      <div></div>
                    </div>
                    {scoreCard.map((entry, index) => (
                      <div className="score-grid set-entry" key={index}>
                        <p className="team-name">Set {index + 1}</p>
                        <input
                          className="score-input"
                          value={entry.A}
                          onChange={(e) =>
                            updateSet(index, "A", e.target.value)
                          }
                        />
                        <input
                          className="score-input"
                          value={entry.B}
                          onChange={(e) =>
                            updateSet(index, "B", e.target.value)
                          }
                        />
                        {index > 0 && (
                          <div
                            className="delete-container px-8 flex-row align-items-center justify-content-center"
                            onClick={() => deleteSet(index)}
                          >
                            <img
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618095809-delete_dustbin.svg"
                              alt="delete"
                              width={10}
                              height={13}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                    <div
                      className={`add-set-btn px-18 py-8 gap-8 flex-row align-items-center ${
                        scoreCard.at(-1)?.A !== "" &&
                        scoreCard.at(-1)?.B !== "" &&
                        "allow-add-set"
                      }`}
                      onClick={addSet}
                    >
                      <p>Add Set</p>
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618101041-plus_white.svg"
                        alt="plus"
                        width={12}
                        height={12}
                      />
                    </div>
                  </div>
                  <div className="continue-container">
                    <div
                      className={`continue-btn flex-row justify-content-center ${
                        scoreCard.at(-1)?.A !== "" &&
                        scoreCard.at(-1)?.B !== "" &&
                        "allow-add-set"
                      }
                      `}
                      onClick={openSaveScore}
                    >
                      <p>Continue</p>
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617193647-Frame.svg"
                        alt="right"
                        width={20}
                        height={20}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {saveScoresPopup && (
        <div className="save-scores-container flex-row align-items-center justify-content-center">
          <div className="display-score-container">
            <div className="header flex-row align-items-center py-8 px-16 gap-28">
              <img
                alt="padel-logo"
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-padel_park_logo.svg"
                width={30}
                height={18}
              />
              <p>PADDEL</p>
            </div>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-Racket.svg"
              alt="racket"
              className="racket-backdrop"
            />
            <div className="score-details-container px-30 flex-column gap-10">
              <div className="team-grid">
                <div className="flex-row align-items-center justify-content-center full-width">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-championship.svg"
                    alt="trophy"
                    width={24}
                    height={24}
                    style={{
                      display: `${
                        finalResult.A > finalResult.B ? "block" : "none"
                      }`,
                    }}
                  />
                </div>
                <div></div>
                <div className="flex-row align-items-center justify-content-center full-width">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-championship.svg"
                    alt="trophy"
                    width={24}
                    height={24}
                    style={{
                      display: `${
                        finalResult.B > finalResult.A ? "block" : "none"
                      }`,
                    }}
                  />
                </div>
              </div>
              <div className="team-grid">
                <div
                  className={`team-logo flex-row align-items-center justify-content-center ${
                    finalResult.A > finalResult.B && "winner-team"
                  }`}
                >
                  {(teams.A[0].name?.charAt(0) || "") +
                    teams.A[1].name?.charAt(0)}
                </div>
                <div className="score flex-row gap-2">
                  <span
                    className={`${
                      finalResult.B > finalResult.A && "loser-points"
                    }`}
                  >
                    {finalResult.A}
                  </span>
                  :
                  <span
                    className={`${
                      finalResult.A > finalResult.B && "loser-points"
                    }`}
                  >
                    {finalResult.B}
                  </span>
                </div>
                <div
                  className={`team-logo flex-row align-items-center justify-content-center ${
                    finalResult.B > finalResult.A && "winner-team"
                  }`}
                >
                  {(teams.B[0].name?.charAt(0) || "") +
                    teams.B[1].name?.charAt(0)}
                </div>
              </div>
              <div className="user-name-list flex-row">
                <div
                  className={`team-list flex-1 ${
                    finalResult.A > finalResult.B && "winner"
                  }`}
                >
                  <div className="player-container flex-row align-items-center gap-4">
                    <img
                      src={teams.A[0].avatar}
                      style={{ borderRadius: "50%" }}
                      width={28}
                      height={28}
                    />
                    <p className="player-name">{teams.A[0].name}</p>
                  </div>
                  <div className="player-container flex-row align-items-center gap-4">
                    <img
                      src={teams.A[1].avatar}
                      style={{ borderRadius: "50%" }}
                      width={28}
                      height={28}
                    />
                    <p className="player-name">{teams.A[1].name}</p>
                  </div>
                </div>
                <div className="team-list">
                  <div className="player-container flex-row align-items-center gap-4">
                    <img
                      src={teams.B[0].avatar}
                      style={{ borderRadius: "50%" }}
                      width={28}
                      height={28}
                    />
                    <p className="player-name">{teams.B[0].name}</p>
                  </div>
                  <div className="player-container flex-row align-items-center gap-4">
                    <img
                      src={teams.B[1].avatar}
                      style={{ borderRadius: "50%" }}
                      width={28}
                      height={28}
                    />
                    <p className="player-name">{teams.B[1].name}</p>
                  </div>
                </div>
              </div>
              <div className="set-score-list flex-column gap-8 p-8">
                {scoreCard.map((row, index) => (
                  <div className="set-score-grid" key={`set-score-${index}`}>
                    <p className={`score ${row.A > row.B && "winner"}`}>
                      {row.A}
                    </p>
                    <p className="set">SET {index + 1}</p>
                    <p className={`score ${row.B > row.A && "winner"}`}>
                      {row.B}
                    </p>
                  </div>
                ))}
              </div>
              <div className="snip-entry-container flex-row align-items-center justify-content-space-between">
                <div className="snip-entry flex-row gap-4 align-items-center ">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-calendar.svg"
                    alt="calendar"
                    width={12}
                    height={12}
                  />
                  <p className="entry-value">{matchDate}</p>
                </div>
                <div className="dot"></div>
                <div className="snip-entry flex-row gap-4 align-items-center">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-clock.svg"
                    alt="clock"
                    width={12}
                    height={12}
                  />
                  <p className="entry-value">{timeDisplay(matchLength)}</p>
                </div>
                <div className="dot"></div>
                <div className="snip-entry flex-row gap-4 align-items-center">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618190709-people.svg"
                    alt="people"
                    width={20}
                    height={20}
                  />
                  <p className="entry-value">XD</p>
                </div>
              </div>
            </div>
            <div className="confirm-score-container px-16 py-18 flex-column gap-12">
              <p className="confirm-msg">Confirm Scores?</p>
              <div className="btn-container flex-row gap-28">
                <p className="edit-btn py-14" onClick={editScoreConfirm}>
                  Edit
                </p>
                <p className="save-btn py-14" onClick={saveScoreConfirm}>
                  Save
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {triggerSelect && (
        <PlayerSelect
          gameType="DOUBLES"
          team={triggerSelect}
          submitPlayers={selectPlayer}
        />
      )}
    </div>
  );
};

export default AddPlayerDoubles;
