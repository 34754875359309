// Function to check if a value is null
export function isNull(value: unknown): boolean {
  return value == null;
}

// Function to check if a value is not null
export function isNotNull(value: unknown): boolean {
  return value != null;
}

// Function to check if a value is undefined
export function isUndefined(value: unknown): boolean {
  return typeof value === "undefined";
}

// Function to check if a value is not undefined
export function isNotUndefined(value: unknown): boolean {
  return typeof value !== "undefined";
}

// Function to check if a value is null or undefined
export function isNullOrUndefined(value: unknown): boolean {
  return value == null || typeof value === "undefined";
}

// Function to check if a value is not null or undefined
export function isNotNullOrUndefined(value: unknown): boolean {
  return value != null && typeof value !== "undefined";
}

export function round(value: number, digit: number): number {
  return Math.round(value * Math.pow(10, digit)) / Math.pow(10, digit);
}

export function FinalPrice(base_price: number, margin: number) {
  return (base_price * 100) / (100 - margin);
}

export function formatValue(value: number) {
  if (value === 0) {
    return "0";
  }
  if (value > 10000000) {
    return round(value / 10000000, 2) + " Cr";
  }
  if (value > 100000) {
    return round(value / 100000, 2) + " L";
  }
  return round(value / 1000, 2) + " K";
}

export function commaFormat(value: string) {
  let result = "";
  const commas = [3, 5, 7, 9, 11];
  let curIndex = 0;
  value
    .toString()
    .split("")
    .reverse()
    .forEach((val, index) => {
      if (index === commas[curIndex]) {
        result = result + "," + val;
        curIndex = curIndex + 1;
      } else {
        result = result + val;
      }
    });
  return result.split("").reverse().join("");
}

export const formatDate = (actualDelivery: string) => {
  if (actualDelivery == null) {
    return "";
  }
  const date = new Date(actualDelivery);
  const options = {
    day: "2-digit" as const,
    month: "2-digit" as const,
    year: "numeric" as const,
  };
  const formattedDate = date.toLocaleDateString("en-IN", options);
  return formattedDate.replace(/\//g, "-");
};

export const formatTime = (value: number) => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value - hours * 3600) / 60);
  const sec = value - hours * 3600 - minutes * 60;
  return `${doubleDigit(hours)}:${doubleDigit(minutes)}:${doubleDigit(sec)}`;
};

export const timeDisplay = (value: number, length = "full") => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value - hours * 3600) / 60);
  const sec = value - hours * 3600 - minutes * 60;

  if (hours > 0) {
    return `${
      length === "short" ? hours + "hrs" : doubleDigit(hours) + " hours"
    }`;
  }
  if (minutes > 0) {
    return `${
      length === "short" ? minutes + "m" : doubleDigit(minutes) + " minutes"
    }`;
  }
  return `${length === "short" ? sec + "s" : doubleDigit(sec) + " secs"}`;
};

export const doubleDigit = (val: number) => {
  if (val < 10) {
    return `0${val}`;
  }
  return val;
};

export function getTotalTime(time: string) {
  if (time === "") {
    return 0;
  }
  const val = time.split(":");
  return parseInt(val[0]) * 3600 + parseInt(val[1]) * 60 + parseInt(val[2]);
}
