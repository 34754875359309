import AboutSelf from "../pages/AboutSelf/AboutSelf";
import AvatarSelect from "../pages/AvatarSelect/AvatarSelect";
import AllMatches from "../pages/AllMatches/AllMatches";
import Analytics from "../pages/Analytics/Analytics";
import Dashboard from "../pages/Dashboard/Dashboard";
import Home from "../pages/Home/Home";
import Profile from "../pages/Profile/Profile";
import RecordMatch from "../pages/RecordMatch/RecordMatch";
import SignIn from "../pages/SignIn/SignIn";
import TermConditions from "../pages/TermConditions/TermConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import EmailConfirm from "../pages/EmailConfirm/EmailConfirm";

const routes = [
  {
    path: "/sign-in",
    Component: SignIn,
    protected: false,
  },
  {
    path: "/name",
    Component: AboutSelf,
    protected: true,
  },
  {
    path: "/avatar",
    Component: AvatarSelect,
    protected: true,
  },
  {
    path: "/email",
    Component: EmailConfirm,
    protected: true,
  },
  {
    path: "/record",
    Component: RecordMatch,
    protected: true,
  },
  {
    path: "/profile",
    Component: Profile,
    protected: true,
  },
  {
    path: "/matches",
    Component: AllMatches,
    protected: true,
  },
  {
    path: "/matches/:id",
    Component: Analytics,
    protected: true,
  },
  {
    path: "/dashboard",
    Component: Dashboard,
    protected: true,
  },
  {
    path: "/terms_and_conditions",
    Component: TermConditions,
    protected: true,
  },
  {
    path: "/privacy_policy",
    Component: PrivacyPolicy,
    protected: true,
  },
  {
    path: "/:court_number",
    Component: Home,
    protected: false,
  },
];

export default routes;
