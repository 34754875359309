import React, { useEffect, useState } from "react";
import "./RecordMatchLoader.scss";
import RecordMatchHeader from "../RecordMatchHeader/RecordMatchHeader";
import { useSelector } from "react-redux";
import { selectUserName } from "../../redux/user/userSelectors";
import { Court } from "../../models/Court";
import { getCookie } from "../../services/cookieService";

interface RecordMatchLoaderProps {
  completed: () => void;
  court: Court | undefined;
}

const RecordMatchLoader: React.FC<RecordMatchLoaderProps> = ({
  completed,
  court,
}) => {
  const [counter, setCounter] = useState(0);
  const [timerPlay, setTimerPlay] = useState(3);
  const [startPlay, setStartPlay] = useState(false);
  const userName = useSelector(selectUserName);

  useEffect(() => {
    let count = 0;
    const interval = setInterval(() => {
      if (count === 100) {
        setTimeout(() => {
          setStartPlay(true);
        }, 1000);
        clearInterval(interval);
      } else {
        setCounter((val) => val + 2);
        count = count + 2;
      }
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!startPlay) {
      return;
    }
    let count = 0;
    const interval = setInterval(() => {
      if (count === 2) {
        completed();
        clearInterval(interval);
      } else {
        setTimerPlay((val) => val - 1);
        count = count + 1;
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [startPlay]);

  return (
    <div className="record-match-loader-container">
      <RecordMatchHeader loader={false} />
      <div className="load-content">
        <div className="progress-loader">
          {counter < 100 && <p className="percent-completion">{counter}%</p>}
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240615183707-Vector+184.svg"
            alt="correct"
            style={{
              display: `${counter >= 100 ? "block" : "none"}`,
              position: "absolute",
            }}
            width={40}
            height={20}
          />
          <svg
            id="animated"
            xmlns="https://www.w3.org/2000/svg"
            version="1.1"
            width="228px"
            height="228px"
            className="loader-svg"
          >
            <defs>
              <linearGradient
                id="GradientColorLoader"
                gradientTransform="rotate(38)"
              >
                <stop offset="0%" stopColor="#9af089" />
                <stop offset="100%" stopColor="#e6fce2" />
              </linearGradient>
            </defs>
            <defs>
              <filter
                id="f1"
                x="-40%"
                y="-40%"
                width="180%"
                height="180%"
                filterUnits="userSpaceOnUse"
              >
                <feGaussianBlur
                  in="SourceAlpha"
                  stdDeviation="8"
                  result="blur1"
                />
                <feFlood floodColor="#7ced66" result="color" />
                <feComposite
                  in="color"
                  in2="blur1"
                  operator="in"
                  result="sombra"
                />
                <feOffset dx="5" dy="5" result="offsetblur" />
                <feOffset dx="-5" dy="-5" result="offsetblur" />
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
            </defs>
            <circle
              id="progress"
              cx="114"
              cy="114"
              r="89"
              className="progress-circle"
              style={{ filter: "url(#f1)" }}
            ></circle>
          </svg>
        </div>
        {counter < 100 && (
          <p className="user-message">
            {userName}, we are getting the court ready for you
          </p>
        )}
        {counter >= 100 && (
          <div className="arena-result-container flex-row align-items-center gap-20">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240616082555-pink+location+marker.svg"
              alt="map-mark"
              width={29}
              height={26}
            />
            <div className="flex-column gap-8">
              <div className="arena flex-row gap-8 align-items-center">
                <div className="dot"></div>
                <p>You are at {court?.client_name}</p>
              </div>
              <div className="arena flex-row gap-8 align-items-center">
                <div className="dot"></div>
                <p>Court {parseInt(getCookie("MATCH_COURT_ID") || "")}</p>
              </div>
            </div>
          </div>
        )}
        {!startPlay && (
          <div className="check-footer">
            {counter >= 0 && counter < 50 && (
              <p className="load-msg">Detecting Cameras..</p>
            )}
            {counter >= 50 && counter < 100 && (
              <p className="load-msg">Loading your court..</p>
            )}
            {counter >= 100 && (
              <div className="check-completed-msg">
                <div className="check-msg">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240615184445-Vector.svg"
                    alt="checked"
                    width={8}
                    height={8}
                  />
                  <p className="load-msg">Camera Working</p>
                </div>
                <div className="check-msg">
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240615184445-Vector.svg"
                    alt="checked"
                    width={8}
                    height={8}
                  />
                  <p className="load-msg">Court Located</p>
                </div>
              </div>
            )}
            {counter < 100 && (
              <p className="load-page">{Math.ceil(counter / 50)}/2</p>
            )}
          </div>
        )}
        {startPlay && (
          <div className="loader-footer">
            <div className="loading-footer"></div>
            <div className="loader-footer-left">
              <div className="loader-footer-timer-circle">{timerPlay}</div>
              <p className="loader-footer-msg">
                Proceed to setting up your team
              </p>
            </div>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240615190434-Frame+1261158599.svg"
              alt="triple-arrow"
              width="44"
              height={20}
              style={{ zIndex: "20" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RecordMatchLoader;
