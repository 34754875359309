import React, { useEffect, useState } from "react";
import "./Profile.scss";
import ProfileAvatar from "../../components/ProfileAvatar/ProfileAvatar";
import ProfileInfo from "../../components/ProfileInfo/ProfileInfo";
import ProfileAdditionalInfo from "../../components/ProfileAdditionalInfo/ProfileAdditionalInfo";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser, updateUserDetails } from "../../redux/user/userActions";
import {
  isUserLoading,
  selectUserDetails,
} from "../../redux/user/userSelectors";
import { UserProfile } from "../../models/User";
import { UpdateUserDetailsPayload } from "../../redux/user/userTypes";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";
import ApiService from "../../services/apiService";
import ApiLoader from "../../components/ApiLoader/ApiLoader";

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isUserLoading);
  const user = useSelector(selectUserDetails);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<UserProfile>({
    image_url: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    age: "",
    gender: null,
    country_code: "",
    weight: "165",
    height: "60",
  });
  const [apiloading, seApiLoading] = useState(false);

  useEffect(() => {
    const loadingSub = ApiService().loadingSubject.subscribe((res) => {
      seApiLoading(res > 0);
    });
    return () => {
      loadingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setUserDetails({
        image_url:
          user.avatar ||
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618071656-9.webp",
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        phone_number: user.mobile || "",
        email: user.email || "",
        age: user.age?.toString() || "",
        gender: user.gender,
        country_code: user.countryCode || "",
        weight: user.weight?.toString() || "",
        height: user.height?.toString() || "",
      });
    }
  }, [isLoading]);

  const onSaveHandler = () => {
    const result: UpdateUserDetailsPayload = {};
    if (userDetails.image_url !== user.avatar && userDetails.image_url != "") {
      result.image_url = userDetails.image_url;
    }
    if (
      userDetails.first_name !== user.first_name &&
      userDetails.first_name != ""
    ) {
      result.first_name = userDetails.first_name;
    }
    if (
      userDetails.last_name !== user.last_name &&
      userDetails.last_name != ""
    ) {
      result.last_name = userDetails.last_name;
    }
    if (
      userDetails.phone_number !== user.mobile &&
      userDetails.phone_number != null
    ) {
      result.phone_number = userDetails.phone_number;
    }
    if (
      userDetails.email !== user.email &&
      userDetails.email != null &&
      userDetails.email !== ""
    ) {
      result.email = userDetails.email;
    }
    if (userDetails.age !== user.age?.toString() && userDetails.age != null) {
      result.age = parseInt(userDetails.age);
    }
    if (userDetails.gender !== user.gender && userDetails.gender != null) {
      result.gender = userDetails.gender;
    }
    if (
      userDetails.country_code !== user.countryCode &&
      userDetails.country_code != null
    ) {
      result.country_code = userDetails.country_code;
    }
    if (
      userDetails.weight !== user.weight?.toString() &&
      userDetails.weight != null
    ) {
      result.weight = parseInt(userDetails.weight);
    }
    if (
      userDetails.height !== user.height?.toString() &&
      userDetails.height != null
    ) {
      result.height = parseInt(userDetails.height);
    }
    dispatch(updateUserDetails(result));
  };

  const editDetail = (key: string, value: string) => {
    setUserDetails((val) => {
      return { ...val, [key]: value };
    });
  };

  const logOut = () => {
    dispatch(logOutUser());
  };

  return (
    <>
      {!isLoading && (
        <div className="profile-wrapper flex-column">
          <div className="profile-header overflow-hidden">
            {
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617103535-Vector+%282%29.svg"
                className="back-btn"
                width={8}
                height={14}
                alt="back"
                onClick={() => navigate(PATHS.DASHBOARD)}
              />
            }

            <p className="title">Profile</p>
            {apiloading && <ApiLoader />}
          </div>
          <div className="profile-container">
            <ProfileAvatar details={userDetails} editDetail={editDetail} />
            <div className="profile-info-wrapper">
              <ProfileInfo details={userDetails} editDetail={editDetail} />
            </div>

            <div className="additional-info-wrapper">
              <ProfileAdditionalInfo
                details={userDetails}
                editDetail={editDetail}
              />
            </div>
            <div className="logout-container flex-column gap-16">
              <p className="font-small bold">Account</p>
              <div
                className="flex-row gap-10 px-16 py-6 align-items-center"
                style={{ background: "#EEEEEE", borderRadius: "10px" }}
                onClick={logOut}
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240706065112-Frame+%282%29.svg"
                  alt="logout"
                  width={20}
                  height={20}
                />
                <p className="font-small bold" style={{ color: "#DE1135" }}>
                  Log Out
                </p>
              </div>
            </div>
          </div>

          <div className="save-profile">
            <div className="save-profile-cta" onClick={onSaveHandler}>
              Save
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
