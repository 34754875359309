import React, { useEffect, useState } from "react";
import "./AddNewPlayer.scss";
import { Player } from "../../models/Player";
import CountryCodeSelector from "../CountryCodeSelector/CountryCodeSelector";
import { AVATAR_IMAGES } from "../../data/Avatar.data";
import { Country } from "../../models/Country";

interface AddNewPlayerProps {
  saveNewPlayer: (player: Player) => void;
  back: () => void;
}

const AddNewPlayer: React.FC<AddNewPlayerProps> = ({ saveNewPlayer, back }) => {
  const [isGuest, setIsGuest] = useState(false);
  const [allowAdd, setAllowAdd] = useState(false);
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState(AVATAR_IMAGES[0]);

  useEffect(() => {
    setAllowAdd(detailCompleted());
  }, [mobile, gender, countryCode, name, avatar, isGuest]);

  const detailCompleted = () => {
    if (isGuest) {
      if (gender !== "" && name.length > 3) {
        return true;
      }
    } else {
      if (gender !== "" && name.length > 3 && mobile !== "") {
        return true;
      }
    }
    return false;
  };

  const submitPlayerHandler = () => {
    if (detailCompleted()) {
      saveNewPlayer({
        name,
        avatar,
        gender,
        mobile,
        countryCode,
        id: 10 + Math.round(Math.random() * 100),
      });
    }
  };

  const nameEditHandler = (e: any) => {
    setName(e.target.value);
  };

  const toggleGuest = () => {
    if (isGuest) {
      setName("");
    } else {
      setName("Guest");
    }
    setIsGuest((val) => !val);
  };

  const codeSet = (val: Country) => {
    setCountryCode(val.prefix);
  };

  return (
    <div className="add-new-player-container flex-column gap-16">
      <div className="top-row flex-row align-items-center justify-content-center">
        <p>Add New Player</p>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617103535-Vector+%282%29.svg"
          className="back-btn"
          width={8}
          height={14}
          alt="back"
          onClick={back}
        />
      </div>
      <div className="px-20 flex-column gap-16 full-width border-box">
        <div className="guest-container px-18 py-8 full-width border-box flex-row align-items-center justify-content-space-between">
          <div className="flex-column gap-4">
            <p className="title bold">Add as Guest</p>
            <p className="subtitle">
              Skip sharing phone.no to us and get added as a Player
            </p>
          </div>
          <div
            className={`${isGuest && "is-guest"} guest-slider`}
            onClick={toggleGuest}
          >
            <div className="point"></div>
          </div>
        </div>

        <div className="gender-container flex-column gap-8">
          <p className="title">Gender</p>
          <div className="flex-row gap-30">
            <div
              className={`gender-btn flex-row align-items-center justify-content-center ${
                gender === "M" && "selected"
              }`}
              onClick={() => setGender("M")}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240701150840-Group+1000004664.svg"
                alt="male-sign"
                className="gender-sign"
              />
              <div className="flex-row gap-8 align-items-center justify-content-center">
                <p className="name">M</p>
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620200839-User+Male.svg"
                  alt="male"
                  width={25}
                  height={25}
                />
              </div>
            </div>
            <div
              className={`gender-btn flex-row align-items-center justify-content-center  ${
                gender === "F" && "selected"
              }`}
              onClick={() => setGender("F")}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240701150848-Group+1000004665.svg"
                alt="female-sign"
                className="gender-sign"
              />
              <div className="flex-row gap-8 align-items-center justify-content-center">
                <p className="name">F</p>
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620200839-Female+User.svg"
                  alt="female"
                  width={25}
                  height={25}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="info-container flex-column gap-8">
          <p className="title">Basic Info</p>
          {!isGuest && (
            <div className="flex-row gap-8">
              <CountryCodeSelector codeSet={codeSet} />
              <input
                className="mobile-number-container"
                value={mobile}
                type="number"
                placeholder="Mobile Number"
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
          )}
          <div className="name-container">
            <input
              className="name"
              value={name}
              onChange={nameEditHandler}
              placeholder="Name"
            />
            {name.length > 3 && (
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620203143-correct.svg"
                alt="tick"
                className="correct-logo"
                width={20}
                height={20}
              />
            )}
          </div>
        </div>

        <div className="avatar-container flex-column gap-8">
          <p className="title">Avatar</p>
          <div className="full-width flex-row gap-20 avatar-selector-container">
            {AVATAR_IMAGES.map((url, index) => (
              <div
                className={`${avatar === url && "selected"} avatar`}
                key={index}
              >
                <img
                  src={url}
                  width={52}
                  height={52}
                  onClick={() => setAvatar(url)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {isGuest && (
        <div className="flex-row guest-msg gap-8 px-20 border-box">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620203143-correct.svg"
            alt="correct"
          />
          <p>
            Player added as guest. This player wont receive any analytics data.
          </p>
        </div>
      )}
      <div className="add-player-confirm-container px-20 py-16">
        <div
          className={`proceed-select-cta flex-row gap-8 justify-content-center ${
            allowAdd && "allow-add"
          }`}
          onClick={submitPlayerHandler}
        >
          <p>Add Player</p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617193647-Frame.svg"
            alt="right"
            width={20}
            height={20}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewPlayer;
