import React from "react";
import "./TermConditions.scss";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";
import { useDispatch } from "react-redux";
import { getCookie } from "../../services/cookieService";
import { updateUserDetails } from "../../redux/user/userActions";

const TermConditions: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const nameUpdate = () => {
    const userName = getCookie("USERNAME");
    dispatch(updateUserDetails({ first_name: userName }));
    navigate(PATHS.AVATAR);
  };

  return (
    <div className="terms-container flex-column">
      <div className="terms-header">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710071743-stupalogo.webp"
          alt="logo"
          width={54}
          className="logo"
        />
        <p className="font-xlarge bold terms-title">Terms & Conditions</p>
      </div>
      <div className="terms-content p-20 flex-1 font-small flex-column gap-10">
        <p>{`This Site/ Application/ Services is/are operated /provided by Hsquare Sports Private Limited (“Hsquare”) operating under the brand name Stupa Sports Analytics (“Us” or “Our” or “We” or “Stupa Sports Analytics”). We operate and are engaged in organising sports and other recreational events as well as providing an internet-based booking platform for and in connection with Our as well as third party sports and other events such as sports fitness session(s), sports coaching/outdoor fitness sessions, sports related training activities and sports facilities and venues through Our Site , either by Our self or through Our subsidiaries, affiliates or third-party service providers (“Primary Services”). On our Site We provide a community-driven ‘Groups & Games’ feature, that allows Customers to connect with ‘groups’ and participate in sports games being organized near their locality (“Secondary Services”). These Terms of Use (“User Terms”) are a legal contract between you and Us.`}</p>
        <p>{`Subject to the terms and conditions mentioned in these User Terms, you are hereby granted a non- exclusive, limited, non-transferable, non-sublicensable license, freely revocable license to use the Service for your personal, non-commercial use only and as permitted by the features of the Service. Stupa Sports Analytics reserves all rights not expressly granted herein in the Service (as detailed below).`}</p>
        <p>{`These User Terms apply to your visit to and use, of the Site whether through a computer or a mobile phone or any other device, the Service, as well as to all information, recommendations and/or services provided to you on or through the Site and the Service.`}</p>
        <p>{`This document is an electronic record in terms of Information Technology Act, 2000 and rules thereunder as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.`}</p>
        <p>{`By successfully registering your account with us, you are consenting to be bound by these User Terms. Please ensure that you read and understand all these User Terms before you use the Site. If you do not accept any of the User Terms, then please do not use the Site or avail any of the Services being provided therein. Your agreement to these User Terms shall operate as a binding agreement between you and Stupa Sports Analytics in respect of the use and services of the Site.`}</p>
        <p>
          Your acceptance of the User Terms shall be deemed to include your
          acceptance of the privacy policy available{" "}
          <span
            className="bold"
            style={{ color: "#62ABDF", textDecoration: "underline" }}
            onClick={() => navigate(PATHS.PRIVACY)}
          >
            here
          </span>
          . By accepting these User Terms, you also allow Stupa Sports Analytics
          to send you promotional emails and SMS alerts from time to time.
        </p>
        <ol className="main-point-list">
          <li>
            <div className="flex-column">
              <p className="bolder">DEFINITIONS</p>
              <p>
                All of the defined and capitalized terms in these User Terms
                will have the meaning assigned to them here below
              </p>
            </div>
            <ol className="sub-point-list">
              <li>
                Account shall mean the account created by or for the Customer on
                the Site for availing the Services.
              </li>
              <li>
                Additional Fee shall mean any toll duty, inter-state taxes, etc.
                as may not be included in the Order Value but payable to any
                third party / government authorities for availing Services under
                Applicable Law.
              </li>
              <li>
                Applicable Law(s) shall mean and include all applicable
                statutes, enactments, acts of legislature or parliament, laws,
                ordinances, rules, by-laws, regulations, notifications,
                guidelines, policies, directions, directives and orders of any
                governmental authority, tribunal, or a court of India.
              </li>
              <li>
                Application shall mean the mobile application Stupa Sports
                Analytics, owned and updated by Hsquare from time to time.
              </li>
              <li>
                Convenience Fee shall mean the fee payable by the Customer to
                Stupa Sports Analytics for the Services i.e., for availing the
                technology services offered by Stupa Sports Analytics.
                Convenience Fee shall be chargeable on every Order made through
                the Site. The Convenience Fee shall be exclusive of all
                applicable taxes on the Convenience Fee, if any.
              </li>
              <li>
                Cancellation Fee shall mean the fee payable towards cancellation
                of an Order by a Customer in terms of Clause 7 of these User
                Terms. The Cancellation Fee shall be exclusive of all applicable
                taxes on the Cancellation Fee, if any.
              </li>
              <li>Customer/ you means a person who has an Account.</li>
              <li>
                E-Wallet shall mean a pre-paid instrument, which can be used to
                make payments on the Site.
              </li>
              <li>
                Force Majeure Event shall mean any event arising due to any
                cause beyond the reasonable control of Stupa Sports Analytics.
              </li>
              <li>
                Stupa Sports Analytics Pass means a pass or reservation (whether
                in physical or electronic form, as permitted under law), being
                purchased by any person through the Site and that allows the
                holder thereof, subject to certain terms and conditions, access,
                attend and participate to any sport/sport activity/fitness
                session, on the date, time period and for the sport as
                identified in such pass or reservation booked through Site, at
                the venue(s)/fitness centre of the Partner;
              </li>
              <li>
                Order shall mean the order placed by a Customer to avail the
                Services facilitated through the Site.
              </li>
              <li>
                Order Value shall mean such amount in Indian Rupees, which is
                reflected on the Site, as the amount payable for a specific
                Order. The Order Value shall be exclusive of all applicable
                taxes on the Order Value, if any.
              </li>
              <li>
                Registration Data shall mean and may include the present, valid,
                true and accurate name, email ID, phone number and such other
                information as may be required by Stupa Sports Analytics from
                the Customer from time to time for registration on the
                Application.
              </li>
              <li>
                Services shall mean a joint reference to the Primary Services
                and the Secondary Services.
              </li>
              <li>
                Site shall mean the Application, the website owned and operated
                by Stupa Sports Analytics and any website which is owned and
                developed by Us for Our specific partner/third party service
                provider to give such partner an exclusive platform for its
                listing of its events/sport sessions etc. or any other software
                that enables the use of the Application or such other URL or any
                other booking platform as may be specifically provided by Stupa
                Sports Analytics.
              </li>
              <li>
                Total Fee shall mean and include the Order Value, the
                Convenience Fee, the Cancellation Fee and Additional Fee and
                taxes as may be applicable from time to time.
              </li>
              <li>
                Venue Membership Plan means a membership plan, as offered by
                respective venue partner and being purchased by any person
                through the Site, whereby such person, subject to terms and
                conditions of such plan, can book and access specific
                venue/fitness centre of the venue/vendor partner engaged by
                Stupa Sports Analytics for playing any particular sport/sport
                activity, as identified in such plan, facilitated/provided at
                the venue of the partner or if applicable , attend/ participate
                in any fitness session or other allied services and products
                facilitated/provided at such venue of the partner and being
                identified in such plan.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">ELIGIBILITY</p>
            <ol className="sub-point-list">
              <li>
                You will be eligible to use the Services only if You are
                competent to enter into a binding contract under Applicable
                Laws.
              </li>
              <li>
                If you reside in a jurisdiction that restricts the use of the
                Service because of age or restricts the ability to enter into
                contracts such as this User Terms due to age, Your use of
                Services shall bind Your legal guardian to these User Terms.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">REGISTRATION AND ACCOUNT</p>
            <ol className="sub-point-list">
              <li>
                In order to access certain password-restricted areas of the Site
                and to use certain Services offered through the Site, you are
                required to successfully register an Account with Us.
              </li>
              <li>
                You understand and acknowledge that you can register on the Site
                only after complying with the requirements of this Clause III
                and by entering your Registration Data.
              </li>
              <li>
                You shall ensure that the Registration Data provided by you is
                accurate, complete, current, valid and true and is updated from
                time to time. We shall bear no liability for false, incomplete,
                old or incorrect Registration Data provided by you.
              </li>
              <li>
                You are solely responsible for maintaining the confidentiality
                of your Registration Data and will be liable for all activities
                and transactions that occur through your Account, whether
                initiated by you or any third party. Your Account cannot be
                transferred, assigned or sold to a third party. We shall not be
                liable for any loss that you may incur as a result of someone
                else using your password or Account, either with or without your
                knowledge.
              </li>
              <li>
                We reserve the right to suspend or terminate your Account with
                immediate effect and for an indefinite period, if we have a
                reason to believe that the Registration Data or any other data
                provided by you is incorrect or false, or that the security of
                your Account has been compromised in any way, or for any other
                reason We may find just or equitable.
              </li>
              <li>
                Except for the Registration Data or any other data submitted by
                you during the use of any Service offered through Site
                (“Permitted Information”), Stupa Sports Analytics does not want
                you to, and you should not, send any confidential or proprietary
                information to Stupa Sports Analytics on the Site or otherwise,
                unless otherwise is required by Applicable Laws. In accepting
                these User Terms you agree that any information or material that
                you or individuals acting on your behalf provide to Stupa Sports
                Analytics other than the Permitted Information will not be
                considered confidential or proprietary.
              </li>
              <li>
                It is your responsibility to check to ensure that you download
                the correct Application for your device. We are not liable if
                you do not have a compatible mobile device or if you download
                the wrong version of the Application for your mobile device. We
                reserve the right to terminate the Service and the use of the
                Application should you be using the Service or Application with
                an incompatible or unauthorized device.
              </li>
              <li>
                We allow you to open only one Account in association with the
                Registration Data provided by you. In case of any unauthorized
                use of your Account please immediately reach Us at
                <span style={{ color: "#62ABDF" }}>
                  {` support@stupaanalytics.com `}
                </span>
              </li>
              <li>
                In case, you are unable to access your Account, please inform Us
                at
                <span style={{ color: "#62ABDF" }}>
                  {` support@stupaanalytics.com `}
                </span>
                and make a written request for blocking your Account. We will
                not be liable for any unauthorized transactions made through
                your Account prior to the expiry of 72 (seventy-two) hours after
                you have made a request in writing for blocking your Account and
                shall not have any liability in case of Force Majeure Event.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">SERVICES</p>
            <ol className="sub-point-list">
              <li>
                You may order Our Services using Our Site. You will be charged
                the applicable fees set forth in the schedule of pricing, as may
                be prescribed from time to time.
              </li>
              <li>
                The Total Fee for the Services will be provided through the Site
                at the time the Services are ordered. You shall be subject to
                terms regarding minimum quantities to be ordered and minimum
                service fees, as detailed on the Site.
              </li>
              <li>
                Due to reasons such as Applicable Law, and limitations, the
                Services may not be available in all locations within a service
                area. We reserve the right to decline the Services for any
                reason in Our discretion. You will not be charged in such
                circumstances.
              </li>
              <li>
                By using the Site for availing the Service, you further agree
                that:
                <ol className="sub-point-list">
                  <li>
                    you will only use the Service or download the Application
                    for your sole personal use and will not resell or assign it
                    to a third party;
                  </li>
                  <li>
                    you will not use an Account that is subject to any rights of
                    a person other than you without appropriate authorization;
                  </li>
                  <li>
                    you will not try to harm the Service, Site or our network in
                    any way whatsoever;
                  </li>
                  <li>
                    you will provide Stupa Sports Analytics with such
                    information and documents which Stupa Sports Analytics may
                    reasonably request for and in relation to performance of
                    Services by Stupa Sports Analytics;
                  </li>
                  <li>
                    you will only use an authorized network to avail the
                    Service;
                  </li>
                  <li>
                    you are aware that when requesting Services, whether by
                    message, via Site or calling the call centre of Stupa Sports
                    Analytics, standard messaging charges, data charges, voice
                    charges, as applicable, of your and Our phone network
                    service providers, will apply;
                  </li>
                  <li>
                    you will comply with all Applicable Law from your country of
                    domicile and residence and the country, state and/or city in
                    which you are present while using the Site or Service;
                  </li>
                  <li>
                    you are aware of and shall comply with the Information
                    Technology Act, 2000 and the rules, regulations and
                    guidelines notified thereunder;
                  </li>
                  <li>
                    you must use our Services according to these User Terms. If
                    you violate these User Terms, we may take action with
                    respect to your account, including disabling or suspending
                    your account and, if we do, you agree not to create another
                    account without our permission. Disabling or suspending your
                    account will be in accordance with the &quot;Term and
                    Termination&quot; section below; and
                  </li>
                  <li>
                    you must access and use our Services only for legal,
                    authorized, and acceptable purposes. You will not use (or
                    assist others in using) our Services in ways that: (a)
                    violate, misappropriate, or infringe the rights of Stupa
                    Sports Analytics, our users, or others, including privacy,
                    publicity, intellectual property, or other proprietary
                    rights; (b) are illegal, obscene, defamatory, threatening,
                    intimidating, harassing, hateful, racially or ethnically
                    offensive, or instigate or encourage conduct that would be
                    illegal or otherwise inappropriate, such as promoting
                    violent crimes, endangering or exploiting children or
                    others, or coordinating harm; (c) involve publishing
                    falsehoods, misrepresentations, or misleading statements;
                    (d) impersonate someone; (e) involve sending illegal or
                    impermissible communications such as bulk messaging,
                    auto-messaging, auto-dialing, and the like; or (f) involve
                    any non-personal use of our Services unless otherwise
                    authorized by Us;
                  </li>
                  <li>
                    you are responsible for keeping your device and your account
                    safe and secure, and you must notify us promptly of any
                    unauthorized use or security breach of your account or our
                    Services
                  </li>
                </ol>
              </li>
              <li>
                Stupa Sports Analytics reserves the right to immediately
                terminate the Service and the use of the Application or booking
                on the Site in the event of non-compliance with any of the above
                requirements. Further, Stupa Sports Analytics will store the
                information provided by you or record your calls for contacting
                you for all Service related matters. You shall promptly inform
                Stupa Sports Analytics on any change in the information provided
                by you.
              </li>
              <li>
                Stupa Sports Analytics or an authorised representative of Stupa
                Sports Analytics, may provide information regarding Services,
                discounts and promotions provided by Stupa Sports Analytics to
                you by way of an SMS or email to your registered mobile
                number/registered email ID. You also have the option to
                discontinue receiving such information at any point of time. In
                order to discontinue receiving such information, you may at any
                point of contact at
                <span style={{ color: "#62ABDF" }}>
                  {` support@stupaanalytics.com `}
                </span>
                to discontinue the same.
              </li>
              <li>
                You agree to grant Stupa Sports Analytics a non-exclusive,
                worldwide, perpetual, irrevocable, royalty-free, sub-licensable
                (through multiple tiers) right to exercise the copyright,
                publicity, host, store, use, copy, display, reproduce, adapt,
                publish, database rights or any other rights you have in your
                information, Posted Content (as defined below) and Accessed
                Content (as defined below) in any media now known or not
                currently known, with respect to your information. You agree and
                permit Stupa Sports Analytics to share your information and/or
                Permitted Information, with third parties.
              </li>
              <li>
                Stupa Sports Analytics will be entitled to enter into any tie-up
                in terms of joint-venture or otherwise with any other
                institution engaged in the business of providing services
                analogous and/or similar to those herein contained. In such
                case, depending upon the modality and the mechanism as may be
                devised, you will be provided with the Services by Stupa Sports
                Analytics jointly and/or severally with the party/ies in joint
                venture. You hereby give your irrevocable consent and permission
                to such a tie-up. In the event of such a tie-up, the terms and
                conditions herein contained will, mutatis mutandis, become
                applicable in respect of such tie-up arrangement also.
              </li>
              <li>
                You will also have the option to purchase Stupa Sports Analytics
                Passes and/or Venue Membership Plan through Site for
                attending/participating/accessing any of our Services, subject
                to terms and conditions more particularly set out on the Site
                being applicable at the time of your purchase. In this
                connection, you may note that any access to the classes/
                sessions/events at venue centre(s), identified under your
                respective Stupa Sports Analytics Pass or Venue Membership Plan
                shall be subject to the availability and shall be accessible on
                first come first serve basis. You understand and acknowledge
                that you agree to all the terms and conditions (including
                cancellation and/or refund terms) of your pass/membership plan
                as detailed on the Site and shall strictly abide to the same
                while making your bookings through the Site.
              </li>
              <li>
                You agree that Stupa Sports Analytics retains an unconditional
                right to modify or amend terms and conditions in relation to
                your Stupa Sports Analytics Pass and/or Venue Membership Plan
                with prior notification to you via email/SMS or on the Mobile
                Application itself. Your continued use of the Site following the
                posting of such changes, shall constitute your consent and
                acceptance of those changes.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">CONFIRMATION OF ORDERS</p>
            <ol className="sub-point-list">
              <li>
                Stupa Sports Analytics shall, upon receiving the Order request
                from you in the manner set out above, proceed to confirm or
                decline the Order based on the ability to provide Service which
                shall be informed to you vide an SMS or email. In the event the
                Order is confirmed, you shall check the Order details and if
                there is any incorrect detail, the same needs to be informed to
                us immediately at
                <span style={{ color: "#62ABDF" }}>
                  {` support@stupaanalytics.com `}
                </span>
              </li>
              <li>
                You shall bear the consequences and damages for any delay that
                may be caused to you due to your failure to check the
                confirmation SMS or email or failure to inform Stupa Sports
                Analytics of the incorrect details immediately.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">PAYMENT</p>
            <ol className="sub-point-list">
              <li>
                Stupa Sports Analytics shall charge Total Fee for the Service
                which shall be determined and amended at the sole and absolute
                discretion of Stupa Sports Analytics.
              </li>
              <li>
                You shall be required to pay such Additional Fee, which will
                form part of the receipt of the Total Fee.
              </li>
              <li>
                You shall be required to pay such Cancellation Fee in terms of
                Clause VII, which will form part of the receipt of the Total
                Fee.
              </li>
              <li>
                In addition to the Total Fee, you may be required to pay such
                other charges incurred by you for availing the Service in cash,
                which are not included in the receipt of the Total Fee.
              </li>
              <li>
                The Total Fee will be collected by Stupa Sports Analytics from
                you at the time of placing an order for the Service. However, it
                shall be the venue partner&apos;s responsibility to issue a tax
                invoice to you after receipt of payment for the Order.
              </li>
              <li>
                All applicable taxes in respect of the Order Value, Convenience
                Fee, Additional Fee, Cancellation Fee shall be borne and payable
                by you.
              </li>
              <li>
                Stupa Sports Analytics may enable you (on behalf of one or more
                additional co-Customers) to split the payment of the Total Fees
                for an Order between you and the other additional co-Customer
                (each a “Co-Payer”) (the “Spilt Payment Service”). In order to
                participate in the Spilt Payment Service, each Co-Payer must
                have or register an Account on Site prior to making a payment of
                their respective share of the booking amount through the Site or
                in cash (or any other mode) to the venue owner (including its
                authorised personnel) where the right to
                play/attend/participate/access any sports event/session etc. is
                exercised. The web link that would enable each Co-Payer to make
                the payment of their respective share will be send by Stupa
                Sports Analytics at their registered email address, mobile
                number or via any other contact details being registered with
                Stupa Sports Analytics (either directly provided by Co-Payer or
                by any other User to Stupa Sports Analytics). It is clarified
                that once the Order is placed on the Site then it shall be your
                sole responsibility that the Total Fees is given to Stupa Sports
                Analytics and/or the venue owner (as stated above). In case, any
                Co-Payer fails to pay their respective share then neither you
                and the Co-Payer would be entitled to
                play/access/attend/participate any sports event/session etc. for
                which the Order is placed on the Site.
              </li>
              <li>
                Any payment related issue, except when such issue is due to an
                error or fault in the Site, shall be resolved between you and
                the Payment Processor/PG. Stupa Sports Analytics shall not be
                responsible for any unauthorized use of your E-Wallet or Card
                Details during or after availing the Services on the Site.
              </li>
              <li>
                Except to the extent otherwise required by Applicable Law, and
                as mentioned in Clause VI (i) above, Stupa Sports Analytics is
                not liable for any payments authorized through the Site using
                your Card Details. Particularly, Stupa Sports Analytics is not
                liable for any payments that do not complete because: (1) your
                payment card or bank account or E-Wallet does not contain
                sufficient funds to complete the transaction or the transaction
                exceeds the credit limit in respect of the payment details
                provided; (2) You have not provided Stupa Sports Analytics with
                correct payment details; (3) Your payment card has expired; or
                (4) circumstances beyond Stupa Sports Analytics&apos;s control
                (such as, but not limited to, power outages, interruptions of
                cellular service, or any other interferences from an outside
                force) preventing the execution of the transaction.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">CANCELLATION AND REFUND POLICY</p>
            <ol className="sub-point-list">
              <li>
                Stupa Sports Analytics does not set cancellation and refund
                policies for the events listed on behalf of the third-party
                service providers on its Site. We only take the responsibility
                that the third-party service providers must communicate its
                refund policy to you through our Site. The sole responsibility
                to issue refunds in accordance therewith shall be of such
                third-party service provider/seller of products. Consequently,
                your requests for refund of your Order should be directed to the
                third-party service providers with whom you make a booking
                through Our Site and in no event shall be directed to Stupa
                Sports Analytics. Any refund-related issues or disputes shall be
                strictly between you and the third-party service providers.
                Stupa Sports Analytics shall not be liable for any
                refund-related claims in connection with Services.
              </li>
              <li>
                In case you wish to cancel booking of an Order for a Service
                provided by a third-party service provider on Stupa Sports
                Analytics, then the booking amount shall be refunded as per the
                refund policy for that specific venue or that specific event or
                that specific Service.
              </li>
              <li>
                In addition to the above-mentioned cancellation charges, the
                refund amount shall be subject to deduction of bank charges i.e.
                3% of the amount paid.
              </li>
              <li>
                For refund/cancellation of events, venues and Services organized
                directly by Stupa Sports Analytics:
                <ol className="sub-point-list">
                  <li>
                    You agree and acknowledge that once an Order has been
                    successfully placed, you shall not be entitled to cancel
                    your Order under any circumstances.
                  </li>
                  <li>
                    You further agree and acknowledge that all the fees paid to
                    Stupa Sports Analytics shall be non-refundable, non-
                    saleable, non-exchangeable, non-transferable and
                    non-adjustable under any circumstances.
                  </li>
                  <li>
                    In case an event or venue directly organized by Stupa Sports
                    Analytics is cancelled, you will be issued a voucher for the
                    full amount of booking which can be redeemed against any
                    future bookings in Stupa Sports Analytics’s Site within six
                    months from the date of cancellation.
                  </li>
                </ol>
              </li>
              <li>
                Refund for Order due to failed online ticket booking will be
                processed within 7-10 working days.
              </li>
              <li>
                Stupa Sports Analytics reserves the right to change/modify this
                cancellation and refund policy without any prior notice.
              </li>
              <li>
                With regards to payments, the Site/Stupa Sports Analytics shall
                not be responsible for any unauthorised transactions conducted
                on our Site using your payment card or internet banking or any
                other method. The Site shall not be obligated to refund any
                money to you in such instances.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">CUSTOMER RELATIONSHIP MANAGEMENT</p>
            <ol className="sub-point-list">
              <li>
                All issues, complaints, opinions, suggestions, questions and
                feedback while availing our Services shall be communicated
                through email address
                <span style={{ color: "#62ABDF" }}>
                  {` support@stupaanalytics.com `}
                </span>
                . You agree to be fair, accurate and non-disparaging while
                leaving comment, feedbacks, testimonials or reviews on or about
                the Services.
              </li>
              <li>
                Reporting of any issue needs to be within 7 (seven) days of the
                happening of the issue, failing which, such issue may not be
                addressed.
              </li>
              <li>
                You are competent to enter into a contract under the Applicable
                Laws.
              </li>
              <li>
                Any issue reported on channels other than the above may be
                addressed by Stupa Sports Analytics only on a best-effort basis.
                Stupa Sports Analytics takes no liability for inability to get
                back on other channels. Stupa Sports Analytics shall endeavour
                to respond to your issues within 7 working days of your
                reporting the same and endeavour to resolve it at the earliest
                possible. It is hereby clarified that issues are resolved on
                severity basis, and certain may be resolved earlier than the
                other. However, Stupa Sports Analytics shall not be liable for
                any damages or losses in the event you are not satisfied with
                any such resolution.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">FORCE MAJEURE</p>
            <p>
              Stupa Sports Analytics shall not be liable for any failure to
              perform any obligations under this User Terms, if the performance
              is prevented, hindered or delayed by a Force Majeure Event and in
              such case our obligations under this User Terms shall be suspended
              for so long as the Force Majeure Event continues.
            </p>
          </li>
          <li>
            <p className="bold">INDEMNIFICATION</p>
            <ol className="sub-point-list">
              <li>
                By accepting these User Terms and using the Service, you agree
                that you shall defend, indemnify and hold Stupa Sports
                Analytics, its affiliates, its licensors, and each of their
                officers, directors, other users, employees, attorneys and
                agents (“Indemnified Party”) harmless from and against any and
                all claims, costs, damages, losses, liabilities, obligations,
                penalties, actions, judgements, suits, proceedings, expenses and
                disbursement of any kind or nature whatsoever (including
                attorneys&apos; fees and costs and the defense, appeal and
                settlement of any and all suits, actions or proceedings
                instituted or threatened) arising out of or in connection with:
                <ol className="sub-point-list">
                  <li>
                    your violation or breach of any term of these User Terms or
                    any Applicable Law or regulation, whether or not referenced
                    herein;
                  </li>
                  <li>your violation of any rights of any third party;</li>
                  <li>your use or misuse of the Site or Service;</li>
                  <li>
                    any misrepresentation with respect to the data or
                    information provided by you;
                  </li>
                  <li>
                    failure to perform (either in whole or part) any obligations
                    or responsibility including self-care required to be
                    performed and ensured by you while availing the Services;
                  </li>
                  <li>
                    any photographs, illustrations, graphics, audio clips, video
                    clips, text, data or any other information, content,
                    display, or material (whether written, graphic, sound, or
                    otherwise) provided by you to Us and/or a claim that the
                    Stupa Sports Analytics&apos;s use of such content infringes
                    the intellectual property rights or privacy rights of a
                    third party;
                  </li>
                  <li>
                    any claim that your data or information or content or
                    photograph or any material (whether written, graphic, sound,
                    or otherwise) caused damage to a third party or affect their
                    rights under Applicable Law.
                  </li>
                </ol>
              </li>
              <li>
                The right to indemnity of the Indemnified Party under this User
                Terms is separate and in addition to all such other rights that
                the Indemnified Party may have under Applicable Law or in equity
                or otherwise including but not limited to the right to seek
                damages and specific performance.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">LIABILITY</p>
            <ol className="sub-point-list">
              <li>
                The information, recommendations and/or Services provided to you
                on or through the Site and call centre are for general
                information purposes only and does not constitute advice. Stupa
                Sports Analytics will reasonably keep the Site and its contents
                correct and up to date but does not guarantee that (the contents
                of) the Site is free of errors, defects, malware and viruses or
                that the Site is correct, up to date and accurate.
              </li>
              <li>
                Stupa Sports Analytics shall not be liable for any damages
                resulting from the use of or inability to use the Site,
                including damages caused by wrong usage of the Site, error in
                call centre number, network issues, malware, viruses or any
                incorrectness or incompleteness of any information on the Site.
              </li>
              <li>
                Stupa Sports Analytics does not assure a complete sustainability
                of its Service and shall not be held responsible or liable for
                the same, in any manner.
              </li>
              <li>
                Stupa Sports Analytics shall not be responsible for any loss of
                communication / information of status update and benefits under
                the program. All this information will be sent on mobile number
                and/or email ID registered with Stupa Sports Analytics. Stupa
                Sports Analytics will not be responsible for appropriateness of
                mobile or email or any other communication medium. You shall be
                responsible for immediately reporting the errors, if any,
                occurred in the information sent to you regarding Order
                confirmation.
              </li>
              <li>
                In case of availing Split Payment Service, if any part of the
                Total Fees remains unpaid by a Co-payer while placing the Order,
                then, it shall be your sole responsibility to pay the Total Fees
                to Us and/or Our partner’s at the venue, as the case may be.
              </li>
              <li>
                In no event shall Stupa Sports Analytics be liable for any
                direct, indirect, punitive, incidental, special or consequential
                damages or for any damages whatsoever, whether based on
                contract, tort, negligence, strict liability or otherwise, even
                if Stupa Sports Analytics has been advised of the possibility
                thereof.
              </li>
              <li>
                In addition, and without limiting the foregoing, to the fullest
                extent permitted by Applicable Law, in no event will Stupa
                Sports Analytics’s aggregate liability arising out of or in
                connection with these User Terms or the services rendered
                hereunder, whether in contract, tort (including negligence,
                product liability or other theory), warranty, or otherwise,
                exceed the amount of Order Value.
              </li>
              <li>
                If Applicable Law does not permit the exclusion of certain
                warranties or the limitation or exclusion of liability, the
                scope and duration of such warranty exclusions and the extent of
                the liability of Stupa Sports Analytics shall be the minimum
                permitted under Applicable Law.
              </li>
              <li>
                You understand and agree that any material or data or content
                downloaded or otherwise obtained through the Site is done
                entirely at your own discretion and risk, and that you will be
                solely responsible for any damage to your computer systems or
                loss of data that results from the download of such material or
                data.
              </li>
              <li>
                You acknowledge and agree that for the Secondary Services Stupa
                Sports Analytics’s involvement is restricted to suggesting
                Customers of relevant ‘groups’ to connect with other players and
                find games. Stupa Sports Analytics hereby disclaims all
                liability with regard to quality of such games, events, safety
                and precautions undertaken at such events, security maintained,
                etc.
              </li>
              <li>
                You acknowledge and agree that access to Primary Services are
                facilitated through vendor(s) and the Stupa Sports Analytics
                Site does not have any control over the services, operations or
                management of the Primary Services or any products provided by
                our venue and/or vendor partners. We/Site hereby disclaims any
                and all liability arising out of your use of the Primary
                Services.
              </li>
              <li>
                We/Site hereby disclaims any guarantees of exactness as to the
                duration, type, satisfaction from any of the Services provided
                by Us and/or Our vendor/venue partners respectively. Further, We
                make no representation that any of the Services provided by Us
                and/or Our vendor/venue partners are appropriate or safe for
                use. You agree that the Services offered herein, by their very
                nature, include certain inherent risks that cannot be eliminated
                regardless of the care taken to avoid injuries (including death)
                and/or any kind of losses. You hereby assert that Your
                participation is voluntary and that You knowingly assume all
                such risks and hence hereby release Stupa Sports Analytics, Site
                and its vendor/venue partners of all liability arising out of
                such aforementioned risks.
              </li>
              <li>
                You acknowledge and agree to maintain decorum at all times while
                using any of our venues and not to engage in any activities
                which are illegal and/or altercations with any other persons
                using the venue at any time. You hereby assert that You will be
                solely responsible for engaging in such acts and/or altercations
                at the venue and hence hereby release Stupa Sports Analytics,
                Site and its vendor/venue partners of all liability arising out
                of such aforementioned circumstances. In an event Your Order
                comprises of a group of individuals, each of such individuals
                shall be bound by these User Terms.
              </li>
              <li>
                You confirm and agree that in addition to acceptance of these
                User Terms, your use and/or booking of the Services through the
                Site signifies your acceptance of any additional terms and
                conditions, guidelines, house rules, etc. posted, communicated
                or displayed on the venue centres of the third-party service
                provider. To the fullest extent permissible pursuant to
                applicable law, your use of the Services and your attendance at,
                participation in, purchase and/or use of the Services, is solely
                at your own risk and the We/Site does not assume any liability
                or make any warranties of any kind, express or implied, arising
                out of, in connection with or with respect to the Services.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">APPLICATION LICENSE</p>
            <ol className="sub-point-list">
              <li>
                Subject to your compliance with these User Terms, Stupa Sports
                Analytics grants you a limited, revocable, non-exclusive,
                non-transferable and non-sub-licensable license to download and
                install a copy of the Application on a single mobile device that
                you own or control and to run such copy of the Application
                solely for your own personal use and to use the Site.
              </li>
              <li>
                You shall not (i) license, sublicense, sell, resell, transfer,
                assign, distribute or otherwise commercially exploit or make
                available to any third party the Service or Site in any way;
                (ii) modify or make derivative works based upon the Service or
                Site; (iii) create internet links to the Service or frame or
                mirror the Site on any other server or wireless or
                internet-based device; (iv) reverse engineer or access the Site
                in order to design or build a competitive product or service,
                design or build a product using similar ideas, features,
                functions or graphics of the Service or Site, or copy any ideas,
                features, functions or graphics of the Service or Site; or (v)
                launch an automated program or script, including, but not
                limited to, web spiders, web crawlers, web robots, web ants, web
                indexers, bots, viruses or worms, or any program which may make
                multiple server requests per second, or unduly burdens or
                hinders the operation and/or performance of the Service or Site.
              </li>
              <li>
                You shall not: (i) send spam or otherwise duplicative or
                unsolicited messages in violation of Applicable Laws; (ii) send
                or store infringing, obscene, threatening, libellous, or
                otherwise unlawful or tortious material, including material
                harmful to children or violation of third party privacy rights;
                (iii) send or store material containing software viruses, worms,
                Trojan horses or other harmful computer code, files, scripts,
                agents or programs; (iv) interfere with or disrupt the integrity
                or performance of the Site, or Service or the data contained
                therein; or (v) attempt to gain unauthorized access to the Site
                or Service or its related systems or networks.
              </li>
              <li>
                Stupa Sports Analytics will have the right to investigate and
                prosecute violation of any of the above to the fullest extent of
                the law. Stupa Sports Analytics may involve and cooperate with
                law enforcement authorities in prosecuting users who violate
                these User Terms. You acknowledge that Stupa Sports Analytics
                has no obligation to monitor your access to or use of the Site,
                Service, Posted Content or Accessed Content of yourself or other
                Customers but has the right to do so for the purpose of
                operating the Site and Service, to ensure your compliance with
                these User Terms, or to comply with Applicable Law or the order
                or requirement of a court, administrative agency or other
                Governmental body. Stupa Sports Analytics reserves the right, at
                any time and without prior notice, to remove or disable access
                to any content that Stupa Sports Analytics, at its sole
                discretion, considers to be in violation of these User Terms or
                otherwise harmful to the Site or the Service or its goodwill or
                acceptable business practices.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">CONTENT POSTED AND SHARED BY CUSTOMERS/US</p>
            <ol className="sub-point-list">
              <li>
                Stupa Sports Analytics may accept posting of any notes,
                messages, e-mails, photos, drawings, profiles, opinions, ideas,
                images, videos, audio files or other materials or information
                given by you on the Site (“Posted Content”) by you. Any success
                stories, comments, articles, testimonials, blogs, scribbles etc.
                posted/ uploaded/ conveyed/ communicated by you on the news feed
                or any dedicated place of the Site will also be considered as
                Posted Content. We may publish such Posted Content on our Site
                so long as you consent. You may request that such Posted Content
                be taken down at any time and we shall remove such Posted
                Content. You represent that you have obtained all permissions
                and consents required to post the Posted Content and such Posted
                Content complies with all requirements of the Posted Content and
                Applicable Law. Stupa Sports Analytics shall not in any manner
                be responsible for any actions taken by third-parties/you in
                relation to the Posted Content. Stupa Sports Analytics does not
                claim ownership of the information that you submit for your
                Stupa Sports Analytics account or through our Services. You must
                have the necessary rights to such information that you submit
                for your Stupa Sports Analytics account or through our Services
                and the right to grant the rights and licenses in these User
                Terms.
              </li>
              <li>
                Stupa Sports Analytics may offer you the opportunity to get your
                photograph clicked (either single photo or in group with other
                users) or audio-videos may be made/created during a
                class/session in fitness centres/venues or at an event or during
                usage of any third-party websites or applications while taking
                fitness session or attending any event using or through Our
                Primary Services (“Photo & Videos”) , as decided by Stupa Sports
                Analytics at its sole discretion. Such Photos and Videos may be
                clicked/made/captured by the venue/centre managers, or any
                individual authorised by Stupa Sports Analytics’s personnel or
                venue managers and shall be accessed and used in the manner
                stated herein below:
                <ol className="sub-point-list">
                  <li>
                    Access to the Photos and Videos: You may access the Photos
                    and Videos from your Account on the Site and may be allowed
                    to tag yourself in the photos. We grant you a revocable,
                    limited, non-exclusive, non-transferable, personal,
                    non-commercial license to access and use the Photo and Video
                    according to User Terms. You acknowledge that the Photos and
                    Videos shall be accessible to you and other users of the
                    Site and to those users who have attended the said sports
                    session/class/event/activity. The said users may be allowed
                    to download or take a screenshot of the Photos and Videos.
                    In the event, you choose to tag yourself in the photos, your
                    username shall be visible/accessible to other users and
                    attendees of the session. Stupa Sports Analytics disclaims
                    any and all liabilities with respect to the misuse, loss,
                    modification, unavailability of the Photos and Videos
                    available on the Site.
                  </li>
                  <li>
                    You hereby grant Stupa Sports Analytics an exclusive,
                    transferable, royalty free, right to copy, irrevocable and
                    worldwide licence to use, copy, host, distribute, store,
                    copy, share modify, perform, create derivative works and
                    publicly display the Photos and Videos on Site and/or on any
                    public forum including on social media platform at its sole
                    discretion for instance, Facebook and Instagram. We reserve
                    all the right, tittle and interest not expressly granted to
                    the fullest extent possible under Applicable Laws. Unless
                    stated otherwise, all Photos and Videos are believed to be
                    in the public domain as either promotional materials or
                    publicity photos and videos as Stupa Sports Analytics may
                    determine at its sole discretion.
                  </li>
                  <li>
                    It is hereby clarified that Stupa Sports Analytics will not
                    have access to information posted by you on groups created
                    by you and/or the ones that you join through the Secondary
                    Services offered on the Application. All such data will be
                    end-to-end encrypted. End-to-end encryption means that your
                    messages are encrypted to protect against us and third
                    parties from reading them. Your messages are stored on your
                    device and not typically stored on our servers. Once your
                    messages are delivered, they are deleted from our servers.
                    The following scenarios describe circumstances where we may
                    store your messages in the course of delivering them:
                    <ol className="sub-point-list">
                      <li>
                        Undelivered Messages. If a message cannot be delivered
                        immediately (for example, if the recipient is offline),
                        we keep it in encrypted form on our servers for up to 30
                        days as we try to deliver it. If a message is still
                        undelivered after 30 days, we delete it.
                      </li>
                      <li>
                        Media Forwarding. When a user forwards media within a
                        message, we store that media temporarily in encrypted
                        form on our servers to aid in more efficient delivery of
                        additional forwards.
                      </li>
                    </ol>
                  </li>
                  <li>
                    You agree that when posting Posted Content or while viewing
                    the any content of yourself or of other Customers, you will
                    not:
                    <ol className="sub-point-list">
                      <li>
                        Publish, post, upload, distribute or disseminate any
                        inappropriate, profane, defamatory, infringing, obscene,
                        indecent or unlawful topic, blasphemous, pornographic,
                        libellous, invasive of another&apos;s privacy, hateful,
                        or racially, ethnically objectionable, disparaging,
                        relating or encouraging money laundering or gambling, or
                        otherwise unlawful material or information.
                      </li>
                      <li>
                        Upload files that contain software or other material
                        protected by intellectual property laws (or by rights of
                        privacy or publicity) unless you own or control the
                        rights thereto or have received all necessary consents.
                      </li>
                      <li>
                        Upload files that contain viruses, corrupted files, or
                        any other similar software or programs that may damage
                        the operation of another’s computer.
                      </li>
                      <li>
                        Advertise or offer to sell or buy any goods or services
                        for any business purpose, unless the Site specifically
                        allows such messages.
                      </li>
                      <li>
                        Conduct or forward surveys, contests, pyramid schemes or
                        chain letters.
                      </li>
                      <li>
                        Download any file posted by/of another user that you
                        know, or reasonably should know, cannot be legally
                        distributed in such manner.
                      </li>
                      <li>
                        Falsify or delete any author attributions, legal or
                        other proper notices or proprietary designations or
                        labels of the origin or source of software or other
                        material contained in a file that is uploaded.
                      </li>
                      <li>
                        Deceive or mislead the addressee about the origin of a
                        message or communicate any information which is grossly
                        offensive or menacing in nature.
                      </li>
                      <li>
                        Restrict or inhibit any other user from using and
                        enjoying the Site.
                      </li>
                      <li>
                        Violate any code of conduct or other guidelines of Our
                        or of Our third party servce provider which may be
                        applicable for any particular Posted Content or any
                        content accessible on the Site.
                      </li>
                      <li>
                        Harvest or otherwise collect information about others,
                        including e-mail addresses, photographs, videos or
                        material (whether written, graphic, sound, or otherwise)
                        without their consent.
                      </li>
                      <li>
                        Threaten the unity, integrity, defence, security or
                        sovereignty of India, friendly relations with foreign
                        states, or public order or causes incitement to the
                        commission of any cognizable offence or prevents
                        investigation of any offence or is insulting any other
                        nation.
                      </li>
                      <li>
                        use the content for any illegal purpose, and/or in any
                        illegal manner.
                      </li>
                      <li>
                        edit, change, prepare any derivative work of or alter in
                        any way any of the content of other users of the Site.
                      </li>
                      <li>
                        Violate any Applicable Laws or regulations including the
                        Information Technology Act, 2000 and the rules,
                        regulations and guidelines notified thereunder.
                      </li>
                      <li>
                        use the Photo and Videos for any illegal purpose, and
                        any illegal manner; edit, change, prepare any derivative
                        work of or alter in any way any of the Photo and Videos
                        provided on the Site; or use them in any way that
                        violates the User Terms or Applicable Laws.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <p className="bold"> INTELLECTUAL PROPERTY OWNERSHIP</p>
            <ol className="sub-point-list">
              <li>
                Stupa Sports Analytics alone (and its licensors or any third
                party, where applicable) shall own all right, title and
                interest, including all related intellectual property rights, in
                and to:
                <ol className="sub-point-list">
                  <li>
                    the Site and the Service and any suggestions, ideas,
                    enhancement requests, feedback, recommendations;
                  </li>
                  <li>
                    text, graphics, user interfaces, visual interfaces,
                    photographs, videos, trademarks, logos, sounds, music,
                    artwork and computer code in relation to the Site; or
                  </li>
                  <li>
                    other information provided by you or any other party
                    relating to the Site or the Service.
                  </li>
                </ol>
              </li>
              <li>
                Third party trademarks or any intellectual property may appear
                on this Site and all rights therein are reserved to the
                registered owners of those intellectual property. For use of any
                third party&apos;s intellectual property, you need to get
                permission directly from the owner of the intellectual property
                for any use.
              </li>
              <li>
                These User Terms do not constitute a sale and do not convey to
                you any rights of ownership in or related to the Site or the
                Service, or any intellectual property rights owned by Stupa
                Sports Analytics. You shall be solely responsible for any
                violations of any laws and for any infringements of any
                intellectual property rights caused by use of the Services or
                the Site.
              </li>
              <li>
                You may use information on the Site purposely made available by
                Stupa Sports Analytics for downloading from the Site, provided
                that you:
                <ol className="sub-point-list">
                  <li>
                    do not remove any proprietary notice language in all copies
                    of such documents and make no modifications to the
                    information;
                  </li>
                  <li>
                    use such information only for your personal, non-commercial
                    informational purpose and do not copy or post such
                    information on any networked computer or broadcast it in any
                    media; or
                  </li>
                  <li>
                    do not make any additional representations or warranties
                    relating to such information.
                  </li>
                </ol>
              </li>
              <li>
                You hereby acknowledge and agree that We exclusively owns all
                the intellectual property rights including copyright and other
                related rights in the Photos and Videos.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">LINKS</p>
            <ol className="sub-point-list">
              <li>
                If permitted by Stupa Sports Analytics, you may establish a
                hypertext link to the Site, provided that the link does not
                state or imply any sponsorship or endorsement of your site by
                Us. You must not use on your site or in any other manner any
                Stupa Sports Analytics trademarks or service marks or any
                content belonging to Stupa Sports Analytics and appearing on the
                Site, including any logos or characters, without our express
                written consent. You must not frame or otherwise incorporate
                into another third-party website or present in conjunction with
                or juxtaposed against such a website any of the content or other
                materials on the Site without our prior written consent.
              </li>
              <li>
                The Services include and make use of certain functionality and
                services provided by third-parties that allow Us to include
                maps, geocoding, places and other content from Google, Inc.
                (“Google”), or third-party vendors like Google, as part of the
                Services (the “Geo-Location Services”). Your use of the
                Geo-Location Services is subject to Google’s then current Terms
                of Use for Google Maps/Google Earth and by using the
                Geo-Location Services, you agree to be bound by Google’s terms
                of use.
              </li>
              <li>
                Our Site may contain links to third-party websites, advertisers,
                or services that are not owned or controlled by Us. We have no
                control over, and assumes no responsibility for, the content,
                privacy policies, or practices of any third-party websites or
                services. If you access a third-party website from Our Site, you
                do so at your own risk, and you understand that this
                agreement/User Terms and Our privacy policy do not apply to your
                use of such sites. You expressly relieve Us from any and all
                liability arising from your use of any third-party website or
                services or third party owned content. Additionally, your
                dealings with or participation in promotions of advertisers
                found on our Site, including payment and delivery of
                goods/services, and any other terms (such as warranties) are
                solely between you and such advertisers. You agree that We shall
                not be responsible for any loss or damage of any sort relating
                to your dealings with such advertisers.
              </li>
              <li>
                We encourage you to be aware of when you leave the Service or
                Our Site, and to read the terms and conditions and privacy
                policy of any third-party website or service that you visit.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">TERM AND TERMINATION</p>
            <ol className="sub-point-list">
              <li>
                Unless terminated in accordance with this Clause XVI, this
                agreement between Stupa Sports Analytics and you is perpetual in
                nature upon downloading the Application and for Order booked
                through the Site.
              </li>
              <li>
                You are entitled to terminate this agreement at all times by
                deleting your Account, thus disabling the use by you of the
                Site. You can close your Account at any time by following the
                instructions on the Site.
              </li>
              <li>
                Stupa Sports Analytics is entitled to terminate the agreement at
                all times and with immediate effect (by disabling your use of
                the Site and the Service) if you: (a) violate or breach or
                unethically manipulate or exploit any term of these User Terms
                or anyway otherwise acted unethically or upon receipt of a
                genuine complaint from another user/Stupa Sports Analytics’s
                affiliate or partner, or (b) in the opinion of Stupa Sports
                Analytics, misuse the Site or the Service. Stupa Sports
                Analytics is not obliged to give notice of the termination of
                the agreement in advance. After termination Stupa Sports
                Analytics will give notice thereof in accordance with these User
                Terms.
              </li>
              <li>
                Termination of this agreement will not prejudice accrued rights
                of either Stupa Sports Analytics or you.
              </li>
              <li>
                Clauses X (Indemnification), XI (Liability), XII (Application
                License), XIII (Contents Posted By Customers), XIV (Intellectual
                Property Ownership), XVI (Term and Termination), XXI (Notice)
                and XXIII (Applicable Law and Dispute Resolution) and such other
                provisions which are intended to survive the termination, shall
                survive the expiry/termination of these User Terms in accordance
                with their terms.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">SEVERABILITY</p>
            <p>
              The invalidity of any term of these User Terms shall not affect
              the validity of the other provisions of these User Terms. If and
              to the extent that any provision of these User Terms is invalid,
              or is unacceptable in the given circumstances, a provision shall
              apply between the parties instead that is acceptable considering
              all the circumstances, taking into account the content and the
              purpose of these User Terms.
            </p>
          </li>
          <li>
            <p className="bold">CONFLICT</p>
            <p>
              In the event of any contradiction or inconsistency between this
              User Terms and any other agreement executed between you and Stupa
              Sports Analytics, the terms of the User Terms shall prevail unless
              the exception has been expressly agreed to in writing by making
              reference to the relevant Clause sought to be modified under this
              User Terms.
            </p>
          </li>
          <li>
            <p className="bold">DISCLAIMER</p>
            <ol className="sub-point-list">
              <li>
                Stupa Sports Analytics does not guarantee or provide assurance
                in respect of the behaviour, actions or data of the users posted
                on the Site.
              </li>
              <li>
                We do not authorize anyone to make a warranty on Our behalf and
                you shall not rely on any statement of warranty as a warranty by
                Us.
              </li>
              <li>
                Stupa Sports Analytics and their representatives, officers,
                employees, agents and contractors shall not be liable for any
                loss, damage, claim, expense, cost (including legal costs) or
                liability arising directly or indirectly from your use or
                non-use of the Service or the Site, or your reliance upon the
                Service or the information contained upon the Site (whether
                arising from Stupa Sports Analytics or any other person&apos;s
                negligence or otherwise).
              </li>
              <li>
                This Site, Application and all content on the Site and the
                Application are provided on an as is basis without warranties of
                any kind, either express or implied, including without
                limitation warranties of title or implied warranties of
                merchantability or fitness for a particular purpose. You
                acknowledge, by your access of the Site, that your access of the
                Site and availing of Services is at your sole risk, that you
                assume full responsibility for your access and use of the Site,
                and that Stupa Sports Analytics shall not be liable for any
                damages of any kind related to your access and use of this Site
                and/or Application.
              </li>
              <li>
                All images, audio, video and text in the Site and/or Application
                are only for illustrative purposes. None of the models, actors
                or products in the images, if any, is endorsing the Services in
                any manner. Any resemblance of any matter to anybody or anything
                is purely unintentional and/or coincidental.
              </li>
              <li>
                You acknowledge and agree that by accessing or using the Site or
                Services or any content on the Site, you may be exposed to
                content from others that you may consider offensive, indecent or
                otherwise objectionable. We disclaims all liabilities arising in
                relation to such offensive content on the Site.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">MODIFICATION OF THE SERVICE AND USER TERMS</p>
            <ol className="sub-point-list">
              <li>
                Stupa Sports Analytics reserves the right, at its sole
                discretion, to modify or replace, in part or full, any of these
                User Terms, or change, suspend, block, discontinue or restrict
                your use to all or any feature of the Service or Application at
                any time.
              </li>
              <li>
                Stupa Sports Analytics shall not be required to notify you of
                any changes made to these User Terms. The revised User Terms
                shall be made available on the Site. You are requested to
                regularly visit the Site to view the most current User Terms.
                You can determine when Stupa Sports Analytics last modified the
                User Terms by referring to the Last Updated legend above. It
                shall be your responsibility to check these User Terms
                periodically for changes. Stupa Sports Analytics may require you
                to provide your consent to the updated User Terms in a specified
                manner prior to any further use of the Site and the Services. If
                no such separate consent is sought, your continued use of the
                Site, following the changes to the User Terms, will constitute
                your acceptance of those changes. Your use of the Site and the
                Services is subject to the most current version of the User
                Terms made available on the Site at the time of such use.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">NOTICE</p>
            <ol className="sub-point-list">
              <li>
                Stupa Sports Analytics may give notice by means of a general
                notice on the Service or Application, or by electronic mail to
                your email address or a message on your registered mobile
                number, or by written communication sent by regular mail to your
                address on record in Stupa Sports Analytics’s account
                information.
              </li>
              <li>
                You may contact Stupa Sports Analytics by electronic mail at Our
                email address
                <span style={{ color: "#62ABDF" }}>
                  {` support@stupaanalytics.com `}
                </span>
                or by written communication sent by regular mail to Our address
                at Kadarpur Rd, Sector-63A, Gurugram, Kadarpur, Haryana 122102
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">ASSIGNMENT</p>
            <p>
              You shall not assign your rights under these User Terms without
              prior written approval of Stupa Sports Analytics. Stupa Sports
              Analytics can assign its rights under the User Terms to any
              affiliate or third party.
            </p>
          </li>
          <li>
            <p className="bold">APPLICABLE LAW AND DISPUTE RESOLUTION</p>
            <ol className="sub-point-list">
              <li>
                These User Terms are subject to the laws of India. Any dispute,
                claim or controversy arising out of or relating to these User
                Terms or the breach, termination, enforcement, interpretation or
                validity thereof or the use of the Site or the
                Service(collectively, “Disputes”) the parties shall attempt to
                settle the same amicably, through negotiation and consultation
                at such offices of Stupa Sports Analytics as Stupa Sports
                Analytics may designate.
              </li>
              <li>
                In the event the Dispute is not resolved internally between the
                parties after at least 30 (thirty) days of negotiation, in good
                faith, the same shall be subject to binding and final
                arbitration in accordance with the Arbitration and Conciliation
                Act, 1996 as amended from time to time or in case the
                Arbitration and Conciliation Act, 1996 is no longer in force, as
                per any law relating to arbitration in force at the time of such
                reference. The reference shall be made to a sole arbitrator
                mutually appointed by Stupa Sports Analytics and you. The place
                of the arbitration shall be Delhi, unless otherwise mutually
                agreed by Stupa Sports Analytics and you in writing. Subject to
                the above, any Dispute will be subject to the exclusive
                jurisdiction of courts in Delhi, India.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <div className="btn-container px-28 py-16 flex-column gap-14">
        <div className="flex-row gap-16 align-items-center">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240709174948-white_tick.svg"
            alt="tick"
            width={20}
            height={20}
          />
          <p className="font-xsmall bold">I Accept the terms and Conditions</p>
        </div>
        <div className="flex-row gap-10">
          <p
            className="btn font-large bold"
            style={{
              borderRadius: "44px",
              color: "#000000",
              background: "#EEEEEE",
            }}
            onClick={() => navigate(PATHS.NAME)}
          >
            Cancel
          </p>
          <p
            className="btn font-large bold"
            style={{
              borderRadius: "44px",
              color: "#FFFFFF",
              background: "#000000",
            }}
            onClick={nameUpdate}
          >
            I accept
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermConditions;
