import React, { useEffect, useState } from "react";
import "./SplashScreen.scss";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";

const SplashScreen: React.FC = () => {
  const navigate = useNavigate();
  const [thunder, setThunder] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setThunder(true);
    }, 700);

    setTimeout(() => {
      setThunder(false);
    }, 800);
  }, []);

  return (
    <div className="splash-screen-container">
      <img
        className="padel-park-logo"
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240629091902-padel-park-logo.svg"
        alt="padel"
        width={118}
        height={118}
      />
      <img
        className="stupa-logo"
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240629091902-stupa-entry-logo.svg"
        alt="stupa"
        width={118}
        height={118}
      />
      <img
        alt="thunder-green"
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240629091902-green-thunder.svg"
        className="green-thunder-1"
        width={132}
        height={208}
      />
      <img
        alt="thunder-green"
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240629115304-Vector+125.svg"
        className="green-thunder-2"
        width={96}
        height={152}
      />
      {thunder && (
        <div className="full-screen-black">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710184557-Group+1000004705+%281%29.webp"
            alt="thunder"
            className="dark-thunder"
          />
        </div>
      )}
      <div className="welcome-message-container">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240611193440-Quote+1.svg"
          alt="quotes"
          width={81}
          height={57}
        />
        <p className="message">WELCOME</p>
        <div
          className="started-btn-container"
          onClick={() => navigate(PATHS.SIGN_IN)}
        >
          <p className="message">Lets get started</p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240611193439-Frame.svg"
            alt="right-arrow"
            width={20}
            height={20}
          />
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
