import React, { useEffect, useState } from "react";
import "./AllMatches.scss";
import MatchSummary from "../../components/MatchSummary/MatchSummary";
import SportsFilter from "../../components/SportsFilter/SportsFilter";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { MatchDetails } from "../../models/Match";
import ApiLoader from "../../components/ApiLoader/ApiLoader";
import InfiniteScroll from "react-infinite-scroll-component";

const AllMatches: React.FC = () => {
  const navigate = useNavigate();
  const [sportId, setSportId] = useState("");
  const [calendarMonth, setCalendarMonth] = useState("");
  const [matches, setMatches] = useState<MatchDetails[]>([]);
  const [apiloading, seApiLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const loadingSub = ApiService().loadingSubject.subscribe((res) => {
      seApiLoading(res > 0);
    });
    return () => {
      loadingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    getMatchListing();
  }, [sportId, page]);

  const getMatchListing = async () => {
    const perPage = 10;
    const params =
      sportId === ""
        ? { params: { per_page: perPage, page } }
        : { params: { sport_id: sportId, per_page: perPage, page } };
    try {
      const res = await ApiService().client.get(URL.MATCH.LIST, params);
      if (res.status === 200) {
        setMatches((matches) => [...matches, ...res.data.data.matches]);
        if (res.data.data.pagination.total_pages === page) {
          setHasMore(false);
        }
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const nextPage = () => {
    setPage((page) => page + 1);
    console.log("NEXT");
  };

  return (
    <div className="all-matches-wrapper flex-column">
      <div className="matches-header overflow-hidden">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617103535-Vector+%282%29.svg"
          className="back-btn"
          width={8}
          height={14}
          alt="back"
          onClick={() => navigate(PATHS.DASHBOARD)}
        />
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240705110302-Logo.webp"
          className="client-logo"
          width={54}
        />
        <p className="title">All Matches</p>
        {apiloading && <ApiLoader />}
      </div>
      <div className="matches-container flex-1">
        <div className="match-filter-wrapper">
          <SportsFilter
            sportId={sportId}
            setSportId={setSportId}
            // calendarMonth={calendarMonth}
            // setCalendarMonth={setCalendarMonth}
            calendar={false}
          />
        </div>
        {/* <div className="match-stats-container p-10 flex-column gap-12">
          <div className="flex-row justify-content-space-between">
            <p className="font-small bolder" style={{ color: "#5F5F5F" }}>
              Total Matches
            </p>
            <p className="font-small bolder" style={{ color: "#73C58A" }}>
              {calendarMonth}
            </p>
          </div>
          <div className="flex-row gap-22">
            <div className="stats-container px-18 py-6 gap-4 flex-1">
              <p style={{ fontSize: "20px" }}>🤩</p>
              <div className="flex-row justify-content-space-between align-items-center">
                <p className="font-small bolder" style={{ color: "#000000" }}>
                  Won
                </p>
                <p className="font-mid" style={{ color: "#AFAFAF" }}>
                  <span style={{ color: "#000000" }}>12</span>/20
                </p>
              </div>
            </div>
            <div className="stats-container px-18 py-6 gap-4 flex-1">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240820055148-snow_flake.svg"
                width={20}
                height={20}
                alt="lost"
              />
              <div className="flex-row justify-content-space-between align-items-center">
                <p className="font-small bolder" style={{ color: "#000000" }}>
                  Lost
                </p>
                <p className="font-mid" style={{ color: "#AFAFAF" }}>
                  <span style={{ color: "#000000" }}>8</span>/20
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="separator"></div> */}
        <div className="matches flex-1" id="matchListScrollableDiv">
          <InfiniteScroll
            dataLength={matches?.length}
            next={nextPage}
            hasMore={hasMore}
            loader={<h4></h4>}
            className="matchListinfiniteScrollComponent"
            scrollableTarget="matchListScrollableDiv"
          >
            {matches.map((match, index) => (
              <MatchSummary key={`match-summary-${index}`} match={match} />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default AllMatches;
