import React, { useState } from "react";
import "./ProfileAvatar.scss";
import ProfileAvatarSelector from "../ProfileAvatarSelector/ProfileAvatarSelector";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import { UserProfile } from "../../models/User";

interface ProfileAvatarProps {
  details: UserProfile;
  editDetail: (key: string, value: string) => void;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  details,
  editDetail,
}) => {
  const [openEditAvatar, setOpenEditAvatar] = useState(false);

  const updateImage = () => {
    setOpenEditAvatar((val) => !val);
  };

  const saveAvatar = (val: string) => {
    editDetail("image_url", val);
  };

  return (
    <div className="profile-avatar">
      <div className="profile-avatar-header">
        <div className="left-avatar-header">Choose your Avatar/photo</div>
        <div className="right-avatar-header">Optional</div>
      </div>
      <div
        className="select-avatar flex-row align-items-center justify-content-center"
        onClick={updateImage}
      >
        <img
          src={details.image_url}
          alt="user-image"
          width={75}
          height={75}
          className="border-round"
        />
        <div className="image-select">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240622061942-camera.svg"
            className="camera"
            alt="camera"
          />
        </div>
      </div>
      {openEditAvatar && (
        <div
          className="update-photo-backdrop flex-column justify-content-flex-end"
          onClick={() => setOpenEditAvatar(false)}
        >
          <div
            className="update-photo-container"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="font-large bold">Update Photo</div>
            {/* <div className="image-section">
            <div className="select-image">
              <div className="image-option">
                <div className="image-option-text">Take a selfie</div>
                <div className="image-option-logo"></div>
              </div>
            </div>
            <div className="select-image">
              <div className="image-option">
                <div className="image-option-text">Upload From Gallery</div>
                <div className="image-option-logo"></div>
              </div>
            </div>
          </div> 
          <div className="seperator-section">
            <div className="seperator"></div>
            OR
            <div className="seperator"></div>
          </div>
          */}
            <ProfileAvatarSelector
              selectImage={saveAvatar}
              selectedImage={details.image_url}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileAvatar;
