import React, { useState } from "react";
import "./ProfileAvatarSelector.scss";
import { AVATAR_IMAGES } from "../../data/Avatar.data";

interface ProfileAvatarSelectorProps {
  selectImage: (val: string) => void;
  selectedImage: string;
}

const ProfileAvatarSelector: React.FC<ProfileAvatarSelectorProps> = ({
  selectImage,
  selectedImage,
}) => {
  const [selectedAvatar, setSelectedAvatar] = useState<string>(selectedImage);

  const handleAvatarClick = (avatar: string) => {
    setSelectedAvatar(avatar);
    selectImage(avatar);
  };

  return (
    <div className="avatar-section">
      <div className="avatar-section-header">Choose an Avatar</div>
      <div className="avatar-grid">
        {AVATAR_IMAGES.map((avatar, index) => (
          <div
            key={index}
            className={`avatar ${selectedAvatar === avatar ? "selected" : ""}`}
            onClick={() => handleAvatarClick(avatar)}
          >
            <img src={avatar} alt={`Avatar ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileAvatarSelector;
