import React, { useEffect, useState } from "react";
import "./RecordMatch.scss";
import RecordMatchLoader from "../../components/RecordMatchLoader/RecordMatchLoader";
import AddPlayerDoubles from "../../components/AddPlayerDoubles/AddPlayerDoubles";
import GameTypeOnboarding from "../../components/GameTypeOnboarding/GameTypeOnboarding";
import AddPlayerSingles from "../../components/AddPlayerSingles/AddPlayerSingles";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { Court } from "../../models/Court";

const RecordMatch: React.FC = () => {
  const [gameSelected, setGameSelected] = useState("");
  const [gameLoading, setGameLoading] = useState(true);
  const [matchId, setMatchId] = useState(5);
  const [courtDetails, setCourtDetails] = useState<Court>();

  useEffect(() => {
    getMatchDetails();
  }, []);

  const loadingCompleted = () => {
    setGameLoading(false);
  };

  const startMatch = (val: string) => {
    setGameSelected(val);
    createMatch();
  };

  const getMatchDetails = async () => {
    try {
      const res = await ApiService().client.get(URL.COURT.DETAILS);
      if (res.status === 200) {
        setCourtDetails({ ...res.data.data[0], sports_name: "BADMINTON" });
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const createMatch = async () => {
    const res = await ApiService().client.post(URL.MATCH.CREATE, {
      data: [{ sports_id: 1, court_number: 1 }],
    });
    if (res.status === 200) {
      setMatchId(res.data.data.match_id);
    }
  };

  return (
    <div>
      {gameLoading || courtDetails == undefined ? (
        <RecordMatchLoader completed={loadingCompleted} court={courtDetails} />
      ) : (
        <>
          {gameSelected === "SINGLES" && (
            <AddPlayerSingles
              setGame={() => setGameSelected("")}
              matchId={matchId}
              court={courtDetails}
            />
          )}
          {gameSelected === "DOUBLES" && (
            <AddPlayerDoubles
              setGame={() => setGameSelected("")}
              matchId={matchId}
              court={courtDetails}
            />
          )}
          {gameSelected === "" && (
            <GameTypeOnboarding setGame={startMatch} court={courtDetails} />
          )}
        </>
      )}
    </div>
  );
};

export default RecordMatch;
