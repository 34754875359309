export const COUNTRY_CODES = [
  {
    name: "Afghanistan",
    code: "AFG",
    digits: [9],
    prefix: "+93",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
  },
  {
    name: "Albania",
    code: "ALB",
    digits: [9],
    prefix: "+355",
    flag: "https://flagcdn.com/al.svg",
  },
  {
    name: "Algeria",
    code: "DZA",
    digits: [9],
    prefix: "+213",
    flag: "https://flagcdn.com/dz.svg",
  },
  {
    name: "Andorra",
    code: "AND",
    digits: [6],
    prefix: "+376",
    flag: "https://flagcdn.com/ad.svg",
  },
  {
    name: "Angola",
    code: "AGO",
    digits: [9],
    prefix: "+244",
    flag: "https://flagcdn.com/ao.svg",
  },
  {
    name: "Antigua and Barbuda",
    code: "ATG",
    digits: [10],
    prefix: "+1-268",
    flag: "https://flagcdn.com/ag.svg",
  },
  {
    name: "Argentina",
    code: "ARG",
    digits: [10],
    prefix: "+54",
    flag: "https://flagcdn.com/ar.svg",
  },
  {
    name: "Armenia",
    code: "ARM",
    digits: [8],
    prefix: "+374",
    flag: "https://flagcdn.com/am.svg",
  },
  {
    name: "Australia",
    code: "AUS",
    digits: [9],
    prefix: "+61",
    flag: "https://flagcdn.com/au.svg",
  },
  {
    name: "Austria",
    code: "AUT",
    digits: [10],
    prefix: "+43",
    flag: "https://flagcdn.com/at.svg",
  },
  {
    name: "Azerbaijan",
    code: "AZE",
    digits: [9],
    prefix: "+994",
    flag: "https://flagcdn.com/az.svg",
  },
  {
    name: "Bahamas",
    code: "BHS",
    digits: [10],
    prefix: "+1-242",
    flag: "https://flagcdn.com/bs.svg",
  },
  {
    name: "Bahrain",
    code: "BHR",
    digits: [8],
    prefix: "+973",
    flag: "https://flagcdn.com/bh.svg",
  },
  {
    name: "Bangladesh",
    code: "BGD",
    digits: [10],
    prefix: "+880",
    flag: "https://flagcdn.com/bd.svg",
  },
  {
    name: "Barbados",
    code: "BRB",
    digits: [10],
    prefix: "+1-246",
    flag: "https://flagcdn.com/bb.svg",
  },
  {
    name: "Belarus",
    code: "BLR",
    digits: [9],
    prefix: "+375",
    flag: "https://flagcdn.com/by.svg",
  },
  {
    name: "Belgium",
    code: "BEL",
    digits: [9],
    prefix: "+32",
    flag: "https://flagcdn.com/be.svg",
  },
  {
    name: "Belize",
    code: "BLZ",
    digits: [7],
    prefix: "+501",
    flag: "https://flagcdn.com/bz.svg",
  },
  {
    name: "Benin",
    code: "BEN",
    digits: [8],
    prefix: "+229",
    flag: "https://flagcdn.com/bj.svg",
  },
  {
    name: "Bhutan",
    code: "BTN",
    digits: [8],
    prefix: "+975",
    flag: "https://flagcdn.com/bt.svg",
  },
  {
    name: "Bolivia",
    code: "BOL",
    digits: [8],
    prefix: "+591",
    flag: "https://flagcdn.com/bo.svg",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BIH",
    digits: [8],
    prefix: "+387",
    flag: "https://flagcdn.com/ba.svg",
  },
  {
    name: "Botswana",
    code: "BWA",
    digits: [8],
    prefix: "+267",
    flag: "https://flagcdn.com/bw.svg",
  },
  {
    name: "Brazil",
    code: "BRA",
    digits: [10, 11],
    prefix: "+55",
    flag: "https://flagcdn.com/br.svg",
  },
  {
    name: "Brunei",
    code: "BRN",
    digits: [7],
    prefix: "+673",
    flag: "https://flagcdn.com/bn.svg",
  },
  {
    name: "Bulgaria",
    code: "BGR",
    digits: [9],
    prefix: "+359",
    flag: "https://flagcdn.com/bg.svg",
  },
  {
    name: "Burkina Faso",
    code: "BFA",
    digits: [8],
    prefix: "+226",
    flag: "https://flagcdn.com/bf.svg",
  },
  {
    name: "Burundi",
    code: "BDI",
    digits: [8],
    prefix: "+257",
    flag: "https://flagcdn.com/bi.svg",
  },
  {
    name: "Cambodia",
    code: "KHM",
    digits: [9],
    prefix: "+855",
    flag: "https://flagcdn.com/kh.svg",
  },
  {
    name: "Cameroon",
    code: "CMR",
    digits: [9],
    prefix: "+237",
    flag: "https://flagcdn.com/cm.svg",
  },
  {
    name: "Canada",
    code: "CAN",
    digits: [10],
    prefix: "+1",
    flag: "https://flagcdn.com/ca.svg",
  },
  {
    name: "Central African Republic",
    code: "CAF",
    digits: [8],
    prefix: "+236",
    flag: "https://flagcdn.com/cf.svg",
  },
  {
    name: "Chad",
    code: "TCD",
    digits: [8],
    prefix: "+235",
    flag: "https://flagcdn.com/td.svg",
  },
  {
    name: "Chile",
    code: "CHL",
    digits: [9],
    prefix: "+56",
    flag: "https://flagcdn.com/cl.svg",
  },
  {
    name: "China",
    code: "CHN",
    digits: [11],
    prefix: "+86",
    flag: "https://flagcdn.com/cn.svg",
  },
  {
    name: "Colombia",
    code: "COL",
    digits: [10],
    prefix: "+57",
    flag: "https://flagcdn.com/co.svg",
  },
  {
    name: "Comoros",
    code: "COM",
    digits: [7],
    prefix: "+269",
    flag: "https://flagcdn.com/km.svg",
  },
  {
    name: "Costa Rica",
    code: "CRI",
    digits: [8],
    prefix: "+506",
    flag: "https://flagcdn.com/cr.svg",
  },
  {
    name: "Croatia",
    code: "HRV",
    digits: [9],
    prefix: "+385",
    flag: "https://flagcdn.com/hr.svg",
  },
  {
    name: "Cuba",
    code: "CUB",
    digits: [8],
    prefix: "+53",
    flag: "https://flagcdn.com/cu.svg",
  },
  {
    name: "Cyprus",
    code: "CYP",
    digits: [8],
    prefix: "+357",
    flag: "https://flagcdn.com/cy.svg",
  },
  {
    name: "Denmark",
    code: "DNK",
    digits: [8],
    prefix: "+45",
    flag: "https://flagcdn.com/dk.svg",
  },
  {
    name: "Djibouti",
    code: "DJI",
    digits: [8],
    prefix: "+253",
    flag: "https://flagcdn.com/dj.svg",
  },
  {
    name: "Dominica",
    code: "DMA",
    digits: [10],
    prefix: "+1-767",
    flag: "https://flagcdn.com/dm.svg",
  },
  {
    name: "Dominican Republic",
    code: "DOM",
    digits: [10],
    prefix: "+1-809",
    flag: "https://flagcdn.com/do.svg",
  },
  {
    name: "Ecuador",
    code: "ECU",
    digits: [9],
    prefix: "+593",
    flag: "https://flagcdn.com/ec.svg",
  },
  {
    name: "Egypt",
    code: "EGY",
    digits: [10],
    prefix: "+20",
    flag: "https://flagcdn.com/eg.svg",
  },
  {
    name: "El Salvador",
    code: "SLV",
    digits: [8],
    prefix: "+503",
    flag: "https://flagcdn.com/sv.svg",
  },
  {
    name: "Equatorial Guinea",
    code: "GNQ",
    digits: [9],
    prefix: "+240",
    flag: "https://flagcdn.com/gq.svg",
  },
  {
    name: "Eritrea",
    code: "ERI",
    digits: [7],
    prefix: "+291",
    flag: "https://flagcdn.com/er.svg",
  },
  {
    name: "Estonia",
    code: "EST",
    digits: [7, 8],
    prefix: "+372",
    flag: "https://flagcdn.com/ee.svg",
  },
  {
    name: "Ethiopia",
    code: "ETH",
    digits: [9],
    prefix: "+251",
    flag: "https://flagcdn.com/et.svg",
  },
  {
    name: "Fiji",
    code: "FJI",
    digits: [7],
    prefix: "+679",
    flag: "https://flagcdn.com/fj.svg",
  },
  {
    name: "Finland",
    code: "FIN",
    digits: [9, 12],
    prefix: "+358",
    flag: "https://flagcdn.com/fi.svg",
  },
  {
    name: "France",
    code: "FRA",
    digits: [9],
    prefix: "+33",
    flag: "https://flagcdn.com/fr.svg",
  },
  {
    name: "Gabon",
    code: "GAB",
    digits: [7],
    prefix: "+241",
    flag: "https://flagcdn.com/ga.svg",
  },
  {
    name: "Gambia",
    code: "GMB",
    digits: [7],
    prefix: "+220",
    flag: "https://flagcdn.com/gm.svg",
  },
  {
    name: "Georgia",
    code: "GEO",
    digits: [9],
    prefix: "+995",
    flag: "https://flagcdn.com/ge.svg",
  },
  {
    name: "Germany",
    code: "DEU",
    digits: [10, 11],
    prefix: "+49",
    flag: "https://flagcdn.com/de.svg",
  },
  {
    name: "Ghana",
    code: "GHA",
    digits: [9],
    prefix: "+233",
    flag: "https://flagcdn.com/gh.svg",
  },
  {
    name: "Greece",
    code: "GRC",
    digits: [10],
    prefix: "+30",
    flag: "https://flagcdn.com/gr.svg",
  },
  {
    name: "Grenada",
    code: "GRD",
    digits: [10],
    prefix: "+1-473",
    flag: "https://flagcdn.com/gd.svg",
  },
  {
    name: "Guatemala",
    code: "GTM",
    digits: [8],
    prefix: "+502",
    flag: "https://flagcdn.com/gt.svg",
  },
  {
    name: "Guinea",
    code: "GIN",
    digits: [9],
    prefix: "+224",
    flag: "https://flagcdn.com/gn.svg",
  },
  {
    name: "Guinea-Bissau",
    code: "GNB",
    digits: [7],
    prefix: "+245",
    flag: "https://flagcdn.com/gw.svg",
  },
  {
    name: "Guyana",
    code: "GUY",
    digits: [7],
    prefix: "+592",
    flag: "https://flagcdn.com/gy.svg",
  },
  {
    name: "Haiti",
    code: "HTI",
    digits: [8],
    prefix: "+509",
    flag: "https://flagcdn.com/ht.svg",
  },
  {
    name: "Honduras",
    code: "HND",
    digits: [8],
    prefix: "+504",
    flag: "https://flagcdn.com/hn.svg",
  },
  {
    name: "Hungary",
    code: "HUN",
    digits: [9],
    prefix: "+36",
    flag: "https://flagcdn.com/hu.svg",
  },
  {
    name: "Iceland",
    code: "ISL",
    digits: [7],
    prefix: "+354",
    flag: "https://flagcdn.com/is.svg",
  },
  {
    name: "India",
    code: "IND",
    digits: [10],
    prefix: "+91",
    flag: "https://flagcdn.com/in.svg",
  },
  {
    name: "Indonesia",
    code: "IDN",
    digits: [10, 12],
    prefix: "+62",
    flag: "https://flagcdn.com/id.svg",
  },
  {
    name: "Iran",
    code: "IRN",
    digits: [10],
    prefix: "+98",
    flag: "https://flagcdn.com/ir.svg",
  },
  {
    name: "Iraq",
    code: "IRQ",
    digits: [10],
    prefix: "+964",
    flag: "https://flagcdn.com/iq.svg",
  },
  {
    name: "Ireland",
    code: "IRL",
    digits: [9],
    prefix: "+353",
    flag: "https://flagcdn.com/ie.svg",
  },
  {
    name: "Israel",
    code: "ISR",
    digits: [9, 10],
    prefix: "+972",
    flag: "https://flagcdn.com/il.svg",
  },
  {
    name: "Italy",
    code: "ITA",
    digits: [9, 10],
    prefix: "+39",
    flag: "https://flagcdn.com/it.svg",
  },
  {
    name: "Jamaica",
    code: "JAM",
    digits: [10],
    prefix: "+1-876",
    flag: "https://flagcdn.com/jm.svg",
  },
  {
    name: "Japan",
    code: "JPN",
    digits: [10],
    prefix: "+81",
    flag: "https://flagcdn.com/jp.svg",
  },
  {
    name: "Jordan",
    code: "JOR",
    digits: [9],
    prefix: "+962",
    flag: "https://flagcdn.com/jo.svg",
  },
  {
    name: "Kazakhstan",
    code: "KAZ",
    digits: [10],
    prefix: "+7",
    flag: "https://flagcdn.com/kz.svg",
  },
  {
    name: "Kenya",
    code: "KEN",
    digits: [9],
    prefix: "+254",
    flag: "https://flagcdn.com/ke.svg",
  },
  {
    name: "Kiribati",
    code: "KIR",
    digits: [8],
    prefix: "+686",
    flag: "https://flagcdn.com/ki.svg",
  },
  {
    name: "North Korea",
    code: "PRK",
    digits: [10],
    prefix: "+850",
    flag: "https://flagcdn.com/kp.svg",
  },
  {
    name: "South Korea",
    code: "KOR",
    digits: [9, 11],
    prefix: "+82",
    flag: "https://flagcdn.com/kr.svg",
  },
  {
    name: "Kuwait",
    code: "KWT",
    digits: [8],
    prefix: "+965",
    flag: "https://flagcdn.com/kw.svg",
  },
  {
    name: "Kyrgyzstan",
    code: "KGZ",
    digits: [9],
    prefix: "+996",
    flag: "https://flagcdn.com/kg.svg",
  },
  {
    name: "Laos",
    code: "LAO",
    digits: [10],
    prefix: "+856",
    flag: "https://flagcdn.com/la.svg",
  },
  {
    name: "Latvia",
    code: "LVA",
    digits: [8],
    prefix: "+371",
    flag: "https://flagcdn.com/lv.svg",
  },
  {
    name: "Lebanon",
    code: "LBN",
    digits: [8],
    prefix: "+961",
    flag: "https://flagcdn.com/lb.svg",
  },
  {
    name: "Lesotho",
    code: "LSO",
    digits: [8],
    prefix: "+266",
    flag: "https://flagcdn.com/ls.svg",
  },
  {
    name: "Liberia",
    code: "LBR",
    digits: [7, 8],
    prefix: "+231",
    flag: "https://flagcdn.com/lr.svg",
  },
  {
    name: "Libya",
    code: "LBY",
    digits: [9],
    prefix: "+218",
    flag: "https://flagcdn.com/ly.svg",
  },
  {
    name: "Liechtenstein",
    code: "LIE",
    digits: [9],
    prefix: "+423",
    flag: "https://flagcdn.com/li.svg",
  },
  {
    name: "Lithuania",
    code: "LTU",
    digits: [8],
    prefix: "+370",
    flag: "https://flagcdn.com/lt.svg",
  },
  {
    name: "Luxembourg",
    code: "LUX",
    digits: [9],
    prefix: "+352",
    flag: "https://flagcdn.com/lu.svg",
  },
  {
    name: "Madagascar",
    code: "MDG",
    digits: [9],
    prefix: "+261",
    flag: "https://flagcdn.com/mg.svg",
  },
  {
    name: "Malawi",
    code: "MWI",
    digits: [9],
    prefix: "+265",
    flag: "https://flagcdn.com/mw.svg",
  },
  {
    name: "Malaysia",
    code: "MYS",
    digits: [9, 10],
    prefix: "+60",
    flag: "https://flagcdn.com/my.svg",
  },
  {
    name: "Maldives",
    code: "MDV",
    digits: [7],
    prefix: "+960",
    flag: "https://flagcdn.com/mv.svg",
  },
  {
    name: "Mali",
    code: "MLI",
    digits: [8],
    prefix: "+223",
    flag: "https://flagcdn.com/ml.svg",
  },
  {
    name: "Malta",
    code: "MLT",
    digits: [8],
    prefix: "+356",
    flag: "https://flagcdn.com/mt.svg",
  },
  {
    name: "Marshall Islands",
    code: "MHL",
    digits: [7],
    prefix: "+692",
    flag: "https://flagcdn.com/mh.svg",
  },
  {
    name: "Mauritania",
    code: "MRT",
    digits: [8],
    prefix: "+222",
    flag: "https://flagcdn.com/mr.svg",
  },
  {
    name: "Mauritius",
    code: "MUS",
    digits: [8],
    prefix: "+230",
    flag: "https://flagcdn.com/mu.svg",
  },
  {
    name: "Mexico",
    code: "MEX",
    digits: [10],
    prefix: "+52",
    flag: "https://flagcdn.com/mx.svg",
  },
  {
    name: "Micronesia",
    code: "FSM",
    digits: [7],
    prefix: "+691",
    flag: "https://flagcdn.com/fm.svg",
  },
  {
    name: "Moldova",
    code: "MDA",
    digits: [8],
    prefix: "+373",
    flag: "https://flagcdn.com/md.svg",
  },
  {
    name: "Monaco",
    code: "MCO",
    digits: [9],
    prefix: "+377",
    flag: "https://flagcdn.com/mc.svg",
  },
  {
    name: "Mongolia",
    code: "MNG",
    digits: [8],
    prefix: "+976",
    flag: "https://flagcdn.com/mn.svg",
  },
  {
    name: "Montenegro",
    code: "MNE",
    digits: [8],
    prefix: "+382",
    flag: "https://flagcdn.com/me.svg",
  },
  {
    name: "Morocco",
    code: "MAR",
    digits: [9],
    prefix: "+212",
    flag: "https://flagcdn.com/ma.svg",
  },
  {
    name: "Mozambique",
    code: "MOZ",
    digits: [9],
    prefix: "+258",
    flag: "https://flagcdn.com/mz.svg",
  },
  {
    name: "Namibia",
    code: "NAM",
    digits: [9],
    prefix: "+264",
    flag: "https://flagcdn.com/na.svg",
  },
  {
    name: "Nauru",
    code: "NRU",
    digits: [7],
    prefix: "+674",
    flag: "https://flagcdn.com/nr.svg",
  },
  {
    name: "Nepal",
    code: "NPL",
    digits: [10],
    prefix: "+977",
    flag: "https://flagcdn.com/np.svg",
  },
  {
    name: "Netherlands",
    code: "NLD",
    digits: [9],
    prefix: "+31",
    flag: "https://flagcdn.com/nl.svg",
  },
  {
    name: "New Zealand",
    code: "NZL",
    digits: [8, 10],
    prefix: "+64",
    flag: "https://flagcdn.com/nz.svg",
  },
  {
    name: "Nicaragua",
    code: "NIC",
    digits: [8],
    prefix: "+505",
    flag: "https://flagcdn.com/ni.svg",
  },
  {
    name: "Niger",
    code: "NER",
    digits: [8],
    prefix: "+227",
    flag: "https://flagcdn.com/ne.svg",
  },
  {
    name: "Nigeria",
    code: "NGA",
    digits: [10],
    prefix: "+234",
    flag: "https://flagcdn.com/ng.svg",
  },
  {
    name: "North Macedonia",
    code: "MKD",
    digits: [8],
    prefix: "+389",
    flag: "https://flagcdn.com/mk.svg",
  },
  {
    name: "Norway",
    code: "NOR",
    digits: [8],
    prefix: "+47",
    flag: "https://flagcdn.com/no.svg",
  },
  {
    name: "Oman",
    code: "OMN",
    digits: [8],
    prefix: "+968",
    flag: "https://flagcdn.com/om.svg",
  },
  {
    name: "Pakistan",
    code: "PAK",
    digits: [10],
    prefix: "+92",
    flag: "https://flagcdn.com/pk.svg",
  },
  {
    name: "Palau",
    code: "PLW",
    digits: [7],
    prefix: "+680",
    flag: "https://flagcdn.com/pw.svg",
  },
  {
    name: "Panama",
    code: "PAN",
    digits: [8],
    prefix: "+507",
    flag: "https://flagcdn.com/pa.svg",
  },
  {
    name: "Papua New Guinea",
    code: "PNG",
    digits: [8],
    prefix: "+675",
    flag: "https://flagcdn.com/pg.svg",
  },
  {
    name: "Paraguay",
    code: "PRY",
    digits: [9],
    prefix: "+595",
    flag: "https://flagcdn.com/py.svg",
  },
  {
    name: "Peru",
    code: "PER",
    digits: [9],
    prefix: "+51",
    flag: "https://flagcdn.com/pe.svg",
  },
  {
    name: "Philippines",
    code: "PHL",
    digits: [10],
    prefix: "+63",
    flag: "https://flagcdn.com/ph.svg",
  },
  {
    name: "Poland",
    code: "POL",
    digits: [9],
    prefix: "+48",
    flag: "https://flagcdn.com/pl.svg",
  },
  {
    name: "Portugal",
    code: "PRT",
    digits: [9],
    prefix: "+351",
    flag: "https://flagcdn.com/pt.svg",
  },
  {
    name: "Qatar",
    code: "QAT",
    digits: [8],
    prefix: "+974",
    flag: "https://flagcdn.com/qa.svg",
  },
  {
    name: "Romania",
    code: "ROU",
    digits: [10],
    prefix: "+40",
    flag: "https://flagcdn.com/ro.svg",
  },
  {
    name: "Russia",
    code: "RUS",
    digits: [10],
    prefix: "+7",
    flag: "https://flagcdn.com/ru.svg",
  },
  {
    name: "Rwanda",
    code: "RWA",
    digits: [9],
    prefix: "+250",
    flag: "https://flagcdn.com/rw.svg",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KNA",
    digits: [10],
    prefix: "+1-869",
    flag: "https://flagcdn.com/kn.svg",
  },
  {
    name: "Saint Lucia",
    code: "LCA",
    digits: [10],
    prefix: "+1-758",
    flag: "https://flagcdn.com/lc.svg",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VCT",
    digits: [10],
    prefix: "+1-784",
    flag: "https://flagcdn.com/vc.svg",
  },
  {
    name: "Samoa",
    code: "WSM",
    digits: [7],
    prefix: "+685",
    flag: "https://flagcdn.com/ws.svg",
  },
  {
    name: "San Marino",
    code: "SMR",
    digits: [9],
    prefix: "+378",
    flag: "https://flagcdn.com/sm.svg",
  },
  {
    name: "Saudi Arabia",
    code: "SAU",
    digits: [9],
    prefix: "+966",
    flag: "https://flagcdn.com/sa.svg",
  },
  {
    name: "Senegal",
    code: "SEN",
    digits: [9],
    prefix: "+221",
    flag: "https://flagcdn.com/sn.svg",
  },
  {
    name: "Serbia",
    code: "SRB",
    digits: [9],
    prefix: "+381",
    flag: "https://flagcdn.com/rs.svg",
  },
  {
    name: "Seychelles",
    code: "SYC",
    digits: [7],
    prefix: "+248",
    flag: "https://flagcdn.com/sc.svg",
  },
  {
    name: "Sierra Leone",
    code: "SLE",
    digits: [8],
    prefix: "+232",
    flag: "https://flagcdn.com/sl.svg",
  },
  {
    name: "Singapore",
    code: "SGP",
    digits: [8],
    prefix: "+65",
    flag: "https://flagcdn.com/sg.svg",
  },
  {
    name: "Slovakia",
    code: "SVK",
    digits: [9],
    prefix: "+421",
    flag: "https://flagcdn.com/sk.svg",
  },
  {
    name: "Slovenia",
    code: "SVN",
    digits: [9],
    prefix: "+386",
    flag: "https://flagcdn.com/si.svg",
  },
  {
    name: "Solomon Islands",
    code: "SLB",
    digits: [7],
    prefix: "+677",
    flag: "https://flagcdn.com/sb.svg",
  },
  {
    name: "Somalia",
    code: "SOM",
    digits: [9],
    prefix: "+252",
    flag: "https://flagcdn.com/so.svg",
  },
  {
    name: "South Africa",
    code: "ZAF",
    digits: [9],
    prefix: "+27",
    flag: "https://flagcdn.com/za.svg",
  },
  {
    name: "Spain",
    code: "ESP",
    digits: [9],
    prefix: "+34",
    flag: "https://flagcdn.com/es.svg",
  },
  {
    name: "Sri Lanka",
    code: "LKA",
    digits: [9],
    prefix: "+94",
    flag: "https://flagcdn.com/lk.svg",
  },
  {
    name: "Sudan",
    code: "SDN",
    digits: [9],
    prefix: "+249",
    flag: "https://flagcdn.com/sd.svg",
  },
  {
    name: "Suriname",
    code: "SUR",
    digits: [7],
    prefix: "+597",
    flag: "https://flagcdn.com/sr.svg",
  },
  {
    name: "Sweden",
    code: "SWE",
    digits: [9],
    prefix: "+46",
    flag: "https://flagcdn.com/se.svg",
  },
  {
    name: "Switzerland",
    code: "CHE",
    digits: [9],
    prefix: "+41",
    flag: "https://flagcdn.com/ch.svg",
  },
  {
    name: "Syria",
    code: "SYR",
    digits: [9],
    prefix: "+963",
    flag: "https://flagcdn.com/sy.svg",
  },
  {
    name: "Taiwan",
    code: "TWN",
    digits: [9],
    prefix: "+886",
    flag: "https://flagcdn.com/tw.svg",
  },
  {
    name: "Tajikistan",
    code: "TJK",
    digits: [9],
    prefix: "+992",
    flag: "https://flagcdn.com/tj.svg",
  },
  {
    name: "Tanzania",
    code: "TZA",
    digits: [9],
    prefix: "+255",
    flag: "https://flagcdn.com/tz.svg",
  },
  {
    name: "Thailand",
    code: "THA",
    digits: [9],
    prefix: "+66",
    flag: "https://flagcdn.com/th.svg",
  },
  {
    name: "Togo",
    code: "TGO",
    digits: [8],
    prefix: "+228",
    flag: "https://flagcdn.com/tg.svg",
  },
  {
    name: "Tonga",
    code: "TON",
    digits: [5],
    prefix: "+676",
    flag: "https://flagcdn.com/to.svg",
  },
  {
    name: "Trinidad and Tobago",
    code: "TTO",
    digits: [10],
    prefix: "+1-868",
    flag: "https://flagcdn.com/tt.svg",
  },
  {
    name: "Tunisia",
    code: "TUN",
    digits: [8],
    prefix: "+216",
    flag: "https://flagcdn.com/tn.svg",
  },
  {
    name: "Turkey",
    code: "TUR",
    digits: [10],
    prefix: "+90",
    flag: "https://flagcdn.com/tr.svg",
  },
  {
    name: "Turkmenistan",
    code: "TKM",
    digits: [8],
    prefix: "+993",
    flag: "https://flagcdn.com/tm.svg",
  },
  {
    name: "Tuvalu",
    code: "TUV",
    digits: [5],
    prefix: "+688",
    flag: "https://flagcdn.com/tv.svg",
  },
  {
    name: "Uganda",
    code: "UGA",
    digits: [9],
    prefix: "+256",
    flag: "https://flagcdn.com/ug.svg",
  },
  {
    name: "Ukraine",
    code: "UKR",
    digits: [9],
    prefix: "+380",
    flag: "https://flagcdn.com/ua.svg",
  },
  {
    name: "United Arab Emirates",
    code: "ARE",
    digits: [9],
    prefix: "+971",
    flag: "https://flagcdn.com/ae.svg",
  },
  {
    name: "United Kingdom",
    code: "GBR",
    digits: [10],
    prefix: "+44",
    flag: "https://flagcdn.com/gb.svg",
  },
  {
    name: "United States",
    code: "USA",
    digits: [10],
    prefix: "+1",
    flag: "https://flagcdn.com/us.svg",
  },
  {
    name: "Uruguay",
    code: "URY",
    digits: [9],
    prefix: "+598",
    flag: "https://flagcdn.com/uy.svg",
  },
  {
    name: "Uzbekistan",
    code: "UZB",
    digits: [9],
    prefix: "+998",
    flag: "https://flagcdn.com/uz.svg",
  },
  {
    name: "Vanuatu",
    code: "VUT",
    digits: [7],
    prefix: "+678",
    flag: "https://flagcdn.com/vu.svg",
  },
  {
    name: "Venezuela",
    code: "VEN",
    digits: [10],
    prefix: "+58",
    flag: "https://flagcdn.com/ve.svg",
  },
  {
    name: "Vietnam",
    code: "VNM",
    digits: [9],
    prefix: "+84",
    flag: "https://flagcdn.com/vn.svg",
  },
  {
    name: "Yemen",
    code: "YEM",
    digits: [9],
    prefix: "+967",
    flag: "https://flagcdn.com/ye.svg",
  },
  {
    name: "Zambia",
    code: "ZMB",
    digits: [9],
    prefix: "+260",
    flag: "https://flagcdn.com/zm.svg",
  },
  {
    name: "Zimbabwe",
    code: "ZWE",
    digits: [9],
    prefix: "+263",
    flag: "https://flagcdn.com/zw.svg",
  },
];
