import React, { useEffect, useState } from "react";
import "./DashboardHeader.scss";
import ApiLoader from "../ApiLoader/ApiLoader";
import ApiService from "../../services/apiService";

const DashboardHeader: React.FC = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadingSub = ApiService().loadingSubject.subscribe((res) => {
      setLoading(res > 0);
    });
    return () => {
      loadingSub.unsubscribe();
    };
  }, []);

  return (
    <div className="dashboard-header flex-row align-items-center justify-content-space-between overflow-hidden">
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240705110302-Logo.webp"
        className="stupa-logo"
        width={54}
      />
      <p className="title">Dashboard</p>
      <div className="counter px-8 py-6 flex-row gap-6 align-items-center">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626071019-Badminton+Player.svg"
          alt="player"
          width={17}
          height={17}
        />
        <p className="font-large bold" style={{ color: "#79EC63" }}>
          0
        </p>
      </div>
      {loading && <ApiLoader />}
    </div>
  );
};

export default DashboardHeader;
