import React, { useEffect, useState } from "react";
import "./CircleGraph.scss";

interface CircleGraphProps {
  width: number;
  color: string;
  strokeWidth: number;
  percent: number;
  animate?: boolean;
}

const CircleGraph: React.FC<CircleGraphProps> = ({
  width,
  color,
  strokeWidth,
  percent,
  animate = true,
}) => {
  const [strokeOffset, setStrokeOffset] = useState<number>(
    2 * 3.14 * (width / 2 - strokeWidth),
  );

  useEffect(() => {
    if (animate) {
      const fullWidth = 2 * 3.14 * (width / 2 - strokeWidth);
      setStrokeOffset(fullWidth);
      const step = fullWidth / 100;
      let ct = 0;
      const interval = setInterval(() => {
        if (ct < percent) {
          setStrokeOffset((val) => val - step);
        } else {
          clearInterval(interval);
        }
        ct++;
      }, 50);
      return () => {
        clearInterval(interval);
      };
    } else {
      const fullWidth = 2 * 3.14 * (width / 2 - strokeWidth);
      setStrokeOffset((fullWidth * (100 - percent)) / 100);
    }
  }, [percent]);

  return (
    <div className="circle-graph-container flex-column gap-24">
      <svg
        id="animated"
        xmlns="https://www.w3.org/2000/svg"
        version="1.1"
        width={`${width}px`}
        height={`${width}px`}
        className="loader-svg"
      >
        <defs>
          <linearGradient
            id={`GradientColorLoader-${color}`}
            gradientTransform="rotate(38)"
          >
            <stop offset="0%" stopColor={color} />
            <stop offset="100%" stopColor={color} />
          </linearGradient>
        </defs>
        <circle
          id={`progress-${color}`}
          cx={width / 2}
          cy={width / 2}
          r={width / 2 - strokeWidth}
          strokeWidth={strokeWidth}
          strokeDasharray={2 * 3.14 * (width / 2 - strokeWidth)}
          strokeDashoffset={strokeOffset}
          className="progress-circle"
          stroke={`url(#GradientColorLoader-${color})`}
          style={{ "--limit": (100 - percent) / 100 } as React.CSSProperties}
        ></circle>
      </svg>
    </div>
  );
};

export default CircleGraph;
