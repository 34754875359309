import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { isAuthenticated, selectAuthToken } from "../redux/user/userSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { JWT_TOKEN, setCookie } from "../services/cookieService";
import routes from "./Routes";
import { getUserDetails } from "../redux/user/userActions";

const ActiveRoutes = () => {
  const isUserAuthenticated: boolean = useSelector(isAuthenticated);
  const authToken: string | undefined | null = useSelector(selectAuthToken);

  useEffect(() => {
    if (authToken) {
      setCookie(JWT_TOKEN, authToken);
    }
  }, [authToken]);

  if (isUserAuthenticated) {
    return (
      <Router>
        <Routes>
          {routes.map((route, index) =>
            route.protected ? <Route key={index} {...route} /> : null,
          )}

          <Route
            key={routes.length}
            path="/notfound"
            element={<div> PAGE NOT FOUND </div>}
          />
          <Route path="*" element={<Navigate to={`/dashboard`} replace />} />
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          {routes.map((route, index) =>
            !route.protected ? <Route key={index} {...route} /> : null,
          )}

          <Route
            key={routes.length}
            path="/notfound"
            element={<div> PAGE NOT FOUND </div>}
          />
          <Route path="*" element={<Navigate to="/1" replace />} />
        </Routes>
      </Router>
    );
  }
};

export default ActiveRoutes;
