export const WinningTrendMessage = {
  weekly: {
    icon: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626130650-Frame.svg",
    message:
      "Find your stride: where activity meets efficiency in perfect balance",
  },
  monthly: {
    icon: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626130650-Frame+%281%29.svg",
    message: "Strive for better engagement balance to elevate your game",
  },
  empty: {
    icon: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626130650-Light.svg",
    message:
      "Prepare to dive into the game and find your balance in every moment.",
  },
};
