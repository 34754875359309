import React, { useState } from "react";
import "./SignInInput.scss";
import CountryCodeSelector from "../CountryCodeSelector/CountryCodeSelector";
import { Country } from "../../models/Country";

interface SignInInputProps {
  signIn: (type: string) => void;
  phoneNumber: string;
  mobileCode: string;
  setPhoneNumber: (val: string) => void;
  codeSet: (val: string) => void;
}

const SignInInput: React.FC<SignInInputProps> = ({
  signIn,
  phoneNumber,
  setPhoneNumber,
  codeSet,
  mobileCode,
}) => {
  const [countryCode, setCountryCode] = useState<Country>();
  const [errorMessage, setErrorMessage] = useState("");

  const handleCountryCodeSet = (value: Country) => {
    setCountryCode(value);
    codeSet(value.prefix);
  };

  const signInHandler = () => {
    const digits = countryCode?.digits;
    if (digits?.length === 1) {
      if (phoneNumber.length === digits[0]) {
        signIn("OTP");
      } else {
        setErrorMessage(`Please enter a ${digits[0]} digit phone number`);
      }
    }
    if (digits?.length === 2) {
      if (phoneNumber.length >= digits[0] && phoneNumber.length <= digits[1]) {
        signIn("OTP");
      } else {
        setErrorMessage(
          `Please enter a ${digits[0]}-${digits[1]} digit phone number`,
        );
      }
    }
  };

  return (
    <div className="sign-in-input-container">
      <div className="sign-in-input-details">
        <p className="title">{"LET'S GET STARTED"}</p>
        <div className="user-details-container">
          <div className="user-input-container">
            <CountryCodeSelector
              codeSet={handleCountryCodeSet}
              countryCode={mobileCode}
            />
            <input
              value={phoneNumber}
              type="number"
              onKeyDown={(event) => (event.keyCode == 69 ? false : true)}
              className="phonenumber"
              placeholder="Enter phone number"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {phoneNumber.length > 0 && (
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240612144405-white-cross.svg"
                alt="delete"
                className="black-cross"
                width={20}
                height={20}
                onClick={() => setPhoneNumber("")}
              />
            )}
          </div>
          <p className="error-message">{errorMessage}</p>
          <div className="separator">
            <div className="left-line"></div>
            {/* <p className="or">OR</p> */}
            <div className="right-line"></div>
          </div>
          {/* <div className="google-container" onClick={() => signIn("GOOGLE")}>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240612142050-google-icon.svg"
              height={30}
              width={30}
              alt="google"
            />
            <p className="google-msg">Continue with google</p>
          </div> */}
        </div>
      </div>
      <div
        className={`sign-in-btn-container ${
          phoneNumber.length >= 0 && "active-sign-in"
        }`}
        onClick={signInHandler}
      >
        <p className="message">Sign In</p>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240612073505-right-arrow-white.svg"
          alt="right-arrow"
          width={20}
          height={20}
        />
      </div>
    </div>
  );
};

export default SignInInput;
