import React, { useEffect, useState } from "react";
import "./LoadScreen.scss";

const LoadScreen: React.FC = () => {
  const [zoomLogo, setZoomLogo] = useState(false);
  const [startText, setStartText] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setZoomLogo(true);
    }, 1000);
    setTimeout(() => {
      setStartText(true);
    }, 1500);
  }, []);

  return (
    <div className="load-screen-container">
      <div className={`logo-container ${zoomLogo && "zoom-logo"}`}>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240629091902-stupa-entry-logo.svg"
          alt="logo"
          className="stupa-logo-load-splash"
        />
      </div>
      {startText && (
        <>
          <p className="font-xlarge bold get" style={{ color: "#ffffff" }}>
            Get..
          </p>
          <p className="font-xlarge bold set" style={{ color: "#ffffff" }}>
            Set..
          </p>
          <p className="font-xlarge bold match" style={{ color: "#ffffff" }}>
            Match!
          </p>
        </>
      )}
      <div className="logo-background" />
    </div>
  );
};

export default LoadScreen;
