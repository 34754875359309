import React from "react";
import "./EngagementRateInfo.scss";

interface EngagementRateInfoProps {
  closeInfo: () => void;
}

const EngagementRateInfo: React.FC<EngagementRateInfoProps> = ({
  closeInfo,
}) => {
  return (
    <div className="engagement-rate-info-container p-20">
      <p
        className="font-large bold"
        style={{ color: "#000000", textAlign: "center" }}
      >
        Engagement Rate
      </p>
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-close+2.svg"
        alt="close"
        width={10}
        height={10}
        className="close-btn"
        onClick={closeInfo}
      />
      <div className="info-details py-12 px-20">
        <p className="font-mid bolder" style={{ color: "#5F5F5F" }}>
          How to improve Engagement Rate
        </p>
        <div className="flex-column gap-16 content">
          <div className="flex-column gap-5">
            <div className="flex-row gap-2">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707200640-Tennis+Player.svg"
                alt="tennis"
                width={16}
                height={16}
              />
              <p className="font-xsmall bold" style={{ color: "#000000" }}>
                Active Time
              </p>
            </div>
            <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
              Period when the player is actively participating in the game or
              match
            </p>
          </div>
          <div className="flex-column gap-5">
            <div className="flex-row gap-2">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707200640-Man.svg"
                alt="man"
                width={16}
                height={16}
              />
              <p className="font-xsmall bold" style={{ color: "#000000" }}>
                Idle Time
              </p>
            </div>
            <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
              Period when the player is taking breaks or resting
            </p>
          </div>
          <div className="flex-column gap-5">
            <div className="flex-row gap-2">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707200640-Frame.svg"
                alt="examples"
                width={16}
                height={16}
              />
              <p className="font-xsmall bold" style={{ color: "#000000" }}>
                Example
              </p>
            </div>
            <div>
              <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                In a 100-minute match:
              </p>
              <div className="flex-row align-items-center gap-6">
                <div
                  className="border-round"
                  style={{ background: "#AFAFAF", height: "2px", width: "2px" }}
                ></div>
                <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                  Active Time : 70 minutes
                </p>
              </div>
              <div className="flex-row align-items-center gap-6">
                <div
                  className="border-round"
                  style={{ background: "#AFAFAF", height: "2px", width: "2px" }}
                ></div>
                <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                  Idle Time : 30
                </p>
              </div>
              <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                So, active time is 70% and idle time is 30%
              </p>
            </div>
          </div>
          <div className="inference p-6">
            <div className="flex-row gap-4 align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707200640-Conclusion+Contract.svg"
                alt="contract"
                width={16}
                height={16}
              />
              <p className="font-xsmall bold" style={{ color: "#000000" }}>
                Inference
              </p>
            </div>
            <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
              More active time means higher engagement; more idle time indicates
              lower engagement
            </p>
          </div>
        </div>
      </div>
      <div
        className="flex-column gap-4"
        style={{ marginTop: "16px", marginBottom: "20px" }}
      >
        <p className="font-small bolder" style={{ color: "#5F5F5F" }}>
          What is Engagement Rate?
        </p>
        <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
          Engagement balance measures the active versus idle time ratio during
          gameplay
        </p>
      </div>
    </div>
  );
};

export default EngagementRateInfo;
