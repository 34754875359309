import React, { useEffect, useState } from "react";
import "./MatchSummary.scss";
import { timeDisplay } from "../../utils/Helper";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";
import { Match } from "@testing-library/react";
import { MatchDetails } from "../../models/Match";

interface MatchSummaryProps {
  match: MatchDetails;
}

const MatchSummary: React.FC<MatchSummaryProps> = ({ match }) => {
  const [stats, setStats] = useState({ A: 0, B: 0 });
  const [players, setPlayers] = useState({
    A: {
      initials: "",
      name: "",
    },
    B: {
      initials: "",
      name: "",
    },
  });
  const navigate = useNavigate();
  const backgroundImages: { [key: string]: string } = {
    Padel:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624200747-Racket+%282%29.svg",
    Badminton:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240819112603-Racket.svg",
  };

  useEffect(() => {
    const aWon = parseInt(match.score.split(":")[0]);
    const bWon = parseInt(match.score.split(":")[1]);
    if (aWon > bWon) {
      setPlayers({ A: match.winner, B: match.loser });
    } else {
      setPlayers({ A: match.loser, B: match.winner });
    }
    setStats({ A: aWon, B: bWon });
  }, []);

  return (
    <div
      className="match-summary flex-column gap-16"
      onClick={() => navigate(PATHS.MATCH(match.match_id))}
    >
      <div className="header gap-28">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-padel_park_logo.svg"
          alt="Padel Logo"
          className="logo"
          width={27}
          height={19}
        />
        <span className="sport">{match.sport}</span>
      </div>
      <div className="match-info">
        <img
          src={backgroundImages[match.sport] || ""}
          alt="backgrop"
          className="racket-backdrop"
          width={300}
        />
        <div className="team" style={{ alignItems: "flex-start" }}>
          {stats.A > stats.B && (
            <div className="trophy" style={{ left: "-23px" }}>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-championship.svg"
                alt="trophy"
                width={16}
                height={15}
              />
            </div>
          )}
          <div className={`team-icon ${stats.A > stats.B && "winning-icon"}`}>
            {players.A.initials}
          </div>
          <div className="team-details">
            {players.A.name.split(" / ").map((name, index) => (
              <p
                key={index}
                className={`team-name ${stats.A > stats.B && "highlight"}`}
              >
                {name.split(" ")[0]}
              </p>
            ))}
          </div>
        </div>
        {match.sets > 0 ? (
          <div className="score-wrapper flex-column">
            <div className="score flex-row gap-8 align-items-center">
              <span
                className={`${stats.A > stats.B ? "winner" : "score-value"}`}
              >
                {stats.A}
              </span>
              <span className="winner">:</span>
              <span
                className={`${stats.B > stats.A ? "winner" : "score-value"}`}
              >
                {stats.B}
              </span>
            </div>
            <span className="sets px-6">Sets {match.sets}</span>
          </div>
        ) : (
          <div
            className="flex-column gap-1 align-items-center"
            style={{ zIndex: "50" }}
          >
            <p className="font-xlarge bold" style={{ color: "#222222" }}>
              VS
            </p>
            <p
              className="font-xsmall bold"
              style={{ color: "#AFAFAF", width: "120px", textAlign: "center" }}
            >
              No score was added after the match
            </p>
          </div>
        )}
        <div className="team" style={{ alignItems: "flex-end" }}>
          {stats.B > stats.A && (
            <div className="trophy" style={{ right: "-23px" }}>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-championship.svg"
                alt="trophy"
                width={16}
                height={15}
              />
            </div>
          )}
          <div className={`team-icon ${stats.B > stats.A && "winning-icon"}`}>
            {players.B.initials}
          </div>
          <div className="team-details">
            {players.B.name.split(" / ").map((name, index) => (
              <p
                key={index}
                className={`team-name ${stats.B > stats.A && "highlight"}`}
              >
                {name.split(" ")[0]}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className="match-meta">
        <div className="meta-item">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240623195216-Frame+%281%29.svg"
            alt="Date"
            className="meta-icon"
          />
          <span className="meta-text">{match.date}</span>
        </div>
        <div className="dot" />
        <div className="meta-item">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-clock.svg"
            alt="Time"
            className="meta-icon"
          />
          <span className="meta-text">{match.duration}</span>
        </div>
        <div className="dot" />
        <div className="meta-item">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618190709-people.svg"
            alt="Players"
            className="meta-icon"
          />
          <span className="meta-text">{match.category}</span>
        </div>
      </div>
    </div>
  );
};

export default MatchSummary;
