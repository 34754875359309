interface Environment {
  production: boolean;
  staging: boolean;
  local: boolean;
  baseUrl: string;
  clientUrl: string;
}

const environmentLocal: Environment = {
  production: false,
  staging: false,
  local: true,
  baseUrl: "https://padel.stupaevents.com",
  clientUrl: "http://localhost:3001",
};

const environmentProduction: Environment = {
  production: true,
  staging: false,
  local: false,
  baseUrl: "https://padel.stupaevents.com",
  clientUrl: "https://analytics.stupa.co",
};

const environment: Environment =
  process.env.REACT_APP_ENV === "local"
    ? environmentLocal
    : environmentProduction;

export default environment;
