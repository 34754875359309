import React, { useEffect } from "react";
import "./Home.scss";
import SplashScreen from "../../components/SplashScreen/SplashScreen";
import { useNavigate, useParams } from "react-router-dom";
import { setCookie } from "../../services/cookieService";

const Home: React.FC = () => {
  const { court_number } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (court_number !== "" && !isNaN(Number(court_number || ""))) {
      setCookie("MATCH_COURT_ID", court_number || "");
    } else {
      navigate("/1");
    }
  }, [court_number]);

  return <SplashScreen />;
};

export default Home;
