import {
  UpdateUserDetailsPayload,
  UserActions,
  UserActionTypes,
  UserDetails,
} from "./userTypes";
import {
  JWT_TOKEN,
  removeCookie,
  setCookie,
} from "../../services/cookieService";
import { User } from "../../models/User";

export interface UserState {
  phone_number: string | null;
  country_code: string | null;
  first_name: string | null;
  last_name: string | null;
  image_url: string | null;
  email: string | null;
  age: number | null;
  weight: number | null;
  height: number | null;
  gender: "M" | "F" | null;
  jwtToken: string | null;
  loading: boolean;
  profileLoading: boolean;
  user_id: number | null;
  error: string | null | undefined;
}

const initialState: UserState = {
  phone_number: null,
  country_code: null,
  email: null,
  first_name: null,
  last_name: null,
  image_url: null,
  age: null,
  weight: null,
  height: null,
  gender: null,
  jwtToken: null,
  loading: true,
  user_id: null,
  profileLoading: false,
  error: null,
};

const userReducer = (
  state = initialState,
  action: UserActionTypes,
): UserState => {
  switch (action.type) {
    case UserActions.FETCH_USER:
      return {
        ...state,
        loading: true,
      };
    case UserActions.FETCH_USER_SUCCESS:
      return {
        ...state,
        ...(action.payload as User),
        loading: false,
        error: null,
      };
    case UserActions.FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserActions.VERIFY_OTP:
      return {
        ...state,
        loading: true,
      };
    case UserActions.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        jwtToken: action.payload as string,
        loading: false,
        error: null,
      };
    case UserActions.VERIFY_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserActions.GET_USER_DETAILS:
      return {
        ...state,
        loading: true,
        profileLoading: true,
      };
    case UserActions.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        ...(action.payload as UserDetails),
        loading: false,
        profileLoading: false,
        error: null,
      };
    case UserActions.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        profileLoading: false,
        error: action.error,
      };
    case UserActions.UPDATE_USER_DETAILS:
      return {
        ...state,
      };
    case UserActions.UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        ...(action.payload as UpdateUserDetailsPayload),
        error: null,
      };
    case UserActions.UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case UserActions.LOGOUT_USER:
      removeCookie(JWT_TOKEN);
      return {
        ...state,
        phone_number: null,
        country_code: null,
        email: null,
        first_name: null,
        last_name: null,
        image_url: null,
        age: null,
        weight: null,
        height: null,
        gender: null,
        jwtToken: null,
      };
    default:
      return state;
  }
};

export default userReducer;
