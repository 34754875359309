import React, { useEffect, useState } from "react";
import "./WinPercentGraph.scss";
import CircleGraph from "../CircleGraph/CircleGraph";
import { WinMessage, percentWinMessage } from "../../data/WinPercent.data";

interface WinPercentGraphProps {
  openInfo: () => void;
  completionPercent: number;
}

const WinPercentGraph: React.FC<WinPercentGraphProps> = ({
  openInfo,
  completionPercent,
}) => {
  const [winMessage, setWinMessage] = useState(WinMessage.empty);

  useEffect(() => {
    setWinMessage(percentWinMessage(completionPercent));
  }, [completionPercent]);

  return (
    <div className="win-percent-details-container p-12 flex-column align-items-center">
      <div className="backdrop-image" />
      <div className="flex-row align-items-end full-width justify-content-space-between position-relative">
        <div className="flex-column gap-12" style={{ zIndex: "20" }}>
          <div className="flex-row gap-12 align-items-center">
            <p className="font-large bold" style={{ color: "#222222" }}>
              Win %
            </p>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626091405-infop.svg"
              alt="info"
              width={12}
              height={12}
              onClick={openInfo}
            />
          </div>
          <div className="win-percent-details flex-row align-items-center justify-content-center">
            <div className="background">
              <CircleGraph
                width={68}
                strokeWidth={4}
                percent={100}
                color="#E2E2E2"
                animate={false}
              />
            </div>
            {completionPercent > 0 && (
              <>
                <div className="foreground">
                  <CircleGraph
                    width={68}
                    strokeWidth={4}
                    percent={completionPercent}
                    color="#79EC63"
                  />
                </div>
                <p className="font-xxlarge bolder" style={{ color: "#79EC63" }}>
                  {completionPercent}
                  <span
                    className="font-small bolder"
                    style={{ color: "#AFAFAF" }}
                  >
                    %
                  </span>
                </p>
              </>
            )}
            {completionPercent === 0 && (
              <p
                className="font-xsmall bold"
                style={{ color: "#afafaf", textAlign: "center" }}
              >
                No Data Found
              </p>
            )}
          </div>
        </div>
        <div className="flex-row win-graph justify-content-center">
          <p className="font-small bolder win-msg">{winMessage?.title}</p>
        </div>
        <img src={winMessage?.graph} alt="graph" width={154} />
      </div>
      <div className="separator"></div>
      <div
        className="flex-row gap-18 align-items-center full-width border-box px-18 py-6"
        style={{
          background: "#EEEEEE",
          borderRadius: "10px",
          marginTop: "10px",
        }}
      >
        {winMessage?.icon !== "" && (
          <img src={winMessage?.icon} width={26} height={26} />
        )}
        <p className="font-xsmall bold" style={{ color: "#5f5f5f" }}>
          {winMessage?.message}
        </p>
      </div>
    </div>
  );
};

export default WinPercentGraph;
