import React, { useEffect, useState } from "react";
import "./PlayTimeAnalytics.scss";
import CircleGraph from "../CircleGraph/CircleGraph";
import { Chart, ScriptableContext } from "chart.js/auto";
import { getTotalTime } from "../../utils/Helper";
import NoAnalyticsData from "../NoAnalyticsData/NoAnalyticsData";

interface HeatMapProps {
  noData: boolean;
  activePlayTime: string;
  idlePlayTime: string;
  distance: {
    A: number;
    B: number;
  };
}

const PlayTimeAnalytics: React.FC<HeatMapProps> = ({
  noData,
  activePlayTime,
  idlePlayTime,
  distance,
}) => {
  const [mini, setMini] = useState(true);
  const [positions, setPositions] = useState<{ x: number; y: number }[]>([
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ]);
  const [stats, setStats] = useState<{
    activePlayTime: number;
    idlePlayTime: number;
    distance: {
      A: number;
      B: number;
    };
  }>({
    activePlayTime: 0,
    idlePlayTime: 0,
    distance: {
      A: 0,
      B: 0,
    },
  });

  useEffect(() => {
    const activeTime = Math.round(
      (getTotalTime(activePlayTime) * 100) /
        (getTotalTime(activePlayTime) + getTotalTime(idlePlayTime)),
    );
    setStats({
      distance,
      activePlayTime: activeTime,
      idlePlayTime: 100 - activeTime,
    });
  }, [activePlayTime, idlePlayTime, distance]);

  const teams = {
    A: [
      {
        name: "Rajesh",
        avatar:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618071655-4.webp",
      },
      {
        name: "Mukesh",
        avatar:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618071113-2.webp",
      },
    ],
    B: [
      {
        name: "Suresh",
        avatar:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618071656-14.webp",
      },
      {
        name: "Prakash",
        avatar:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618071656-13.webp",
      },
    ],
  };

  useEffect(() => {
    distanceGraph();
  }, [mini]);

  const distanceGraph = async () => {
    await clearChart();
    const data = [
      { team: "A", count: stats.distance.A * 1000 },
      { team: "B", count: stats.distance.B * 1000 },
    ];

    const max =
      (Math.ceil(Math.max(stats.distance.A, stats.distance.B)) + 0.5) * 1000;

    const canvas = document.getElementById("distance-travelled-canvas");
    if (canvas) {
      const ctx = new Chart(canvas as HTMLCanvasElement, {
        type: "bar",
        data: {
          labels: data.map((row) => row.team),
          datasets: [
            {
              label: "Speed",
              data: data.map((row) => row.count),
              backgroundColor: (ctx: ScriptableContext<"bar">) => {
                const meta = ctx.chart.getDatasetMeta(0);
                meta.data.forEach((bar, index) => {
                  if (bar.x && bar.y) {
                    setPositions((val) => {
                      const result = [...val];
                      result[index] = { x: bar.x, y: bar.y };
                      return result;
                    });
                  }
                });
                return ctx.dataIndex === 0 ? "#6FA8A8" : "#73C58A";
              },
              borderRadius: 10,
              barThickness: 50,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              title: {
                text: "metre",
                color: "red",
                font: {
                  family: "Poppins",
                },
              },
              max,
              beginAtZero: true,
              grid: {
                display: true,
                drawOnChartArea: false,
                tickLength: 4,
              },
              border: {
                display: false,
              },
              ticks: {
                color: "#AFAFAF",
                font: {
                  size: 10,
                  weight: 500,
                  family: "Poppins",
                },
                maxTicksLimit: 10,
              },
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          },
          animation: {
            duration: 0,
          },
        },
      });
    }
  };

  const clearChart = async () => {
    const chartStatus = Chart.getChart("distance-travelled-canvas"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
  };

  const efficiencyEmoji = (percent: number) => {
    if (percent < 100) {
      return "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240623090821-amorous+emoji.svg";
    }
    return "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240623090821-amorous+emoji.svg";
  };

  return (
    <div className="playtime-content flex-column gap-24">
      <div
        className="top-row flex-row align-items-center gap-16"
        onClick={() => setMini((val) => !val)}
      >
        <div className="logo-container align-items-center flex-row justify-content-center">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626194322-shot_analytics.svg"
            alt="play time"
            width={34}
            height={34}
          />
        </div>
        <div className="flex-column gap-4 flex-1">
          <p className="title font-large bold" style={{ color: "#222222" }}>
            Playtime Analytics
          </p>
          <p className="title font-mid " style={{ color: "#AFAFAF" }}>
            {noData ? "No Data" : "Efficiency and distance travelled"}
          </p>
        </div>
        <div
          className="flex-row align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240622171408-top-black-row.svg"
            alt="top-row"
            width={12}
            className="px-8"
            style={{ transform: `${mini ? "rotate(180deg)" : "rotate(0deg)"}` }}
          />
        </div>
      </div>
      {!mini &&
        (noData ? (
          <NoAnalyticsData />
        ) : (
          <>
            <div className="separator"></div>
            <div
              className="px-10 py-5 flex-row gap-10 align-items-center"
              style={{ background: "#F7F7F7", borderRadius: "10px" }}
            >
              <div className="flex-column gap-5 flex-1">
                <p className="font-small bold" style={{ color: "#000000" }}>
                  Playing Efficiency
                </p>
                <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                  % of time player was active during the whole match
                </p>
              </div>
              <p className="font-xlarge bolder" style={{ color: "#000000" }}>
                {stats.activePlayTime}%
              </p>
              <img
                src={efficiencyEmoji(stats.activePlayTime)}
                alt="happy"
                width={24}
                height={24}
              />
            </div>
            <div className="flex-column gap-14 align-items-start">
              <div className="flex-row justify-content-center gap-8 full-width">
                <div className="flex-row align-items-center align-items-center gap-4">
                  <div
                    className="border-round play-indicator"
                    style={{ background: "#73C58A" }}
                  ></div>
                  <p className="font-xsmall bold" style={{ color: "#000000" }}>
                    Active
                  </p>
                </div>
                <div className="flex-row align-items-center align-items-center gap-4">
                  <div
                    className="border-round play-indicator"
                    style={{ background: "#6FA8A8" }}
                  ></div>
                  <p className="font-xsmall bold" style={{ color: "#000000" }}>
                    Idle
                  </p>
                </div>
                <div className="flex-row align-items-center align-items-cente gap-4">
                  <div
                    className="border-round play-indicator"
                    style={{ background: "#D9D9D9" }}
                  ></div>
                  <p className="font-xsmall bold" style={{ color: "#000000" }}>
                    Total Playtime
                  </p>
                </div>
              </div>
              <div className="flex-row align-items-start justify-content-center rally-time-container">
                <div className="flex-column gap-14 align-items-center justify-content-center gap-14 rally-stats">
                  <div className="flex-row gap-2 align-items-center">
                    <img
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240627120614-Man.svg"
                      alt="idle"
                      width={18}
                      height={18}
                    />
                    <p
                      className="font-small bolder"
                      style={{ color: "#AFAFAF" }}
                    >
                      {stats.idlePlayTime}%
                    </p>
                  </div>
                  <div className="flex-row gap-2 align-items-center">
                    <img
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240627120614-Tennis+Player+%281%29.svg"
                      alt="active"
                      width={18}
                      height={18}
                    />
                    <p
                      className="font-small bolder"
                      style={{ color: "#AFAFAF" }}
                    >
                      {stats.activePlayTime}%
                    </p>
                  </div>
                </div>
                <div className="rally-graph-container">
                  <CircleGraph
                    width={146}
                    strokeWidth={12}
                    color="#D9D9D9"
                    percent={100}
                  />
                  <div className="active-graph">
                    <CircleGraph
                      width={106}
                      strokeWidth={10}
                      color="#73C58A"
                      percent={stats.activePlayTime}
                    />
                  </div>
                  <div className="idle-graph">
                    <CircleGraph
                      width={66}
                      strokeWidth={8}
                      color="#6FA8A8"
                      percent={stats.idlePlayTime}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-column gap-8">
              <p className="font-large bold" style={{ color: "#222222" }}>
                Distance Travelled
              </p>
              <div className="flex-column py-5 gap-8">
                <div className="flex-row justify-content-space-between align-items-center">
                  <div className="flex-row gap-4">
                    <div className="flex-row">
                      <img
                        src={teams.A[0].avatar}
                        alt="t1"
                        width={14}
                        height={14}
                        className="border-round"
                      />
                      <img
                        src={teams.A[1].avatar}
                        alt="t1"
                        width={14}
                        height={14}
                        className="border-round"
                      />
                    </div>
                    <p
                      className="font-xsmall bold"
                      style={{ color: "#000000" }}
                    >
                      Team A
                    </p>
                  </div>
                  <p className="font-xsmall bold" style={{ color: "#6FA8A8" }}>
                    {stats.distance.A} m
                  </p>
                </div>
                <div className="graph-full-container">
                  <div
                    className="graph-fill-container"
                    style={{
                      width: `${
                        (stats.distance.A * 100) /
                        (stats.distance.A + stats.distance.B)
                      }%`,
                      background: "#6FA8A8",
                    }}
                  ></div>
                </div>
                <div className="flex-column py-5 gap-8">
                  <div className="flex-row justify-content-space-between align-items-center">
                    <div className="flex-row gap-4">
                      <div className="flex-row">
                        <img
                          src={teams.B[0].avatar}
                          alt="t1"
                          width={14}
                          height={14}
                          className="border-round"
                        />
                        <img
                          src={teams.B[1].avatar}
                          alt="t1"
                          width={14}
                          height={14}
                          className="border-round"
                        />
                      </div>
                      <p
                        className="font-xsmall bold"
                        style={{ color: "#000000" }}
                      >
                        Team B
                      </p>
                    </div>
                    <p
                      className="font-xsmall bold"
                      style={{ color: "#73C58A" }}
                    >
                      {stats.distance.B} m
                    </p>
                  </div>
                  <div className="graph-full-container">
                    <div
                      className="graph-fill-container"
                      style={{
                        width: `${
                          (stats.distance.B * 100) /
                          (stats.distance.A + stats.distance.B)
                        }%`,
                        background: "#73C58A",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default PlayTimeAnalytics;
