import { put, call, takeLatest, all } from "redux-saga/effects";
import {
  fetchUserSuccess,
  fetchUserFailure,
  verifyOtpSuccess,
  verifyOtpFailure,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  getUserDetails,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
} from "./userActions";
import {
  UpdateUserDetailsAction,
  UserActions,
  VerifyOtpAction,
} from "./userTypes";
import ApiService from "../../services/apiService";
import { User } from "../../models/User";
import URL from "../../urls/Urls";
import { setCookie } from "../../services/cookieService";

function* getUserSaga() {
  try {
    const { data } = yield call(ApiService().client.get, URL.USERS.GET_USER);
    const user: User = data.user;
    yield put(fetchUserSuccess(user));
  } catch (error) {
    yield put(fetchUserFailure("Error fetching user data."));
  }
}

function* watchGetUser() {
  yield takeLatest(UserActions.FETCH_USER, getUserSaga);
}

function* getVerifyOtpSaga(acion: VerifyOtpAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.USERS.VERIFY_OTP,
      acion.payload,
    );
    yield put(verifyOtpSuccess(data.data.token));
    setCookie("JWT_TOKEN", data.data.token);
  } catch (error) {
    yield put(verifyOtpFailure("Error fetching user data."));
  }
}

function* watchVerifyOtp() {
  yield takeLatest(UserActions.VERIFY_OTP, getVerifyOtpSaga);
}

function* getUserDetailsSaga() {
  try {
    const { data } = yield call(ApiService().client.get, URL.USERS.GET_USER);
    yield put(getUserDetailsSuccess(data.data));
  } catch (error) {
    yield put(getUserDetailsFailure("Error fetching user data."));
  }
}

function* watchGetUserDetails() {
  yield takeLatest(UserActions.GET_USER_DETAILS, getUserDetailsSaga);
}

function* updateUserDetailsSaga(action: UpdateUserDetailsAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.USERS.UPDATE_USER,
      action.payload,
    );
    yield put(updateUserDetailsSuccess(action.payload));
  } catch (error) {
    yield put(updateUserDetailsFailure("Error fetching user data."));
  }
}

function* watchUpdatetUserDetails() {
  yield takeLatest(UserActions.UPDATE_USER_DETAILS, updateUserDetailsSaga);
}

export default function* userSaga() {
  yield all([
    watchGetUser(),
    watchVerifyOtp(),
    watchGetUserDetails(),
    watchUpdatetUserDetails(),
  ]);
}
