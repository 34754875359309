// src/redux/user/userSelector.ts
import { createSelector } from "reselect";
import { RootState } from "../store";
import { UserState } from "./userReducer";
import { isNotNull } from "../../utils/Helper";

// Non-memoized selector function to access the user state from the Redux store
const selectUserState = (state: RootState): UserState => state.user;

// Memoized selector using createSelector
export const selectUser = createSelector(
  [selectUserState],
  (userState) => userState,
);

export const isAuthenticated = createSelector([selectUserState], (userState) =>
  isNotNull(userState.jwtToken),
);
export const selectAuthToken = createSelector(
  [selectUserState],
  (userState) => userState.jwtToken,
);

export const selectUserName = createSelector(
  [selectUserState],
  (userState) => userState.first_name,
);

export const selectUserAvatar = createSelector(
  [selectUserState],
  (userState) => userState.image_url,
);

export const selectUserEmail = createSelector(
  [selectUserState],
  (userState) => userState.email,
);

export const isUserLoading = createSelector(
  [selectUserState],
  (userState) => userState.loading,
);

export const isProfileLoading = createSelector(
  [selectUserState],
  (userState) => userState.profileLoading,
);

export const selectUserDetails = createSelector(
  [selectUserState],
  (userState) => {
    return {
      id: userState.user_id,
      first_name: userState.first_name,
      last_name: userState.last_name,
      avatar: userState.image_url,
      mobile: userState.phone_number,
      countryCode: userState.country_code,
      email: userState.email,
      gender: userState.gender,
      age: userState.age,
      weight: userState.weight,
      height: userState.height,
    };
  },
);
