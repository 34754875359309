import React, { useState } from "react";
import "./GameTypeOnboarding.scss";
import RecordMatchHeader from "../../components/RecordMatchHeader/RecordMatchHeader";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";
import { useSelector } from "react-redux";
import { selectUserAvatar } from "../../redux/user/userSelectors";
import { Court } from "../../models/Court";

interface GameTypeOnboardingProps {
  setGame: (val: string) => void;
  court: Court | undefined;
}

const GameTypeOnboarding: React.FC<GameTypeOnboardingProps> = ({
  setGame,
  court,
}) => {
  const [gameSelected, setGameSelected] = useState("");
  const navigate = useNavigate();
  const userAvatar = useSelector(selectUserAvatar);
  const [COURT_IMAGES, _setCourtImages] = useState(
    court?.sport_id === 2
      ? {
          top_view:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240725062858-Frame+1261158835.svg",
          player_view:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240725062858-Group+1000004678.svg",
        }
      : {
          top_view:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240619181654-Group+1000004666+%281%29.svg",
          player_view:
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240619181654-Group+1000004666+%282%29.svg",
        },
  );
  const ALLOWED_TYPES: { [key: string]: string[] } = {
    2: ["SINGLES", "DOUBLES"],
    1: ["DOUBLES"],
  };

  const handleSingleSelect = () => {
    setGameSelected("SINGLES");
  };

  const handleDoubleSelect = () => {
    setGameSelected("DOUBLES");
  };

  return (
    <div className="game-type-onboarding-container">
      <RecordMatchHeader
        showBack={true}
        onBack={() => navigate(PATHS.DASHBOARD)}
      />
      <div className="game-type-selection-component px-20">
        <div className="game-type-header">What is your game type?</div>
        <div className="game-type-container">
          {(ALLOWED_TYPES[court?.sport_id || ""] || []).indexOf("SINGLES") >
            -1 && (
            <div
              className={`game-type ${
                gameSelected === "SINGLES" ? "selected" : ""
              }`}
              onClick={handleSingleSelect}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240701122522-Frame+1261158260.png"
                alt="singles"
                width={87}
                height={83}
              />
              <div className="game-type-desc">
                <div className="game-type-desc-header">Singles</div>
                <div className="game-type-desc-content">
                  Head-to-Head Athletic Showdown
                </div>
              </div>
            </div>
          )}
          {(ALLOWED_TYPES[court?.sport_id || ""] || []).indexOf("DOUBLES") >
            -1 && (
            <div
              className={`game-type ${
                gameSelected === "DOUBLES" ? "selected" : ""
              }`}
              onClick={handleDoubleSelect}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240701122522-Frame+1261158260+%281%29.png"
                alt="doubles"
                width={87}
                height={83}
              />
              <div className="game-type-desc">
                <div className="game-type-desc-header">Doubles</div>
                <div className="game-type-desc-content">
                  Dynamic Duo Competitive Clash
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Conditional Rendering based on singleSelected or doubleSelected */}
      {gameSelected !== "" ? (
        <div className="game-type-court-container-selected">
          <div className="court-container">
            <img
              src={COURT_IMAGES.player_view}
              alt="right"
              className="court-top"
            />
          </div>
          <div
            className={`team-template-container py-12 flex-row align-items-center px-20 team-a ${
              gameSelected === "DOUBLES" && "doubles-a"
            }`}
          >
            {gameSelected === "DOUBLES" && (
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg"
                alt="usera-2"
                width={40}
                height={40}
              />
            )}
            <div className="flex-column gap-4 align-items-center">
              <img
                src={userAvatar || ""}
                alt="usera-1"
                width={40}
                height={40}
              />
              <p className="player-name">You</p>
            </div>
          </div>
          <div
            className={`team-template-container flex-row align-items-center justify-content-space-between team-b ${
              gameSelected === "DOUBLES" && "doubles-b"
            }`}
          >
            {gameSelected === "DOUBLES" && (
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg"
                alt="usera-2"
                width={40}
                height={40}
              />
            )}
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617070418-Vector+%281%29.svg"
              alt="usera-2"
              width={40}
              height={40}
            />
          </div>
          <div
            className="court-select-cta-container px-20 py-16"
            onClick={() => setGame(gameSelected)}
          >
            <div className="court-select-cta py-14 gap-8">
              <p>Proceed</p>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617193647-Frame.svg"
                alt="right"
                width={20}
                height={20}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="game-type-court-container flex-row justify-content-center align-items-flex-end">
          <img
            src={COURT_IMAGES.top_view}
            alt="top-view"
            className="top-view"
          ></img>
        </div>
      )}
    </div>
  );
};

export default GameTypeOnboarding;
