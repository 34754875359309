import React, { useEffect, useState } from "react";
import "./Analytics.scss";
import HeatMap from "../../components/HeatMap/HeatMap";
import ShotAnalytics from "../../components/ShotAnalytics/ShotAnalytics";
import PlayTimeAnalytics from "../../components/PlayTimeAnalytics/PlayTimeAnalytics";
import RallyAnalysis from "../../components/RallyAnalysis/RallyAnalysis";
import Highlights from "../../components/Highlights/Highlights";
import { share } from "rxjs";
import { useNavigate, useParams } from "react-router-dom";
import PATHS from "../../urls/Path";
import ShareHighlights from "../../components/ShareHighlights/ShareHighlights";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import {
  MatchAnalyticStats,
  MatchScoreCard,
  TeamAnalyticsStats,
} from "../../models/Analytics";

const Analytics: React.FC = () => {
  const [tabName, setTabName] = useState("STATS");
  const [shareOn, setShareOn] = useState(false);
  const [noData, setNoData] = useState(true);
  const [teamInitials, setTeamInitials] = useState<{ A: string; B: string }>({
    A: "",
    B: "",
  });
  const [teamDetails, setTeamDetails] = useState<{
    A: {
      first_name: string;
      last_name: string;
      imager_url: string;
    }[];
    B: {
      first_name: string;
      last_name: string;
      imager_url: string;
    }[];
  }>({
    A: [],
    B: [],
  });
  const [matchStats, setMatchStats] = useState<MatchAnalyticStats>();
  const [teamAStats, setTeamAStats] = useState<TeamAnalyticsStats>();
  const [teamBStats, setTeamBStats] = useState<TeamAnalyticsStats>();
  const [scorecard, setScorecard] = useState<MatchScoreCard>();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getAnalytics();
  }, []);

  const getAnalytics = async () => {
    try {
      const res = await ApiService().client.get(URL.ANALYTICS.MATCH_STATS, {
        params: {
          match_id: id,
        },
      });
      if (res.status === 200) {
        setMatchStats(res.data.data.match_stats[0]);
        setTeamAStats(res.data.data.team_A_data[0]);
        setTeamBStats(res.data.data.team_B_data[0]);
        setTeamInitials({
          A: res.data.data.team_A_initials,
          B: res.data.data.team_B_initials,
        });
        setScorecard(res.data.data.scorecard);
        setTeamDetails({
          A: res.data.data.team_A_players,
          B: res.data.data.team_B_players,
        });
        if (res.data.data.match_stats.length > 0) {
          setNoData(false);
        }
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  return (
    <div className="analytics-container flex-column">
      <div className="analytics-header">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617103535-Vector+%282%29.svg"
          className="back-btn"
          width={8}
          height={14}
          alt="back"
          onClick={() => navigate(PATHS.ALL_MATCHES)}
        />
        <div className="logo-container">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240705110302-Logo.webp"
            className="stupa-logo"
            width={54}
          />
        </div>
        <p className="title">Match Report</p>
      </div>
      {shareOn ? (
        <div style={{ height: "58px", width: "100%" }}></div>
      ) : (
        <div className="tab-container p-18 flex-row gap-40">
          <p
            className={`tab-name ${tabName === "STATS" && "selected"}`}
            onClick={() => setTabName("STATS")}
          >
            AI Analytics
          </p>
          {scorecard && (
            <p
              className={`tab-name ${tabName === "HIGHLIGHTS" && "selected"}`}
              onClick={() => setTabName("HIGHLIGHTS")}
            >
              Highlights
            </p>
          )}
        </div>
      )}
      {tabName === "STATS" ? (
        <div className="analytics-content-container flex-column gap-8 flex-1 px-20 py-8">
          <HeatMap
            noData={noData}
            percentageDistribution={
              matchStats?.player_foot_heatmap_percentage_distribution[0] || ""
            }
            teamDetails={teamDetails}
            active={matchStats?.player_foot_heatmap_active[0] || ""}
          />
          <PlayTimeAnalytics
            noData={noData}
            activePlayTime={matchStats?.total_active_time || ""}
            idlePlayTime={matchStats?.total_idle_time || ""}
            distance={{
              A: teamAStats?.distance_travelled || 0,
              B: teamBStats?.distance_travelled || 0,
            }}
          />
          <ShotAnalytics
            teamAStats={teamAStats}
            teamBStats={teamBStats}
            noData={noData}
            teamInitials={teamInitials}
          />
          <RallyAnalysis matchStats={matchStats} noData={noData} />
        </div>
      ) : (
        <div className="analytics-content-container flex-column gap-8 flex-1">
          {scorecard && (
            <Highlights
              toggleShare={() => setShareOn((val) => !val)}
              share={shareOn}
              videoUrl={matchStats?.highlights_url || ""}
              scorecard={scorecard}
              teamInitials={teamInitials}
              teamDetails={teamDetails}
            />
          )}
        </div>
      )}
      {shareOn && <ShareHighlights toggleShare={() => setShareOn(false)} />}
    </div>
  );
};

export default Analytics;
