import React, { useEffect, useState } from "react";
import "./ProfileAdditionalInfo.scss";
import { UserProfile } from "../../models/User";

interface ProfileAdditionalInfoProps {
  details: UserProfile;
  editDetail: (key: string, value: string) => void;
}

const ProfileAdditionalInfo: React.FC<ProfileAdditionalInfoProps> = ({
  details,
  editDetail,
}) => {
  const [height, setHeight] = useState(parseInt(details.height) || 165);
  const [weight, setWeight] = useState(parseInt(details.weight) || 60);

  useEffect(() => {
    setHeight(parseInt(details.height) || 165);
    setWeight(parseInt(details.weight) || 60);
  }, [details]);

  const increaseHeight = () => {
    editDetail("height", (height + 1).toString());
  };

  const decreaseHeight = () => {
    editDetail("height", (height - 1).toString());
  };

  const increaseWeight = () => {
    editDetail("weight", (weight + 1).toString());
  };

  const decreaseWeight = () => {
    editDetail("weight", (weight - 1).toString());
  };

  return (
    <div className="additional-info-container">
      <div className="flex-row justify-content-space-between align-items-center">
        <p className="info-header bold">Additional Information</p>
        <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
          Optional
        </p>
      </div>
      <div className="info-height-wieght-container">
        <div className="height-weight">
          Height <span className="unit">(cm)</span>
        </div>
        <div className="height-weight-input">
          <div className="increase-value" onClick={decreaseHeight}>
            -
          </div>
          <div className="show-value">{height}</div>
          <div className="decrease-value" onClick={increaseHeight}>
            +
          </div>
        </div>
      </div>
      <div className="info-height-wieght-container">
        <div className="height-weight">
          Weight <span className="unit">(kg)</span>
        </div>
        <div className="height-weight-input">
          <div className="increase-value" onClick={decreaseWeight}>
            -
          </div>
          <div className="show-value">{weight}</div>
          <div className="decrease-value" onClick={increaseWeight}>
            +
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileAdditionalInfo;
