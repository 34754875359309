import React, { useEffect, useState } from "react";
import "./RecordMatchHeader.scss";
import ApiService from "../../services/apiService";
import ApiLoader from "../ApiLoader/ApiLoader";

interface RecordMatchHeaderProps {
  showBack?: boolean;
  onBack?: () => void;
  loader?: boolean;
}

const RecordMatchHeader: React.FC<RecordMatchHeaderProps> = ({
  showBack = false,
  onBack,
  loader = true,
}) => {
  const [apiloading, seApiLoading] = useState(false);

  useEffect(() => {
    const loadingSub = ApiService().loadingSubject.subscribe((res) => {
      seApiLoading(res > 0);
    });
    return () => {
      loadingSub.unsubscribe();
    };
  }, []);

  return (
    <div className="record-match-header overflow-hidden">
      {showBack && (
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617103535-Vector+%282%29.svg"
          className="back-btn"
          width={8}
          height={14}
          alt="back"
          onClick={onBack}
        />
      )}
      <div className={`logo-container ${!showBack && "no-back-logo"}`}>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240705110302-Comnbined+logo.webp"
          className="client-logo"
          width={100}
        />
      </div>
      <p className="title">Manage</p>
      {apiloading && loader && <ApiLoader />}
    </div>
  );
};

export default RecordMatchHeader;
