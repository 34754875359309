import React, { useEffect, useState } from "react";
import "./EmailConfirm.scss";
import RecordMatchHeader from "../../components/RecordMatchHeader/RecordMatchHeader";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../../redux/user/userActions";
import { selectUserEmail } from "../../redux/user/userSelectors";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";

const EmailConfirm: React.FC = () => {
  const [email, setEmail] = useState("");
  const userEmail = useSelector(selectUserEmail);
  const [timerPlay, setTimerPlay] = useState(3);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userEmail != null && userEmail !== "") {
      let count = 0;
      const interval = setInterval(() => {
        if (count === 2) {
          clearInterval(interval);
          navigate(PATHS.DASHBOARD);
        } else {
          setTimerPlay((val) => val - 1);
          count = count + 1;
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [userEmail]);

  const saveEmail = () => {
    dispatch(
      updateUserDetails({
        email,
      }),
    );
  };

  return (
    <div className="email-confirm-container flex-column">
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710094432-Topology-1.svg"
        alt="patter"
        className="pattern"
      />
      <RecordMatchHeader showBack={false} />
      <div className="flex-column content align-items-center">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710094831-green_tick.svg"
          alt="tick"
          width={42}
          height={28}
        />
        <p
          className="font-xlarge bold message center-text"
          style={{ color: "#000000" }}
        >
          Great your scores have been recorded
        </p>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710094831-chatgpt+robot+holding+loupe.webp"
          alt="robot"
          width={108}
        />
        {userEmail == null ? (
          <>
            <p
              className="font-xlarge bold request center-text"
              style={{ color: "#000000" }}
            >
              Now tell us your mail id for the AI generated stats
            </p>
            <input
              className="email-input font-mid px-8 py-14"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
          </>
        ) : (
          <div className="email-details-container flex-column align-items-center py-26 gap-35 px-18">
            <p
              className="font-large bold center-text"
              style={{ color: "#757575", width: "260px" }}
            >
              We will send your Ai HIghlights here{" "}
            </p>
            <p className="font-small bolder" style={{ color: "#000000" }}>
              {userEmail}
            </p>
          </div>
        )}
      </div>
      {userEmail == null ? (
        <div className="email-confirm" onClick={saveEmail}>
          <p className="save font-large center-text">Save</p>
        </div>
      ) : (
        <>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710111841-Topology-2.svg"
            alt="patter-2"
            className="bottom-pattern"
          />
          <div className="loader-footer">
            <div className="loading-footer"></div>
            <div className="loader-footer-left">
              <div className="loader-footer-timer-circle">{timerPlay}</div>
              <p className="loader-footer-msg">Taking you to Dashboard</p>
            </div>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240615190434-Frame+1261158599.svg"
              alt="triple-arrow"
              width="44"
              height={20}
              style={{ zIndex: "20" }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EmailConfirm;
