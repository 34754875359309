import React, { useState } from "react";
import "./HeatMap.scss";
import { MatchAnalyticStats } from "../../models/Analytics";
import NoAnalyticsData from "../NoAnalyticsData/NoAnalyticsData";

interface HeatMapProps {
  percentageDistribution: string;
  active: string;
  noData: boolean;
  teamDetails: {
    A: { first_name: string; last_name: string; imager_url: string }[];
    B: { first_name: string; last_name: string; imager_url: string }[];
  };
}

const HeatMap: React.FC<HeatMapProps> = ({
  percentageDistribution,
  active,
  noData,
  teamDetails,
}) => {
  const [mini, setMini] = useState(false);
  return (
    <div className="analytics-heatmap-content flex-column gap-18">
      <div
        className="top-row flex-row align-items-center gap-16"
        onClick={() => setMini((val) => !val)}
      >
        <div className="logo-container flex-row align-items-center justify-content-center">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240705111510-Vector+%283%29.svg"
            alt="logo"
            width={28}
            height={28}
          />
        </div>
        <div className="flex-column gap-4 flex-1">
          <p className="title font-large bold" style={{ color: "#222222" }}>
            Heat Map
          </p>
          <p className="title font-mid " style={{ color: "#AFAFAF" }}>
            {noData ? "No Data" : "Player and Ball movement"}
          </p>
        </div>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240622171408-top-black-row.svg"
          alt="top-row"
          width={12}
          className="px-8"
          style={{ transform: `${mini ? "rotate(180deg)" : "rotate(0deg)"}` }}
        />
      </div>
      {!mini &&
        (noData ? (
          <NoAnalyticsData />
        ) : (
          <>
            <div className="flex-column gap-8">
              <p className="font-small bold" style={{ color: "#222222" }}>
                Zone Distribution
              </p>
              <div className="flex-row align-items-center justify-content-space-between">
                <p className="font-small bold" style={{ color: "#AFAFAF" }}>
                  Team A
                </p>
                <p className="font-small bold" style={{ color: "#AFAFAF" }}>
                  Team B
                </p>
              </div>
              <div className="flex-row align-items-center justify-content-space-between">
                <div className="flex-row gap-8 align-items-center">
                  {teamDetails.A.map((player, ind) => (
                    <div
                      className="flex-row gap-2 align-items-center"
                      key={ind}
                    >
                      <img
                        src={player.imager_url}
                        alt="icon-1"
                        width={12}
                        height={12}
                      />
                      <p
                        className="font-xsmall bold"
                        style={{ color: "##7F7F7F" }}
                      >
                        {player.first_name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="flex-row gap-8 align-items-center">
                  {teamDetails.B.map((player, ind) => (
                    <div
                      className="flex-row gap-2 align-items-center"
                      key={ind}
                    >
                      <img
                        src={player.imager_url}
                        alt="icon-1"
                        width={12}
                        height={12}
                      />
                      <p
                        className="font-xsmall bold"
                        style={{ color: "##7F7F7F" }}
                      >
                        {player.first_name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <img
                src={percentageDistribution}
                alt="heatmap-1"
                className="full-width"
              />
              {/* <div className="heat-strength"></div>
            <div className="flex-row justify-content-space-between">
              <p className="font-xsmall bold" style={{ color: "#757575" }}>
                Low
              </p>
              <p className="font-xsmall bold" style={{ color: "#757575" }}>
                Medium
              </p>
              <p className="font-xsmall bold" style={{ color: "#757575" }}>
                High
              </p>
            </div> */}
              <div className="match-msg-container flex-row px-10 py-6 gap-8">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240622174244-Lightning+Bolt.svg"
                  alt="bold"
                  width={28}
                  height={28}
                />
                <p
                  className="font-xsmall bold"
                  style={{ color: "#757575", width: "250px" }}
                >
                  Discover your % movement distribution with our AI-generated
                  heatmap to fine-tune your positioning and boost performance.
                </p>
              </div>
            </div>
            <div className="flex-column gap-8" style={{ marginTop: "16px" }}>
              <p className="font-small bold" style={{ color: "#222222" }}>
                Overall Movement
              </p>
              <div className="flex-row align-items-center justify-content-space-between">
                <p className="font-small bold" style={{ color: "#AFAFAF" }}>
                  Team A
                </p>
                <p className="font-small bold" style={{ color: "#AFAFAF" }}>
                  Team B
                </p>
              </div>
              <div className="flex-row align-items-center justify-content-space-between">
                <div className="flex-row gap-8 align-items-center">
                  {teamDetails.A.map((player, ind) => (
                    <div
                      className="flex-row gap-2 align-items-center"
                      key={ind}
                    >
                      <img
                        src={player.imager_url}
                        alt="icon-1"
                        width={12}
                        height={12}
                      />
                      <p
                        className="font-xsmall bold"
                        style={{ color: "##7F7F7F" }}
                      >
                        {player.first_name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="flex-row gap-8 align-items-center">
                  {teamDetails.B.map((player, ind) => (
                    <div
                      className="flex-row gap-2 align-items-center"
                      key={ind}
                    >
                      <img
                        src={player.imager_url}
                        alt="icon-1"
                        width={12}
                        height={12}
                      />
                      <p
                        className="font-xsmall bold"
                        style={{ color: "##7F7F7F" }}
                      >
                        {player.first_name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <img src={active} alt="heatmap-2" className="full-width" />
              {/* <div className="heat-strength"></div>
            <div className="flex-row justify-content-space-between">
              <p className="font-xsmall bold" style={{ color: "#757575" }}>
                Low
              </p>
              <p className="font-xsmall bold" style={{ color: "#757575" }}>
                Medium
              </p>
              <p className="font-xsmall bold" style={{ color: "#757575" }}>
                High
              </p>
            </div> */}
              <div className="match-msg-container flex-row px-10 py-6 gap-8">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240622174244-Lightning+Bolt.svg"
                  alt="bold"
                  width={28}
                  height={28}
                />
                <p
                  className="font-xsmall bold"
                  style={{ color: "#757575", width: "250px" }}
                >
                  Optimize your game with AI-analyzed movement patterns to
                  enhance your positioning and performance.
                </p>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default HeatMap;
