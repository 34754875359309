import { User } from "../../models/User";
import {
  FetchUserAction,
  FetchUserFailureAction,
  FetchUserSuccessAction,
  GetUserDetailsAction,
  GetUserDetailsFailureAction,
  GetUserDetailsSuccessAction,
  LogOutUserAction,
  SetUserAction,
  UpdateUserDetailsAction,
  UpdateUserDetailsFailureAction,
  UpdateUserDetailsPayload,
  UpdateUserDetailsSuccessAction,
  UserActions,
  UserDetails,
  VerifyOtpAction,
  VerifyOtpFailureAction,
  VerifyOtpPayload,
  VerifyOtpSuccessAction,
} from "./userTypes";

export const fetchUser = (): FetchUserAction => ({
  type: UserActions.FETCH_USER,
});

export const fetchUserSuccess = (user: User): FetchUserSuccessAction => ({
  type: UserActions.FETCH_USER_SUCCESS,
  payload: user,
});

export const fetchUserFailure = (error: string): FetchUserFailureAction => ({
  type: UserActions.FETCH_USER_FAILURE,
  error,
});

export const setUser = (user: User | null): SetUserAction => ({
  type: UserActions.SET_USER,
  payload: user,
});

export const logOutUser = (): LogOutUserAction => ({
  type: UserActions.LOGOUT_USER,
});

export const verifyOtp = (payload: VerifyOtpPayload): VerifyOtpAction => ({
  type: UserActions.VERIFY_OTP,
  payload,
});

export const verifyOtpSuccess = (token: string): VerifyOtpSuccessAction => ({
  type: UserActions.VERIFY_OTP_SUCCESS,
  payload: token,
});

export const verifyOtpFailure = (error: string): VerifyOtpFailureAction => ({
  type: UserActions.VERIFY_OTP_FAILURE,
  error,
});

export const getUserDetails = (): GetUserDetailsAction => ({
  type: UserActions.GET_USER_DETAILS,
});

export const getUserDetailsSuccess = (
  user: UserDetails,
): GetUserDetailsSuccessAction => ({
  type: UserActions.GET_USER_DETAILS_SUCCESS,
  payload: user,
});

export const getUserDetailsFailure = (
  error: string,
): GetUserDetailsFailureAction => ({
  type: UserActions.GET_USER_DETAILS_FAILURE,
  error,
});

export const updateUserDetails = (
  user: UpdateUserDetailsPayload,
): UpdateUserDetailsAction => ({
  type: UserActions.UPDATE_USER_DETAILS,
  payload: user,
});

export const updateUserDetailsSuccess = (
  user: UpdateUserDetailsPayload,
): UpdateUserDetailsSuccessAction => ({
  type: UserActions.UPDATE_USER_DETAILS_SUCCESS,
  payload: user,
});

export const updateUserDetailsFailure = (
  error: string,
): UpdateUserDetailsFailureAction => ({
  type: UserActions.UPDATE_USER_DETAILS_FAILURE,
  error,
});
