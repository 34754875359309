import React, { useEffect, useState } from "react";
import "./ShotAnalytics.scss";
import { Chart, ScriptableContext } from "chart.js/auto";
import { TeamAnalyticsStats } from "../../models/Analytics";
import NoAnalyticsData from "../NoAnalyticsData/NoAnalyticsData";

interface ShotAnalyticsProps {
  noData: boolean;
  teamAStats: TeamAnalyticsStats | undefined;
  teamBStats: TeamAnalyticsStats | undefined;
  teamInitials: { A: string; B: string };
}

const ShotAnalytics: React.FC<ShotAnalyticsProps> = ({
  teamAStats,
  teamBStats,
  noData,
  teamInitials,
}) => {
  const [mini, setMini] = useState(true);
  const [positions, setPositions] = useState<{ x: number; y: number }[]>([
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ]);
  const [stats, setStats] = useState<{
    forehand: { A: number; B: number };
    backhand: { A: number; B: number };
    smash: { A: number; B: number };
    serve: { A: number; B: number };
    speed: { A: number; B: number };
  }>({
    forehand: {
      A: teamAStats?.forehand || 0,
      B: teamBStats?.forehand || 0,
    },
    smash: {
      A: teamAStats?.smash || 0,
      B: teamBStats?.smash || 0,
    },
    serve: {
      A: teamAStats?.serve || 0,
      B: teamBStats?.serve || 0,
    },
    backhand: {
      A: teamAStats?.backhand || 0,
      B: teamBStats?.backhand || 0,
    },
    speed: {
      A: Math.round(teamAStats?.average_shot_speed || 0),
      B: Math.round(teamBStats?.average_shot_speed || 0),
    },
  });

  useEffect(() => {
    setStats({
      forehand: {
        A: teamAStats?.forehand || 0,
        B: teamBStats?.forehand || 0,
      },
      smash: {
        A: teamAStats?.smash || 0,
        B: teamBStats?.smash || 0,
      },
      serve: {
        A: teamAStats?.serve || 0,
        B: teamBStats?.serve || 0,
      },
      backhand: {
        A: teamAStats?.backhand || 0,
        B: teamBStats?.backhand || 0,
      },
      speed: {
        A: Math.round(teamAStats?.average_shot_speed || 0),
        B: Math.round(teamBStats?.average_shot_speed || 0),
      },
    });
  }, [teamAStats, teamBStats]);

  const teams = {
    A: [
      {
        name: "Rajesh",
        avatar:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618071655-4.webp",
      },
      {
        name: "Mukesh",
        avatar:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618071113-2.webp",
      },
    ],
    B: [
      {
        name: "Suresh",
        avatar:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618071656-14.webp",
      },
      {
        name: "Prakash",
        avatar:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618071656-13.webp",
      },
    ],
  };

  useEffect(() => {
    shotSpeedGraph();
  }, [mini]);

  const shotSpeedGraph = async () => {
    await clearChart();
    const data = [
      { team: "A", count: stats.speed.A },
      { team: "B", count: stats.speed.B },
    ];

    const canvas = document.getElementById("shot-speed-canvas");
    if (canvas) {
      const ctx = new Chart(canvas as HTMLCanvasElement, {
        type: "bar",
        data: {
          labels: data.map((row) => row.team),
          datasets: [
            {
              label: "Speed",
              data: data.map((row) => row.count),
              backgroundColor: (ctx: ScriptableContext<"bar">) => {
                const meta = ctx.chart.getDatasetMeta(0);
                meta.data.forEach((bar, index) => {
                  if (bar.x && bar.y) {
                    setPositions((val) => {
                      const result = [...val];
                      result[index] = { x: bar.x, y: bar.y };
                      return result;
                    });
                  }
                });
                return ctx.dataIndex === 0 ? "#6FA8A8" : "#73C58A";
              },
              borderRadius: 10,
              barThickness: 50,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
              grid: {
                display: true,
                drawOnChartArea: false,
                tickLength: 4,
              },
              border: {
                display: false,
              },
              ticks: {
                color: "#AFAFAF",
                font: {
                  size: 10,
                  weight: 500,
                  family: "Poppins",
                },
                maxTicksLimit: 10,
              },
            },
            x: {
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          },
          animation: {
            duration: 0,
          },
        },
      });
    }
  };

  const clearChart = async () => {
    const chartStatus = Chart.getChart("shot-speed-canvas"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
  };

  return (
    <div className="shot-analytics-content flex-column gap-18">
      <div
        className="top-row flex-row align-items-center gap-16"
        onClick={() => setMini((val) => !val)}
      >
        <div className="logo-container align-items-center flex-row justify-content-center">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626194322-playtime.svg"
            alt="shot"
            width={30}
            height={30}
          />
        </div>
        <div className="flex-column gap-4 flex-1">
          <p className="title font-large bold" style={{ color: "#222222" }}>
            Shot Analytics
          </p>
          <p className="title font-mid " style={{ color: "#AFAFAF" }}>
            {noData ? "No Data" : "Shot type and speed analysis"}
          </p>
        </div>
        <div
          className="flex-row align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240622171408-top-black-row.svg"
            alt="top-row"
            width={12}
            className="px-8"
            style={{ transform: `${mini ? "rotate(180deg)" : "rotate(0deg)"}` }}
          />
        </div>
      </div>
      {!mini &&
        (noData ? (
          <NoAnalyticsData />
        ) : (
          <>
            <div className="separator"></div>
            {/* <div className="shot-type-container">
              <p className="font-large bold" style={{ color: "#222222" }}>
                Shot Type Analysis
              </p>
              <div className="team-name-container flex-row justify-content-center">
                <p className="team-name winner">{teamInitials.A}</p>
                <p className="team-name">{teamInitials.B}</p>
              </div>
              <div className="shot-graph-container flex-column gap-24">
                <div className="shot-type flex-column gap-10">
                  <div className="flex-row justify-content-space-between full-width">
                    <p className="font-xsmall" style={{ color: "#AFAFAF" }}>
                      {stats.forehand.A + stats.forehand.B}
                    </p>
                    <p
                      className="font-xsmall bold "
                      style={{ color: "#AFAFAF" }}
                    >
                      Forehand
                    </p>
                    <p className="font-xsmall" style={{ color: "#AFAFAF" }}>
                      {stats.forehand.A + stats.forehand.B}
                    </p>
                  </div>
                  <div className="indicator-container">
                    <div
                      className={`team-indicator ${
                        stats.forehand.A > stats.forehand.B &&
                        "higher-indicator"
                      }`}
                      style={{
                        left: `${
                          (stats.forehand.B * 100) /
                          (stats.forehand.A + stats.forehand.B) /
                          2
                        }%`,
                        width: `${
                          (stats.forehand.A * 100) /
                          (stats.forehand.A + stats.forehand.B) /
                          2
                        }%`,
                      }}
                    ></div>
                    <div
                      className={`team-indicator ${
                        stats.forehand.B > stats.forehand.A &&
                        "higher-indicator"
                      }`}
                      style={{
                        left: "50%",
                        width: `${
                          (stats.forehand.B * 100) /
                          (stats.forehand.A + stats.forehand.B) /
                          2
                        }%`,
                      }}
                    ></div>
                    <p
                      className={`team-pointer font-xsmall ${
                        stats.forehand.A > stats.forehand.B && "winner-pointer"
                      }`}
                      style={{
                        left: `calc(${
                          (stats.forehand.B * 100) /
                          (stats.forehand.A + stats.forehand.B) /
                          2
                        }% - 9px`,
                      }}
                    >
                      {stats.forehand.A}
                    </p>
                    <p
                      className={`team-pointer font-xsmall ${
                        stats.forehand.B > stats.forehand.A && "winner-pointer"
                      }`}
                      style={{
                        left: `calc(${
                          50 +
                          (stats.forehand.B * 100) /
                            (stats.forehand.A + stats.forehand.B) /
                            2
                        }% - 9px`,
                      }}
                    >
                      {stats.forehand.B}
                    </p>
                  </div>
                </div>
                <div className="shot-type flex-column gap-10">
                  <div className="flex-row justify-content-space-between full-width">
                    <p className="font-xsmall" style={{ color: "#AFAFAF" }}>
                      {stats.backhand.A + stats.backhand.B}
                    </p>
                    <p
                      className="font-xsmall bold "
                      style={{ color: "#AFAFAF" }}
                    >
                      Backhand
                    </p>
                    <p className="font-xsmall" style={{ color: "#AFAFAF" }}>
                      {stats.backhand.A + stats.backhand.B}
                    </p>
                  </div>
                  <div className="indicator-container">
                    <div
                      className={`team-indicator ${
                        stats.backhand.A > stats.backhand.B &&
                        "higher-indicator"
                      }`}
                      style={{
                        left: `${
                          (stats.backhand.B * 100) /
                          (stats.backhand.A + stats.backhand.B) /
                          2
                        }%`,
                        width: `${
                          (stats.backhand.A * 100) /
                          (stats.backhand.A + stats.backhand.B) /
                          2
                        }%`,
                      }}
                    ></div>
                    <div
                      className={`team-indicator ${
                        stats.backhand.B > stats.backhand.A &&
                        "higher-indicator"
                      }`}
                      style={{
                        left: "50%",
                        width: `${
                          (stats.backhand.B * 100) /
                          (stats.backhand.A + stats.backhand.B) /
                          2
                        }%`,
                      }}
                    ></div>
                    <p
                      className={`team-pointer font-xsmall ${
                        stats.backhand.A > stats.backhand.B && "winner-pointer"
                      }`}
                      style={{
                        left: `calc(${
                          (stats.backhand.B * 100) /
                          (stats.backhand.A + stats.backhand.B) /
                          2
                        }% - 9px`,
                      }}
                    >
                      {stats.backhand.A}
                    </p>
                    <p
                      className={`team-pointer font-xsmall ${
                        stats.backhand.B > stats.backhand.A && "winner-pointer"
                      }`}
                      style={{
                        left: `calc(${
                          50 +
                          (stats.backhand.B * 100) /
                            (stats.backhand.A + stats.backhand.B) /
                            2
                        }% - 9px`,
                      }}
                    >
                      {stats.backhand.B}
                    </p>
                  </div>
                </div>
                <div className="shot-type flex-column gap-10">
                  <div className="flex-row justify-content-space-between full-width">
                    <p className="font-xsmall" style={{ color: "#AFAFAF" }}>
                      {stats.smash.A + stats.smash.B}
                    </p>
                    <p
                      className="font-xsmall bold "
                      style={{ color: "#AFAFAF" }}
                    >
                      Smash
                    </p>
                    <p className="font-xsmall" style={{ color: "#AFAFAF" }}>
                      {stats.smash.A + stats.smash.B}
                    </p>
                  </div>
                  <div className="indicator-container">
                    <div
                      className={`team-indicator ${
                        stats.smash.A > stats.smash.B && "higher-indicator"
                      }`}
                      style={{
                        left: `${
                          (stats.smash.B * 100) /
                          (stats.smash.A + stats.smash.B) /
                          2
                        }%`,
                        width: `${
                          (stats.smash.A * 100) /
                          (stats.smash.A + stats.smash.B) /
                          2
                        }%`,
                      }}
                    ></div>
                    <div
                      className={`team-indicator ${
                        stats.smash.B > stats.smash.A && "higher-indicator"
                      }`}
                      style={{
                        left: "50%",
                        width: `${
                          (stats.smash.B * 100) /
                          (stats.smash.A + stats.smash.B) /
                          2
                        }%`,
                      }}
                    ></div>
                    <p
                      className={`team-pointer font-xsmall ${
                        stats.smash.A > stats.smash.B && "winner-pointer"
                      }`}
                      style={{
                        left: `calc(${
                          (stats.smash.B * 100) /
                          (stats.smash.A + stats.smash.B) /
                          2
                        }% - 9px`,
                      }}
                    >
                      {stats.smash.A}
                    </p>
                    <p
                      className={`team-pointer font-xsmall ${
                        stats.smash.B > stats.smash.A && "winner-pointer"
                      }`}
                      style={{
                        left: `calc(${
                          50 +
                          (stats.smash.B * 100) /
                            (stats.smash.A + stats.smash.B) /
                            2
                        }% - 9px`,
                      }}
                    >
                      {stats.smash.B}
                    </p>
                  </div>
                </div>
                <div className="shot-type flex-column gap-10">
                  <div className="flex-row justify-content-space-between full-width">
                    <p className="font-xsmall" style={{ color: "#AFAFAF" }}>
                      {stats.serve.A + stats.serve.B}
                    </p>
                    <p
                      className="font-xsmall bold "
                      style={{ color: "#AFAFAF" }}
                    >
                      Serve
                    </p>
                    <p className="font-xsmall" style={{ color: "#AFAFAF" }}>
                      {stats.serve.A + stats.serve.B}
                    </p>
                  </div>
                  <div className="indicator-container">
                    <div
                      className={`team-indicator ${
                        stats.serve.A > stats.serve.B && "higher-indicator"
                      }`}
                      style={{
                        left: `${
                          (stats.serve.B * 100) /
                          (stats.serve.A + stats.serve.B) /
                          2
                        }%`,
                        width: `${
                          (stats.serve.A * 100) /
                          (stats.serve.A + stats.serve.B) /
                          2
                        }%`,
                      }}
                    ></div>
                    <div
                      className={`team-indicator ${
                        stats.serve.B > stats.serve.A && "higher-indicator"
                      }`}
                      style={{
                        left: "50%",
                        width: `${
                          (stats.serve.B * 100) /
                          (stats.serve.A + stats.serve.B) /
                          2
                        }%`,
                      }}
                    ></div>
                    <p
                      className={`team-pointer font-xsmall ${
                        stats.serve.A > stats.serve.B && "winner-pointer"
                      }`}
                      style={{
                        left: `calc(${
                          (stats.serve.B * 100) /
                          (stats.serve.A + stats.serve.B) /
                          2
                        }% - 9px`,
                      }}
                    >
                      {stats.serve.A}
                    </p>
                    <p
                      className={`team-pointer font-xsmall ${
                        stats.serve.B > stats.serve.A && "winner-pointer"
                      }`}
                      style={{
                        left: `calc(${
                          50 +
                          (stats.serve.B * 100) /
                            (stats.serve.A + stats.serve.B) /
                            2
                        }% - 9px`,
                      }}
                    >
                      {stats.serve.B}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="shot-speed-container">
              <p className="font-large bold" style={{ color: "#222222" }}>
                Average Shot speed
              </p>
              <div className="shot-speed-canvas-container">
                {/* <canvas id="shot-speed-canvas"></canvas> */}
              </div>
              <div
                className="full-width position-relative flex-row justify-content-center"
                style={{ gap: "75px", marginTop: "28px" }}
              >
                <div
                  className="px-12 py-5 flex-row gap-4 align-items-center border-box"
                  style={{
                    background: "#98D1D1",
                    borderRadius: "8px",
                    width: "96px",
                  }}
                >
                  <div className="flex-row">
                    <img
                      src={teams.A[0].avatar}
                      alt="team-a-1"
                      width={14}
                      height={14}
                      className="border-round"
                    />
                    <img
                      src={teams.A[1].avatar}
                      alt="team-a-2"
                      width={14}
                      height={14}
                      className="border-round"
                    />
                  </div>
                  <p className="font-xsmall bold" style={{ color: "#FFFFFF" }}>
                    Team A
                  </p>
                </div>
                <div
                  className="px-12 py-5 flex-row gap-4 align-items-center border-box"
                  style={{
                    background: "#90D5A3",
                    borderRadius: "8px",
                    width: "96px",
                  }}
                >
                  <div className="flex-row">
                    <img
                      src={teams.B[0].avatar}
                      alt="team-a-1"
                      width={14}
                      height={14}
                      className="border-round"
                    />
                    <img
                      src={teams.B[1].avatar}
                      alt="team-a-2"
                      width={14}
                      height={14}
                      className="border-round"
                    />
                  </div>
                  <p className="font-xsmall bold" style={{ color: "#FFFFFF" }}>
                    Team B
                  </p>
                </div>
              </div>
              <div
                className="flex-row justify-content-center"
                style={{ gap: "50px", marginTop: "18px" }}
              >
                <p
                  className="font-xlarge bolder"
                  style={{
                    color: `${
                      stats.speed.A > stats.speed.B ? "#79EC63" : "#AFAFAF"
                    }`,
                  }}
                >
                  {stats.speed.A}m/s
                </p>
                {stats.speed.A !== stats.speed.B ? (
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240705112405-Vector+%284%29.svg"
                    alt="greater"
                    style={{
                      transform: `rotate(${
                        stats.speed.A > stats.speed.B ? 180 : 0
                      }deg)`,
                    }}
                  />
                ) : (
                  <div style={{ width: "16px" }}></div>
                )}
                <p
                  className="font-xlarge bolder"
                  style={{
                    color: `${
                      stats.speed.B > stats.speed.A ? "#79EC63" : "#AFAFAF"
                    }`,
                  }}
                >
                  {stats.speed.B}m/s
                </p>
              </div>
              <div
                className="p-10 flex-column gap-16"
                style={{
                  background: "#F6F6F6",
                  borderRadius: "8px",
                  marginTop: "26px",
                }}
              >
                <div className="flex-column gap-4">
                  <p className="font-xsmall bold" style={{ color: "#000000" }}>
                    Professional Stats
                  </p>
                  <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                    See where you stand in the professional world
                  </p>
                </div>
                <div className="flex-row justify-content-space-between">
                  <div className="flex-column gap-8 align-items-center">
                    <p
                      className="font-xlarge bolder"
                      style={{ color: "#419898" }}
                    >
                      35-50
                    </p>
                    <p
                      className="font-xsmall bold"
                      style={{ color: "#419898" }}
                    >
                      Pro
                    </p>
                  </div>
                  <div className="flex-row justify-content-space-between">
                    <div className="flex-column gap-8 align-items-center">
                      <p
                        className="font-xlarge bolder"
                        style={{ color: "#70C0C0" }}
                      >
                        20-35
                      </p>
                      <p
                        className="font-xsmall bold"
                        style={{ color: "#70C0C0" }}
                      >
                        Intermediate
                      </p>
                    </div>
                  </div>
                  <div className="flex-row justify-content-space-between">
                    <div className="flex-column gap-8 align-items-center">
                      <p
                        className="font-xlarge bolder"
                        style={{ color: "#A0D8D8" }}
                      >
                        5-20
                      </p>
                      <p
                        className="font-xsmall bold"
                        style={{ color: "#A0D8D8" }}
                      >
                        Amateur
                      </p>
                    </div>
                  </div>
                </div>
                <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                  All units are in m/s
                </p>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default ShotAnalytics;
