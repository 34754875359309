import React, { useEffect, useState } from "react";
import "./PrivacyPolicy.scss";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";

const PrivacyPolicy: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="terms-container flex-column">
      <div className="terms-header">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240710071743-stupalogo.webp"
          alt="logo"
          width={54}
          className="logo"
        />
        <p className="font-xlarge bold terms-title">Privacy Policy</p>
      </div>
      <div className="terms-content p-20 flex-1 font-small flex-column gap-10">
        <ol className="main-point-list">
          <li>
            <p className="bold">Introduction</p>
            <ol className="sub-point-list">
              <li>
                Welcome to Hsquare Sports Private Limited (“Us” or “Our” or “We”
                or “Stupa Sports Analytics”) website-{" "}
                <a
                  href="https://stupaanalytics.com/"
                  target="_blank"
                  className="bold"
                  rel="noopener noreferrer"
                  style={{ color: "#62ABDF", textDecoration: "underline" }}
                >
                  https://stupa.ai
                </a>{" "}
                (“Website”), including any website which is owned and developed
                by Us for Our specific partner to give such partner an exclusive
                platform for its listing of its events/sport sessions etc.
                (“Brand Page”) and mobile application (“Mobile Application”)
                (hereinafter collectively referred to as “Platform”). We operate
                under the brand name Stupa Sports Analytics and are engaged in
                organising sports and other recreational events as well as
                providing an internet-based booking platform for and in
                connection with Our as well as third party sports and other
                recreational events and sports facilities and venues through Our
                Platform either by Our self or through Our subsidiaries,
                affiliates or third-party service providers (“Primary
                Services&quot;). On our mobile application, we may also provide
                ways for you to communicate with other Stupa Sports Analytics
                users including through messages, [voice and video calls,
                sending images and video, showing your status, sharing your
                location with others when you choose and hosting games]
                (“Secondary Services”). For the purposes of this policy, Primary
                Services and Secondary Services shall collectively referred to
                as Services.
              </li>
              <li>
                We care about the privacy of the users of Our Platform (“Users”)
                such as yourself. We respect the information that you provide to
                Us, and want to be sure you fully understand exactly how We use
                that information. By accepting the Terms of Service provided{" "}
                <span
                  className="bold"
                  style={{ color: "#62ABDF", textDecoration: "underline" }}
                  onClick={() => navigate(PATHS.TERMS_AND_CONDITIONS)}
                >
                  here
                </span>{" "}
                (“Terms of Service”), you are also agreeing to this privacy
                policy (“Privacy Policy”).
              </li>
              <li>
                You understand that by using the Services via any Platform you
                consent to the collection, use and disclosure of your personal
                information and aggregate data as set forth in Our Privacy
                Policy, and to have your personal information collected, used,
                transferred to and processed in accordance with this policy. If
                you do not wish to agree with terms of Our Privacy Policy, We
                kindly request you not to use Our Platform.
              </li>
              <li>
                This Privacy Policy does not apply to the practices of companies
                or entities that We do not own or control, or to individuals
                that We do not employ or manage.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">Security</p>
            <ol className="sub-point-list">
              <li>
                Stupa Sports Analytics uses commercially reasonable physical,
                managerial, and technical safeguards to preserve the integrity
                and security of your personal information and implement your
                privacy settings. However, We cannot guarantee that unauthorized
                third parties will never be able to defeat Our security measures
                or use your personal information for improper purposes. In the
                event where you believe your privacy has been breached, please
                contact Us immediately.
              </li>
              <li>
                We want to reduce loss, theft, misuse, unauthorized access,
                disclosure, alteration, destruction, harassment, and abuse
                related to Our Platform, but We cannot guarantee complete
                perfection. When there is clear evidence of any of the above, We
                will do Our best to respond promptly. You acknowledge that you
                provide your personal information at your own risk.
              </li>
              <li>
                We may transfer the information described in this Privacy Policy
                to, and process and store it in, India and other countries, some
                of which may have less protective data protection laws than the
                region in which you reside. Where this is the case, We will take
                appropriate measures to protect your personal information in
                accordance with this Privacy Policy. We will take steps to
                reasonably ensure that the receiving jurisdiction has in place a
                standard of protection accorded to personal data that is
                comparable to the protection under India’s data protection laws.
                We may sub-contract processing activities to third parties
                include—record keeping, data analysis, marketing assistance,
                processing payments, and providing customer service.
              </li>
              <li>
                You undertake to treat your password and other confidential
                information in relation to the use of Our Platforms and its
                Services confidentially, and We disclaim any liability arising
                from your failure to do so.
              </li>
              <li>
                The collection, storage, usage, and sharing of your data by the
                Company shall be in compliance with the Information Technology
                (Reasonable Security Practices and Procedures and Sensitive
                Personal Data or Information) Rules, 2011 and other applicable
                laws.
              </li>
              <li>
                We have taken appropriate steps for the security and protection
                of all Our digital platforms including internal applications,
                however, We shall not be responsible for any breach of security
                or the disclosure of personal data for reasons outside Our
                control, such as hacking, social engineering, cyber terrorism,
                espionage by third parties etc.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">Platform Permission</p>
            <p>
              Most mobile platforms such as iOS, Android, etc. (“Mobile
              Platform”) have defined certain types of device data that apps
              cannot access without your consent. And these Mobile Platforms
              have different permission systems for obtaining your consent. For
              example, the iOS platform will alert you the first time the Mobile
              Application wants permission to access certain types of data and
              will let you consent (or not consent) to that request. Android
              devices will notify you of the permissions that the Mobile
              Application seeks before you first install or use the app, and
              your use of the app constitutes your consent.
            </p>
          </li>
          <li>
            <p className="bold">Collection of Information</p>
            <ol className="sub-point-list">
              <li>
                Stupa Sports Analytics collects information about you when you
                use Our Services and through other interactions and
                communications you have with Us.
              </li>
              <li>
                We collect information you provide directly to Us, such as when
                you create or modify your account, request on-demand services,
                contact customer support, use of any related services connected
                to Stupa Sports Analytics or participate in contests and
                surveys, or otherwise participate in activities We promote that
                might require information about you or complete contact forms or
                request newsletters or other information from Us (for instance
                via email) or otherwise communicate with Us. This information
                may include name, email, phone number, postal address, profile
                picture, items and quantities requested, delivery notes, and
                other information you choose to provide.
              </li>
              <li>
                When you use Our Primary Services, We collect information about
                you in the following general categories:
                <ol className="sub-point-list">
                  <li>
                    <p>
                      Personal information
                      <ol className="sub-point-list">
                        <li>
                          We collect information about you (such as email
                          address, name, username or other relevant unique
                          identifier, mobile phone number, location, date of
                          birth, gender identity etc.) from you when you set up
                          a free account with Us.
                        </li>
                        <li>
                          When you complete your profile, you can share with Us
                          additional information, such as details on your
                          favourite sports, interests in particular venues and
                          events, corporate profile details and other details
                          about you, as well as content such as photos. To add
                          certain content, like pictures, you may allow Us to
                          access your camera or photo album. By choosing to
                          provide this information, you consent to Our
                          processing of that information.
                        </li>
                        <li>
                          You may be able to use your social media login (such
                          as Facebook login) or Google login to create and log
                          into your Stupa Sports Analytics account that allows
                          you to share some information from your social media
                          account or google account with Us.
                        </li>
                        <li>
                          While you may be able to browse some sections of Our
                          Platform without being a registered member, certain
                          activities (such as placing an order) do require
                          registration. We may use your contact information to
                          send you offers based on your previous orders and your
                          interests.
                        </li>
                        <li>
                          In accordance with Our Terms of Service, Your
                          photograph may be clicked (either as single photo or
                          in group with other users) or audio-videos may be
                          made/created during a class/session in fitness
                          centres/venues or at an event or during usage of any
                          third party websites or applications while taking
                          fitness session or attending any event using or
                          through Our Primary Services (“Photos and Videos”).
                          Such Photos and Videos may be clicked/made/captured by
                          the venue/centre managers, or any individual
                          authorised by Stupa Sports Analytics’s personnel or
                          venue managers.
                        </li>
                      </ol>
                    </p>
                  </li>
                  <li>
                    <p>Financial information:</p>
                    <p>
                      If you transact with Us directly or through Our partners
                      (for instance, you pay the booking amount to Our venue
                      partner directly while making booking through Our
                      Platform) or through entities engaged by Us providing
                      financial services, We collect some additional
                      information, such as a billing address, credit / debit
                      card number and a credit / debit card expiration date and/
                      or other payment instrument details and tracking
                      information.
                    </p>
                  </li>
                  <li>
                    <p>Location information:</p>
                    <p>
                      When you use the Services, We collect precise location
                      data about the location of the device from the Platform
                      used by the User. If you permit any of Our Platform to
                      access location services through the permission system
                      used by your mobile/web platform, We may also collect the
                      precise location of your device when the Mobile
                      Application or the Platform is running in the foreground
                      or background. We may also derive your approximate
                      location from your IP address.
                    </p>
                  </li>
                  <li>
                    <p>Transaction information:</p>
                    <p>
                      We collect transaction details related to your use of Our
                      Primary Services, including the type of service requested,
                      date and time the service was provided, amount charged,
                      product supplied, and other related transaction details.
                      Additionally, if someone uses your promo code, We may
                      associate your name with that person.
                    </p>
                  </li>
                  <li>
                    <p>Device information:</p>
                    <p>
                      We may collect information about your mobile device,
                      including, for example, the hardware model, operating
                      system and version, software and file names and versions,
                      preferred language, unique device identifier, advertising
                      identifiers, serial number, device motion information, and
                      mobile network information.
                    </p>
                  </li>
                  <li>
                    <p>Call and SMS Data:</p>
                    <p>
                      Our Services may facilitate communications between Users
                      and Our designated support staff (“Support Staff”). In
                      connection with facilitating this service, We receive call
                      data, including the date and time of the call or SMS
                      message, the parties phone numbers, and the content of the
                      SMS message.
                    </p>
                  </li>
                  <li>
                    <p>Log information:</p>
                    <p>
                      When you interact with the Services, We collect server
                      logs, which may include information like device IP
                      address, access dates and times, Mobile Application
                      features or Website (including Brand Page) pages viewed,
                      Mobile Application crashes and other system activity, type
                      of browser and the third-party site or service you were
                      using before interacting with Our Services.
                    </p>
                  </li>
                  <li>
                    <p>
                      Information collected by cookies and other tracking
                      technologies:
                    </p>
                    <p>
                      We use various technologies to collect information, and
                      this may include sending cookies to your computer/device.
                      Cookies are small data files stored on your hard drive or
                      in your device memory that helps Us to improve Our
                      Services and your experience, see which areas and features
                      of Our Services are popular and count visits. Most web
                      browsers are set to accept cookies by default. If you
                      prefer, you can usually choose to set your browser to
                      remove or reject browser cookies. Please note that if you
                      choose to remove or reject cookies, this could affect the
                      availability and functionality of Our Services. Further,
                      We may also collect information using web beacons (also
                      known as tracking pixels). Web beacons are electronic
                      images that may be used in Our Services or emails and to
                      track visits or understand usage and campaign
                      effectiveness.
                    </p>
                  </li>
                  <li>
                    <p>Other information:</p>
                    <ol className="sub-point-list">
                      <li>
                        When you participate in surveys or focus groups, you
                        give Us your insights into Our products and services,
                        responses to Our questions and testimonials.
                      </li>
                      <li>
                        When you choose to participate in Our promotions, events
                        or contests, We collect the information that you use to
                        register or enter.
                      </li>
                      <li>
                        If you contact Our customer care team or Support Staff,
                        We collect the information you give Us during the
                        interaction. Sometimes, We monitor or record these
                        interactions for training purposes and to ensure a high
                        quality of service.
                      </li>
                      <li>
                        If you ask Us to communicate with or otherwise process
                        information of other people (for example, if you ask Us
                        to send an email or payment link (in case of split
                        payment option) or any other information/material on
                        your behalf to one of your friends or any other user),
                        We collect the information about others that you give Us
                        in order to complete your request.
                      </li>
                      <li>
                        We also process your content you publish, as part of the
                        operation of the services.
                      </li>
                      <li>
                        Participate in communities or forums or blogs or news
                        feed on the Platform.
                      </li>
                      <li>
                        Knowingly volunteer information on any other part of the
                        Services (e.g., request for customer service, submit a
                        job application).
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>Information We receive from others:</p>
                    <p>
                      In addition to the information you provide Us directly, We
                      receive information about you from others, including:
                    </p>
                    <ol className="sub-point-list">
                      <li>
                        Other users: Other users may provide information about
                        you as they use Our services. For instance, We may
                        collect information about you from other users if they
                        contact Us about you or ask Us to send a payment link to
                        complete the payment transaction in relation to any
                        booking made on Our Platforms.
                      </li>
                      <li>
                        Social media: You may be able to use your social media
                        login (such as Facebook login) or Google login to create
                        and log into your Stupa Sports Analytics account on the
                        Platform. This saves you from having to remember yet
                        another user name and password and allows you to share
                        some information from your social media account with Us.
                      </li>
                      <li>
                        Other partners: We may receive information about you
                        from Our partners, for instance where Stupa Sports
                        Analytics ads are published on a partner’s websites and
                        platforms (in which case they may pass along details for
                        instance information in connection with a campaign’s
                        success).
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      When you use Our Secondary Services, We do not retain your
                      messages in the ordinary course of providing our Secondary
                      Services to you. Instead, your messages are stored on your
                      device and not typically stored on our servers. Once your
                      messages are delivered, they are deleted from our servers.
                      The following scenarios describe circumstances where we
                      may store your messages in the course of delivering them:
                    </p>
                    <ol className="sub-point-list">
                      <li>
                        Undelivered Messages. If a message cannot be delivered
                        immediately (for example, if the recipient is offline),
                        we keep it in encrypted form on our servers for up to 30
                        days as we try to deliver it. If a message is still
                        undelivered after 30 days, we delete it.
                      </li>
                      <li>
                        Media Forwarding. When a user forwards media within a
                        message, we store that media temporarily in encrypted
                        form on our servers to aid in more efficient delivery of
                        additional forwards.
                      </li>
                      <li>
                        We offer end-to-end encryption for our Secondary
                        Services. End-to-end encryption means that your messages
                        are encrypted to protect against us and third parties
                        from reading them.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <p className="bold"> Use of Information</p>
            <p>
              We may use the information We collect about you from Our Primary
              Services to:
            </p>
            <ol className="sub-point-list">
              <li>
                Provide, maintain, and improve Our Services, including, for
                example, to facilitate payments, send receipts, provide products
                and services you request (and send related information), develop
                new features, develop safety features, authenticate users, and
                send product updates, security alerts and administrative
                messages;
              </li>
              <li>
                Perform internal operations, including, for example, to prevent
                fraud and abuse of Our Services; to troubleshoot software bugs
                and operational problems; to conduct data analysis, testing, and
                research; and to monitor and analyse usage and activity trends;
              </li>
              <li>
                Send or facilitate communications between you and a Support
                Staff;
              </li>
              <li>
                Send you communications We think will be of interest to you,
                including information about products, services, promotions,
                news, and events of Stupa Sports Analytics and other companies;
                and to process contest, sweepstake, or other promotion entries
                and fulfil any related awards;
              </li>
              <li>
                Personalize and improve the Services, including to provide or
                recommend features, content, social connections, referrals, and
                advertisements;
              </li>
              <li>
                Respond to your comments, questions and requests and provide
                customer service to Users;
              </li>
              <li>
                Publicly display the Photos and Videos on the Platform or on
                public forum including on social media platform (such as
                Facebook and Instagram) at its sole discretion. Unless stated
                otherwise, all Photos and Videos are believed to be in the
                public domain as either promotional materials or publicity
                photos and videos as Stupa Sports Analytics may determine at its
                sole discretion.
              </li>
              <li>
                Enforce applicable user agreements or policies, including the
                Terms of Service (link) and to protect Us, Our users or the
                public from harm or illegal activities;
              </li>
              <li>
                We reserves the right, but has no obligation, to monitor the
                User content you post while using Our Primary Services on any of
                Our Platforms. We have the right to remove any such information
                or material for any reason or no reason, including without
                limitation, if in Our sole opinion, such information or material
                violates, or may violate, any applicable law or Our business
                policies and practices or to protect or defend Our rights or
                property or those of any third party. We also reserves the right
                to remove information upon the request of any third party.
              </li>
              <li>
                For the avoidance of any doubt, We should clarify that in the
                event We anonymize and aggregate information collected from you,
                We will be entitled to use such anonymized data freely, without
                any restrictions other than those set out under applicable law.
                We do this for purposes such as analyzing how the Service is
                used, diagnosing service or technical problems, maintaining
                security, and personalizing advertisements and promotions.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">Third-Party Links and Information</p>
            <ol className="sub-point-list">
              <li>
                <p>
                  The Primary Services may contain links to third-party
                  materials that are not owned or controlled by Stupa Sports
                  Analytics and are simply provided for convenience. Stupa
                  Sports Analytics does not endorse or assume any responsibility
                  for any such third-party sites, information, videos (including
                  videos on youtube.com), materials, products, or services. If
                  you access a third-party website or service from the Service
                  (for instance attend any fitness or sports session hosted on
                  Our Platform but accessible online via third party application
                  or websites) or share content on or through any third-party
                  website or service, you do so at your own risk, add you
                  understand that this Privacy Policy does not apply to your use
                  of such sites.
                </p>
                <p>
                  You expressly relieve Stupa Sports Analytics from any and all
                  liability arising from your use of any third-party website,
                  service, or content, including without limitation content
                  submitted by other Users. Additionally, your dealings with or
                  participation in promotions of advertisers found on the
                  Service, including payment and delivery of goods, and any
                  other terms (such as warranties) are solely between you and
                  such advertisers.
                </p>
                <p>
                  You agree that Stupa Sports Analytics shall not be responsible
                  for any loss or damage of any sort relating to your dealings
                  with such advertisers. We may receive data whenever you visit
                  a third-party link through Our Platforms which includes the
                  date and time of your visit to the third-party website, the
                  web address or URL, technical information about the IP
                  address, browser and the operating system you use and, if you
                  are logged into Stupa Sports Analytics Platforms, your
                  username.
                </p>
              </li>
              <li>
                We may also receive information from other sources and combine
                that with information We collect through Our Primary Services.
                For example: If you choose to link, create, or log in to your
                Stupa Sports Analytics account with a payment provider (e.g.,
                Paytm) or social media service (e.g., Facebook), or if you
                engage with a separate app or website that uses Our API (or
                whose API We use), We may receive information about you or your
                connections from that site or app.
              </li>
              <li>
                If your employer uses one of Our enterprise solutions, We may
                receive information about you from your employer.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">Sharing of Information</p>
            <ol className="sub-point-list">
              <li>
                <p>
                  We may share the information We collect about you through Our
                  Primary Services as described in this Privacy Policy or as
                  described at the time of collection or sharing, including as
                  follows:
                </p>
                <ol className="sub-point-list">
                  <li>
                    With Support Staff to enable them to provide the Services
                    you request;
                  </li>
                  <li>
                    With third parties to provide you a service you requested
                    through a partnership or promotional offering made by a
                    third party or by Us;
                  </li>
                  <li>
                    With the general public or with the audience selected by you
                    for the post, such as group or customised list of people, if
                    you submit content in a public forum, such as blog comments,
                    social media posts, or other features of Our Primary
                    Services that are viewable by the general public;
                  </li>
                  <li>
                    With third parties with whom you choose to let Us share
                    information, for example other apps or websites that
                    integrate with Our API or Services, or those with an API or
                    service with which We integrate; and
                  </li>
                  <li>
                    With your employer (or similar entity) and any necessary
                    third parties engaged by Us or your employer (e.g., an
                    expense management service provider), if you participate in
                    any of Our enterprise solutions.
                  </li>
                </ol>
              </li>
              <li>
                <p>Further, We may share your information:</p>
                <ol className="sub-point-list">
                  <li>
                    With Our subsidiaries and affiliated entities that provide
                    services or conduct data processing on Our behalf, or for
                    data centralization and / or logistics purposes;
                  </li>
                  <li>
                    With vendors, consultants, marketing partners and other
                    service providers who need access to such information to
                    carry out work on Our behalf;
                  </li>
                  <li>
                    In response to a request for information by a competent
                    authority if We believe disclosure is in accordance with, or
                    is otherwise required by, any applicable law, regulation, or
                    legal process;
                  </li>
                  <li>
                    With law enforcement officials, government authorities, or
                    other third parties if We believe your actions are
                    inconsistent with Our Terms of Use or other policies, or to
                    protect the rights, property, or safety of Stupa Sports
                    Analytics or others;
                  </li>
                  <li>
                    In connection with, or during negotiations of, any merger,
                    sale of company assets, consolidation or restructuring,
                    financing, or acquisition of all or a portion of Our
                    business by or into another company;
                  </li>
                  <li>
                    If We otherwise notify you and you consent to the sharing;
                    and
                  </li>
                  <li>
                    In an aggregated and/or anonymized form which cannot
                    reasonably be used to identify you.
                  </li>
                </ol>
              </li>
              <li>
                <p>Social sharing features</p>
                <p>
                  The Primary Services may integrate with social sharing
                  features and other related tools which let you share actions
                  you take on Our Primary Services with other apps, sites, or
                  media, and vice versa. Your use of such features enables the
                  sharing of information with your friends or the public,
                  depending on the settings you establish with the social
                  sharing service. Please refer to the privacy policies of those
                  social sharing services for more information about how they
                  handle the data you provide to or share through them.
                </p>
              </li>
              <li>
                <p>Analytics and advertising services provided by others</p>
                <p>
                  We may allow others to provide audience measurement and
                  analytics services for Us, to serve advertisements on Our
                  behalf across the internet, and to track and report on the
                  performance of those advertisements. These entities may use
                  cookies, web beacons, software development kits (SDKs), and
                  other technologies to identify your device when you visit Our
                  site and use Our Services, as well as when you visit other
                  online sites and services, your IP address, web browser, pages
                  viewed, time spent on pages, links clicked and conversion
                  information. This information may be used by Us and third
                  parties to, among other things, analyse and track data,
                  determine the popularity of certain content and other websites
                  and better understand your online activity.
                </p>
                <p>
                  Our Privacy Policy does not apply to, and We are not
                  responsible for, third party cookies, web beacons or other
                  tracking technologies and We encourage you to check the
                  privacy policies of these third parties to learn more about
                  their privacy practices.
                </p>
              </li>
              <li>
                <p>Public areas, forums, reviews, and community areas</p>
                <p>
                  Please remember any success stories, comments, messages, blogs
                  etc. posted/ uploaded/ conveyed/ communicated by you on the
                  public sections of Our Platform becomes published content and
                  becomes public. Anyone may take and use that information.
                  Please be careful about what you disclose and do not post any
                  personal information that you expect to keep private. We may
                  publish such published content on Our Platforms so long as you
                  consent. You may request that such published content be taken
                  down at any time and We shall remove such published content.
                  However, We are not responsible for any actions taken by
                  third-parties with respect to such published content.
                </p>
              </li>
              <li>
                We as an intermediary are responsible to facilitate a
                transaction between you and the ultimate event or fitness
                session organiser/partner so as to enable you to book/purchase a
                reservation for an sports event, sports session, coaching
                session, etc. For Us to be able to facilitate this transaction
                and otherwise fulfil Our contractual arrangements with these
                ultimate product/service providers, We may have to share your
                data with them so that they are able to verify the accuracy of
                the information you have shared with Us while issuing your valid
                booking id or reservation, to provide you with services and
                benefits that you may be entitled to and to conduct their own
                analysis.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">Your Data</p>
            <ol className="sub-point-list">
              <li>
                <p>Account information</p>
                <p>
                  You may correct your account information at any time by
                  logging into your account. If you wish to cancel your account,
                  please email Us at{" "}
                  <span style={{ color: "#62ABDF" }}>
                    {` support@stupaanalytics.com `}
                  </span>
                </p>
                <p>
                  Please note that in some cases We may retain certain
                  information about you as required by law, or for legitimate
                  business purposes to the extent permitted by law. For
                  instance, if you have a standing credit or debt on your
                  account, or if We believe you have committed fraud or violated
                  Our Terms of Service, We may seek to resolve the issue before
                  deleting your information.
                </p>
              </li>
              <li>
                <p>Access, correction and deletion rights</p>
                <p>
                  Stupa Sports Analytics will comply with individuals requests
                  regarding access, correction, and/or deletion of the personal
                  data it stores in accordance with applicable law. You may
                  request access, correction or updation, and deletion of the
                  data by contacting{" "}
                  <span style={{ color: "#62ABDF" }}>
                    {` support@stupaanalytics.com `}
                  </span>
                  . You may note that deletion of certain data or withdrawal of
                  consent may lead to cancellation of your registration with Us
                  or your access to Our Services. Based on technical
                  feasibility, We will provide you with access to all your
                  personal and sensitive personal data that We maintain about
                  you. We will perform verification before providing you access
                  to this data.
                </p>
                <p>
                  You may opt out of Our marketing emails by clicking on the
                  ‘opt-out’ link provided in the emails. However, We may still
                  send you non-marketing emails about your accounts or any other
                  transactions with you.
                </p>
              </li>
              <li>
                <p>Location information</p>
                <p>
                  We request permission from Our Platform for collection of
                  precise location from your device, per the permission system
                  used by your mobile operating system or the operating system
                  of your device. If you initially permit the collection of this
                  information, you can later disable it by changing the location
                  settings on your mobile device or any other device which You
                  are using for accessing the Platform. However, this will limit
                  your ability to use certain features of Our Services.
                </p>
              </li>
              <li>
                <p>Promotional Communications</p>
                <p>
                  You may opt out of receiving promotional messages from Us by
                  following the instructions in those messages. If you opt out,
                  We may still send you non-promotional communications, such as
                  those about your account, about Services you have requested,
                  or Our ongoing business relations.
                </p>
              </li>
              <li>
                <p>Geo-Location Terms</p>
                <p>
                  The Services include and make use of certain functionality and
                  services provided by third-parties that allow Us to include
                  maps, geocoding, places and other content from Google or
                  third-party vendors like Google, as part of the Services (the
                  Geo-Location Services). Your use of the Geo-Location Services
                  is subject to Googles then current terms of use for Google
                  Maps/Google Earth and by using the Geo-Location Services, you
                  are agreeing to be bound by Googles Terms of Use or relevant
                  terms from other third-party providers.
                </p>
              </li>
              <li>
                <p>International Visitors and Users</p>
                <p>
                  We currently only provide the Services in India. If you choose
                  to become a User from other regions of the world, then please
                  note that you may be transferring your personal information
                  outside of those regions for storage and processing. Also, We
                  may transfer your data from India or elsewhere to other
                  countries or regions in connection with storage and processing
                  of data, fulfilling your requests, and providing the services
                  associated with the Services. By providing any information,
                  including personal information, on or through the Services,
                  you consent to such transfer, storage, and processing.
                </p>
              </li>
              <li>
                <p>Retention and Storage</p>
                <p>
                  We retain your data only for as long as necessary to provide
                  the services you have requested and thereafter for a variety
                  of legitimate legal or business purposes. These might include
                  retention periods: (a) mandated by law, contract or similar
                  obligations applicable to Our business operations; (b) for
                  preserving, resolving, defending or enforcing Our
                  legal/contractual rights; or needed to maintain adequate and
                  accurate business and financial records.
                </p>
                <p>
                  Your personal data will primarily be stored in electronic
                  form. We may enter into agreement with third parties to
                  collect, store, process your personal data but under full
                  compliance with applicable laws. We may need to retain your
                  personal data even if you seek deletion thereof, if it is
                  needed to comply with Our legal obligations, resolve disputes
                  and enforce our agreements.
                </p>
                <p>
                  If you are a registered user, please be advised that after you
                  terminate your usage of a Service, We may, unless legally
                  prohibited, delete all data provided or collected by you from
                  Our servers.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p className="bold"> Changes to the Privacy Policy</p>
            <ol className="sub-point-list">
              <li>
                We reserve the right, at Our discretion, to change this Privacy
                Policy on a going-forward basis at any time. Please check this
                Privacy Policy periodically for changes. Modified Privacy Policy
                becomes effective upon its publication on the Website. If We
                make material changes to this Privacy Policy, We will attempt to
                provide you reasonable notice before such changes become
                effective. By continuing using the Services, you agree to be
                bound by the updated, amended, or modified Privacy Policy. If
                you do not agree to be bound by the modified Privacy Policy, you
                must immediately cease your use of the Services. Disputes
                arising under this Privacy Policy will be resolved in accordance
                with the version of this Privacy Policy that was in effect at
                the time the dispute arose.
              </li>
              <li>
                We reserve the right, at Our discretion, to change this Privacy
                Policy on a going-forward basis at any time. Please check this
                Privacy Policy periodically for changes. Modified Privacy Policy
                becomes effective upon its publication on the Website. If We
                make material changes to this Privacy Policy, We will attempt to
                provide you reasonable notice before such changes become
                effective. By continuing using the Services, you agree to be
                bound by the updated, amended, or modified Privacy Policy. If
                you do not agree to be bound by the modified Privacy Policy, you
                must immediately cease your use of the Services. Disputes
                arising under this Privacy Policy will be resolved in accordance
                with the version of this Privacy Policy that was in effect at
                the time the dispute arose.
              </li>
            </ol>
          </li>
          <li>
            <p className="bold">Contact Us</p>
            <p>
              Should you have questions about this Privacy Policy or Stupa
              Sports Analytics&apos;s information collection, use and disclosure
              practices, you may contact, the Grievance Officer appointed by
              Stupa Sports Analytics. Please find below the details of the
              Grievance Officer for the purpose of this Privacy Policy:
            </p>
            <p>
              Email:{" "}
              <span style={{ color: "#62ABDF" }}>
                {` support@stupaanalytics.com `}
              </span>
            </p>
            <p>
              Address: Kadarpur Rd, Sector-63A, Gurugram, Kadarpur, Haryana
              122102
            </p>
            <p>
              Any communication pursuant to this Clause which is not marked to
              the designated individual mentioned here in above shall not be
              treated as a valid and binding communication for the purpose of
              this Privacy Policy.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
