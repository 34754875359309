import React, { useState } from "react";
import "./SportsFilter.scss";

interface SportsFilterProps {
  calendar?: boolean;
  sportId: string;
  setSportId: (value: string) => void;
  calendarMonth?: string;
  setCalendarMonth?: (value: string) => void;
}

const SportsFilter: React.FC<SportsFilterProps> = ({
  calendar = true,
  sportId,
  setSportId,
  calendarMonth,
  setCalendarMonth = () => {},
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const selectMonth = (month: string) => {
    if (calendarMonth === month) {
      setCalendarMonth("");
    } else {
      setCalendarMonth(month);
    }
  };

  return (
    <div className="flex-column gap-12">
      <div className="match-filter-container">
        <div className="sports-select-filters">
          {sportId === "" ? (
            <div className="all-sports">All sports</div>
          ) : (
            <p
              className="unselected-all font-small bold py-14 px-18"
              onClick={() => setSportId("")}
            >
              All
            </p>
          )}
          {sportId === "2" ? (
            <div className="selected-sports flex-row gap-8 px-10 align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240708172129-Shuttlecock.svg"
                alt="racket-highlight"
                width={16}
                height={16}
              />
              <p className="font-small bold" style={{ color: "#E2E2E2" }}>
                Badminton
              </p>
            </div>
          ) : (
            <div className="sports" onClick={() => setSportId("2")}>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240623195216-Shuttlecock.svg"
                alt="Shuttlecock"
              />
            </div>
          )}
          {sportId === "1" ? (
            <div className="selected-sports flex-row gap-8 px-10 align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240708172129-Racket.svg"
                alt="racket-highlight"
                width={16}
                height={16}
              />
              <p className="font-small bold" style={{ color: "#E2E2E2" }}>
                Padel
              </p>
            </div>
          ) : (
            <div className="sports" onClick={() => setSportId("1")}>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240623195216-Racket+%281%29.svg"
                alt="Racket"
              />
            </div>
          )}
          {sportId === "3" ? (
            <div className="selected-sports flex-row gap-8 px-10 align-items-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240708172831-Ping+Pong.svg"
                alt="racket-highlight"
                width={16}
                height={16}
              />
              <p className="font-small bold" style={{ color: "#E2E2E2" }}>
                Table Tennis
              </p>
            </div>
          ) : (
            <div className="sports" onClick={() => setSportId("3")}>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240623195216-Ping+Pong.svg"
                alt="Ping Pong"
              />
            </div>
          )}
        </div>
        {calendar && (
          <div
            className="calender-filter"
            onClick={() => setShowCalendar((pre) => !pre)}
            style={{ background: showCalendar ? "#73C58A" : "#FFFFFF" }}
          >
            <img
              src={
                showCalendar
                  ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240820053125-white_cross.svg"
                  : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240623195216-Frame+%281%29.svg"
              }
              alt="Calendar"
            />
          </div>
        )}
      </div>
      {showCalendar && (
        <div className="flex-row month-container gap-16">
          {months.map((month, index) => {
            return (
              <p
                key={index}
                className={`month-item px-20 py-10 bold font-mid ${
                  calendarMonth === month && "selected-month"
                }`}
                onClick={() => selectMonth(month)}
              >
                {month}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SportsFilter;
