import React, { useEffect, useState } from "react";
import "./PlayerSelect.scss";
import { useNavigate } from "react-router-dom";
import AddNewPlayer from "../AddNewPlayer/AddNewPlayer";
import { Player } from "../../models/Player";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";

interface PlayerSelectProps {
  gameType: "SINGLES" | "DOUBLES";
  team?: "A" | "B";
  submitPlayers: (playerSelected: Player[]) => void;
}

const PlayerSelect: React.FC<PlayerSelectProps> = ({
  gameType,
  team,
  submitPlayers,
}) => {
  const [playerSelected, setSelectedPlayer] = useState<number[]>([]);
  const [selectionCompleted, setSelectionCompleted] = useState(false);
  const [openSearch, setSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [playerMsg, setPlayerMsg] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [playerList, setPlayerList] = useState<Player[]>([]);
  const [filterPlayerList, setFilterPlayerList] = useState<Player[]>([]);

  useEffect(() => {
    getPastParticipants();
  }, []);

  useEffect(() => {
    if (gameType === "SINGLES" || (gameType === "DOUBLES" && team === "A")) {
      setSelectionCompleted(playerSelected.length === 1);
    } else {
      setSelectionCompleted(playerSelected.length === 2);
    }
  }, [playerSelected]);

  useEffect(() => {
    setFilterPlayerList(
      playerList.filter((entry) =>
        entry.name?.toLowerCase()?.includes(searchText.toLowerCase()),
      ),
    );
  }, [playerList, searchText]);

  const getPastParticipants = async () => {
    try {
      const res = await ApiService().client.get(URL.MATCH.PAST_PARTICIPANTS);
      if (res.status === 200) {
        setPlayerList(
          res.data.data.past_participants.map((row: any) => {
            return {
              id: row.player_id,
              name: row.player_first_name,
              avatar:
                row.player_image ||
                "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618071656-14.webp",
            };
          }),
        );
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const selectPlayer = (player: Player) => {
    console.log("Player: ", player, playerSelected);
    if (playerSelected.indexOf(player.id) === -1) {
      if (gameType === "SINGLES" || (gameType === "DOUBLES" && team === "A")) {
        setSelectedPlayer([player.id]);
      } else if (playerSelected.length < 2) {
        if (playerSelected.length === 0) {
          setPlayerMsg(true);
        } else {
          setPlayerMsg(false);
        }
        setSelectedPlayer((val) => [...val, player.id]);
      }
    } else {
      setSelectedPlayer((val) => val.filter((id) => player.id !== id));
      setPlayerMsg(false);
    }
  };

  const searchChangeHandler = (e: any) => {
    setSearchText(e.target.value);
  };

  const submitPlayerHandler = () => {
    const players: Player[] = [];
    playerSelected.forEach((id) => {
      const player = playerList.filter((row) => row.id === id)[0];
      if (player) {
        players.push(player);
      }
    });
    submitPlayers(players);
  };

  const saveNewPlayer = async (player: Player) => {
    try {
      const params: any = {
        first_name: player.name,
        gender: player.gender,
        country_code: player.countryCode,
        image_url: player.avatar,
      };
      if (player.mobile !== "") {
        params.phone_number = player.mobile;
      }
      const res = await ApiService().client.post(
        URL.MATCH.ADD_NEW_PLAYER,
        params,
      );
      player = { ...player, id: res.data.data.player_id };
      setPlayerList((val) => [player, ...val]);
      setAddNew(false);
      selectPlayer(player);
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  return (
    <div
      className={`player-select-container flex-column align-items-center ${
        (selectionCompleted || addNew) && "add-player-open"
      }`}
    >
      {!addNew && (
        <>
          {!openSearch && (
            <div className="top-row flex-row align-items-center justify-content-center px-20">
              <p>Add Players</p>
              <div
                className="search-container flex-row align-items-center justify-content-center"
                onClick={() => setSearch(true)}
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620071330-filled.svg"
                  alt="search"
                  width={12}
                  height={12}
                />
              </div>
            </div>
          )}
          {openSearch && (
            <div className="flex-row align-items-center justify-content-space-between full-width gap-20 px-20 border-box">
              <div className="search-text-container flex-row align-items-center gap-16 flex-1 px-16 py-8">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620071330-filled.svg"
                  alt="search"
                  width={12}
                  height={12}
                />
                <input
                  className="search-text"
                  value={searchText}
                  onChange={searchChangeHandler}
                  placeholder="Search Players.."
                />
              </div>
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620114324-close.svg"
                alt="close"
                width={30}
                height={30}
                onClick={() => setSearch(false)}
              />
            </div>
          )}
          {gameType === "DOUBLES" && (
            <div className="team-selection-container px-12 py-6 flex-row">
              <p className={`team-name ${team === "A" && "selected"}`}>
                Team A
              </p>
              <p className={`team-name ${team === "B" && "selected"}`}>
                Team B
              </p>
            </div>
          )}
          <div className="player-selection px-20">
            <div
              className="add-new-player flex-column align-items-center gap-8 justify-content-center"
              onClick={() => setAddNew(true)}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620081537-Vector+%281%29.svg"
                alt="plus"
                width={40}
                height={40}
              />
              <p>Add New</p>
            </div>
            {filterPlayerList.map((player) => (
              <div
                className={`existing-player flex-column align-items-center gap-8 justify-content-center py-4 ${
                  playerSelected.indexOf(player.id) > -1 && "selected-player"
                }`}
                key={player.id}
                onClick={() => selectPlayer(player)}
              >
                <img src={player.avatar} alt="plus" width={62} height={62} />
                <p className="player-name">{player.name}</p>
                {gameType === "DOUBLES" &&
                  team === "B" &&
                  playerSelected.indexOf(player.id) > -1 && (
                    <div className="counter flex-row align-items-center justify-content-center">
                      {playerSelected.indexOf(player.id) + 1}
                    </div>
                  )}
              </div>
            ))}
          </div>
          {playerMsg && (
            <p className="selecte-player-2-msg">Great! Now select Player 2</p>
          )}
          {selectionCompleted && (
            <div className="proceed-select-container px-20 py-16">
              <div
                className="proceed-select-cta flex-row gap-8 justify-content-center"
                onClick={submitPlayerHandler}
              >
                <p>
                  {gameType === "SINGLES" ||
                  (gameType === "DOUBLES" && team === "A")
                    ? "Add Player"
                    : "Add Players"}
                </p>
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617193647-Frame.svg"
                  alt="right"
                  width={20}
                  height={20}
                />
              </div>
            </div>
          )}
        </>
      )}
      {addNew && (
        <AddNewPlayer
          back={() => setAddNew(false)}
          saveNewPlayer={saveNewPlayer}
        />
      )}
    </div>
  );
};

export default PlayerSelect;
