import React, { useEffect, useState } from "react";
import "./ApiLoader.scss";

interface ApiLoaderProps {
  position?: "top" | "bottom";
}

const ApiLoader: React.FC<ApiLoaderProps> = ({ position = "bottom" }) => {
  return (
    <div
      className={`api-loader-container ${position === "top" && "top-loader"}`}
    >
      <div className="shimmer" />
    </div>
  );
};

export default ApiLoader;
