import React, { useState } from "react";
import "./NoAnalyticsData.scss";

const NoAnalyticsData: React.FC = () => {
  const [knowMore, setKnowMore] = useState(false);

  return (
    <div className="no-analytics-content flex-column gap-12 align-items-center">
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240728194838-gps+receiving.svg"
        alt="no-data"
        width={86}
        height={86}
      />
      <p
        className="font-small"
        style={{
          color: "#5F5F5F",
          width: "220px",
          textAlign: "center",
          marginTop: "24px",
        }}
      >
        Your AI Analytics are on their way. Expect groundbreaking Insights soon.
      </p>
      {knowMore ? (
        <div className="know-more-container px-10 py-8 flex-column gap-16 align-items-center">
          <p className="font-mid bolder">How it works</p>
          <div className="font-mid" style={{ color: "#757575" }}>
            <p>
              Stupa AI takes <span style={{ color: "#000000" }}>2-3 hrs</span>{" "}
              of time to:{" "}
            </p>
            <p style={{ marginTop: "8px" }}>
              -Analyse your performance on the video
            </p>
            <p>-Extract data out of it.</p>
            <p>-Furnish the data in a presentable manner.</p>
          </div>
          <div
            className="flex-row gap-8 cursor-pointer"
            onClick={() => setKnowMore(false)}
          >
            <p className="font-xsmall bold" style={{ color: "#62ABDF" }}>
              Know Less
            </p>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240728195928-Vector.svg"
              width={10}
              alt="arrow"
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        </div>
      ) : (
        <div
          className="flex-row gap-8 cursor-pointer"
          onClick={() => setKnowMore(true)}
        >
          <p className="font-xsmall bold" style={{ color: "#62ABDF" }}>
            Know More
          </p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240728195928-Vector.svg"
            width={10}
            alt="arrow"
          />
        </div>
      )}
    </div>
  );
};

export default NoAnalyticsData;
