export const WinMessage = {
  empty: {
    title: "Onlooker",
    icon: "",
    message: "Let's spark the champion within!",
    graph:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240628062710-Group+1000004651.webp",
  },
  low: {
    title: "Novice",
    icon: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707195119-image+53.svg",
    message:
      "Every champion starts as a novice – conquer the climb, one move at a time!",
    graph:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240628062710-Group+1000004702.webp",
  },
  mid: {
    title: "Striker",
    icon: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707195119-Flash+On.svg",
    message: "Strike fear into every challenge, and let victory be your goal!",
    graph:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240628062710-Group+1000004701.webp",
  },
  high: {
    title: "Warrior",
    icon: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707195119-Fire.svg",
    message:
      "Fuel your fire, embrace the grind, and dominate the game with relentless determination!",
    graph:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240628062711-Group+1000004706.webp",
  },
  full: {
    title: "Champion",
    icon: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240707195119-Rocket.svg",
    message: "Victory belongs to those who sweat, strive, and never settle!",
    graph:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240628062710-Group+1000004705.webp",
  },
};

export const percentWinMessage = (val: number) => {
  if (val === 0) {
    return WinMessage.empty;
  } else if (val > 0 && val <= 25) {
    return WinMessage.low;
  } else if (val > 25 && val <= 50) {
    return WinMessage.mid;
  } else if (val > 50 && val <= 75) {
    return WinMessage.high;
  }
  return WinMessage.full;
};
