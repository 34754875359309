import React, { useEffect, useState } from "react";
import SignInInput from "../../components/SignInInput/SignInInput";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../config/firebaseAuthConfig";
import EnterOTP from "../../components/EnterOTP/EnterOTP";
import "./SignIn.scss";
import { useNavigate } from "react-router-dom";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { SignInPages } from "../../data/SignIn.data";
import { useDispatch } from "react-redux";
import ApiLoader from "../../components/ApiLoader/ApiLoader";

const SignIn: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<SignInPages>(
    SignInPages.INPUT,
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    const loadingSub = ApiService().loadingSubject.subscribe((res) => {
      setLoading(res > 0);
    });
    return () => {
      loadingSub.unsubscribe();
    };
  }, []);

  const handleSignIn = (type: string) => {
    if (phoneNumber.length < 10 && type === "OTP") {
      return;
    }
    if (type === "GOOGLE") {
      handleGoogleLogin();
    } else {
      sendOtp();
    }
  };

  const sendOtp = async () => {
    try {
      const response = await ApiService().client.post(URL.USERS.SEND_OTP, {
        country_code: countryCode,
        phone_number: phoneNumber,
      });
      if (response.status === 200) {
        setOtp(response.data.data.otp);
        setCurrentPage(SignInPages.OTP);
      }
    } catch (err) {
      console.log("Error:", err);
      setCurrentPage(SignInPages.OTP);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setCurrentPage(SignInPages.NAME);
    } catch (error) {
      console.log("Error occurred during sign-in:", error);
    }
  };

  const handleNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const handleCountryCodeSet = (value: string) => {
    setCountryCode(value);
  };

  return (
    <div className="sign-in-container overflow-hidden">
      {loading && <ApiLoader position="top" />}
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617220257-Topology-1.svg"
        alt="top-right-pattern"
        className="top-right-pattern"
      />
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617220056-Topology-2.svg"
        alt="bottom-left-pattern"
        className="bottom-left-pattern"
      />
      <div className="top-row-details">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240611193440-Quote+1.svg"
          alt="quotes"
          width={94}
          height={64}
        />
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240705110302-Logo.webp"
          alt="stupa-logo"
          width={54}
        />
      </div>
      {currentPage === SignInPages.INPUT && (
        <SignInInput
          mobileCode={countryCode}
          signIn={handleSignIn}
          phoneNumber={phoneNumber}
          setPhoneNumber={handleNumberChange}
          codeSet={handleCountryCodeSet}
        />
      )}
      {currentPage === SignInPages.OTP && (
        <EnterOTP
          phoneNumber={phoneNumber}
          currentOtp={otp}
          countryCode={countryCode}
          editNumber={() => setCurrentPage(SignInPages.INPUT)}
        />
      )}
    </div>
  );
};

export default SignIn;
