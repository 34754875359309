import React, { useEffect, useState } from "react";
import "./CountryCodeSelector.scss";
import axios from "axios";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import { Country } from "../../models/Country";
import { COUNTRY_CODES } from "../../data/CountryCode.data";

interface CountryCodeSelectorProps {
  codeSet?: (val: Country) => void;
  countryCode?: string;
  customClass?: string;
  disabled?: boolean;
}

const CountryCodeSelector: React.FC<CountryCodeSelectorProps> = ({
  codeSet = (val: Country) => {},
  customClass,
  countryCode = "",
  disabled = false,
}) => {
  const [filteredCountryList, setFilteredCountryList] = useState<Country[]>();
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [showDropDown, setShowDropDown] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchCountryList();
  }, []);

  useEffect(() => {
    setFilteredCountryList(
      COUNTRY_CODES.filter((row) =>
        row.code.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }, [search]);

  const fetchCountryList = () => {
    let country: Country = COUNTRY_CODES.filter(
      (entry) => entry.name === "India",
    )[0];
    if (countryCode !== "") {
      country = COUNTRY_CODES.filter(
        (entry) => entry.prefix === countryCode,
      )[0];
    }
    setSelectedCountry(country);
    codeSet(country);
    setFilteredCountryList(
      COUNTRY_CODES.sort((a, b) => (a.name > b.name ? 1 : -1)),
    );
  };

  const searchHandler = (e: any) => {
    const val: string = e.target.value;
    setSearch(val);
  };

  const selectCountry = (val: Country) => {
    setSelectedCountry(val);
    codeSet(val);
    setShowDropDown(false);
    setSearch("");
  };

  const openSearch = () => {
    if (!disabled) {
      setShowDropDown(true);
      setTimeout(() => {
        document.getElementById("searchText")?.focus();
      }, 200);
    }
  };

  return (
    <OutsideAlerter action={() => setShowDropDown(false)}>
      <>
        {showDropDown ? (
          <div
            className={`country-code-selector-active-container ${customClass}-active`}
          >
            <input
              id="searchText"
              className="search"
              value={search}
              onChange={searchHandler}
            />
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240621110935-grey-down-arrow.svg"
              alt="down-arrow"
              width={12}
              className="p-12"
            />
            <div className="country-drop-container p-16 flex-column gap-16">
              {filteredCountryList?.map((val) => (
                <div
                  className="flex-row gap-12"
                  key={val.code}
                  onClick={() => selectCountry(val)}
                >
                  <img src={val.flag} alt="flag" width={20} height={20} />
                  <p>{val.code}</p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            className={`country-code-selector-container p-12 ${customClass}`}
            onClick={openSearch}
          >
            <img src={selectedCountry?.flag} alt="country" width={20} />
            <p className="country-name px-6 border-box">
              {selectedCountry?.prefix}
            </p>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240621110935-grey-down-arrow.svg"
              alt="down-arrow"
              width={12}
            />
          </div>
        )}
      </>
    </OutsideAlerter>
  );
};

export default CountryCodeSelector;
