import React, { useEffect, useState } from "react";
import "./WinningTrend.scss";
import { WinningTrendMessage } from "../../data/WinningTrend.data";
import { Chart } from "chart.js/auto";

interface WinningTrendProps {
  weeklyWinningTrendData: { period: string; win_rate: number }[];
  monthlyWinningTrendData: { period: string; win_rate: number }[];
}

const WinningTrend: React.FC<WinningTrendProps> = ({
  weeklyWinningTrendData,
  monthlyWinningTrendData,
}) => {
  const [winMessage, setWinMessage] = useState<{
    icon: string;
    message: string;
  }>();
  const [tab, setTab] = useState<"weekly" | "monthly">("weekly");
  const [winningTrendData, setWinningTrendData] = useState<
    { period: string; win_rate: number }[]
  >([]);

  useEffect(() => {
    if (tab === "weekly") {
      setWinMessage(WinningTrendMessage.weekly);
      setWinningTrendData(weeklyWinningTrendData);
    }
    if (tab === "monthly") {
      setWinMessage(WinningTrendMessage.monthly);
      setWinningTrendData(monthlyWinningTrendData);
    }
  }, [tab, weeklyWinningTrendData, monthlyWinningTrendData]);

  useEffect(() => {
    weeklyGraphRender();
  }, [winningTrendData]);

  const weeklyGraphRender = async () => {
    if (winningTrendData.length === 0) {
      return;
    }
    await clearChart();
    const data = winningTrendData;
    console.log("RENDER GRPAH");
    const canvas = document.getElementById("winning-trend-graph");
    if (canvas) {
      const ctx = new Chart(canvas as HTMLCanvasElement, {
        type: "line",
        data: {
          labels: data.map((row) => row.period),
          datasets: [
            {
              label: "Win percent",
              data: data.map((row) => row.win_rate),
              borderColor: "#79EC63",
              borderWidth: 2,
              pointBackgroundColor: "#ffffff",
              pointBorderColor: "#79EC63",
              pointBorderWidth: 2,
              pointRadius: 7,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              offset: true,
              max: 100,
              grid: {
                color: "#E2E2E2",
                tickColor: "transparent",
              },
              border: {
                display: true,
                dash: [8, 4],
                color: "transparent",
              },
              ticks: {
                color: "#AFAFAF",
                font: {
                  size: 10,
                  weight: 500,
                  family: "Poppins",
                },
              },
            },
            x: {
              grid: {
                display: false,
              },

              border: {
                display: false,
              },
              ticks: {
                color: "#AFAFAF",
                font: {
                  size: 10,
                  weight: 500,
                  family: "Poppins",
                },
              },
            },
          },
        },
      });
    }
  };

  const clearChart = async () => {
    const chartStatus = Chart.getChart("winning-trend-graph"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }
  };

  return (
    <div className="winning-trend-details-container px-12 py-6 flex-column align-items-center">
      <div className="backdrop-image" />
      <div className="flex-row align-items-end full-width">
        <div
          className="flex-row justify-content-space-between full-width"
          style={{ zIndex: "20" }}
        >
          <div className="flex-row gap-12 align-items-center">
            <p className="font-large bold" style={{ color: "#222222" }}>
              Winning Trend
            </p>
          </div>
          <div className="period-selector-container px-2 py-8 flex-row">
            <p
              className="font-xsmall bold px-10 py-6"
              style={{
                color: `${tab === "weekly" ? "#ffffff" : "#3A3A3A"}`,
                background: `${tab === "weekly" ? "#000000" : "#ffffff"}`,
                borderRadius: "25px",
              }}
              onClick={() => setTab("weekly")}
            >
              {tab === "weekly" ? "Weekly" : "W"}
            </p>
            <p
              className="font-xsmall bold px-10 py-6"
              style={{
                color: `${tab === "monthly" ? "#FFFFFF" : "#3A3A3A"}`,
                background: `${tab === "monthly" ? "#000000" : "#ffffff"}`,
                borderRadius: "25px",
              }}
              onClick={() => setTab("monthly")}
            >
              {tab === "monthly" ? "Monthly" : "M"}
            </p>
          </div>
        </div>
      </div>
      <div className="trend-graph-container">
        <canvas id="winning-trend-graph"></canvas>
      </div>
      <div
        className="flex-row gap-18 align-items-center full-width border-box px-18 py-6"
        style={{
          background: "#EEEEEE",
          borderRadius: "10px",
          marginTop: "10px",
        }}
      >
        {winMessage && (
          <>
            <img src={winMessage.icon} width={26} height={26} />
            <p className="font-xsmall bold" style={{ color: "#5f5f5f" }}>
              {winMessage.message}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default WinningTrend;
