import environment from "../environment/environment";

const URL = {
  USERS: {
    GOOGLE_LOGIN: `${environment.baseUrl}/auth/google_login`,
    GET_USER: `${environment.baseUrl}/user/v1/get_user_profile`,
    GET_USER_COMPLETION: `${environment.baseUrl}/user/v1/get_profile_percentage`,
    GET_WIN_PERCENT: `${environment.baseUrl}/user/v1/stats/get_win_percentage`,
    GET_WINNING_TREND: `${environment.baseUrl}/user/v1/stats/get_winning_trend`,
    GET_ENGAGEMENT_RATE: `${environment.baseUrl}/user/v1/stats/engagement_balance`,
    TOTAL_MATCHES: `${environment.baseUrl}/user/v1/stats/total_matches`,
    TOTAL_PLAY_TIME: `${environment.baseUrl}/user/v1/stats/total_play_time`,
    TOTAL_SCORE: `${environment.baseUrl}/user/v1/stats/total_score`,
    UPDATE_USER: `${environment.baseUrl}/user/v1/update_user_profile`,
    LOGOUT: `${environment.baseUrl}/auth/logout`,
    SEND_OTP: `${environment.baseUrl}/auth/v1/user_authentication`,
    VERIFY_OTP: `${environment.baseUrl}/auth/v1/verify_otp`,
    PLAYER_STATS: `${environment.baseUrl}/user/v1/player_stats`,
  },
  MATCH: {
    LIST: `${environment.baseUrl}/matches/v1/get_match_listing`,
    CREATE: `${environment.baseUrl}/matches/v1/create_match`,
    UPDATE: `${environment.baseUrl}/matches/v1/update_match`,
    CREATE_TEAM: `${environment.baseUrl}/matches/v1/create_teams`,
    ADD_MATCH_PARTICIPANTS: `${environment.baseUrl}/matches/v1/add_match_participants`,
    PAST_PARTICIPANTS: `${environment.baseUrl}/user/v1/get_past_participants`,
    ADD_NEW_PLAYER: `${environment.baseUrl}/user/v1/add_new_player`,
    ADD_MATCH_SCORE: `${environment.baseUrl}/matches/v1/add_match_score`,
  },
  ANALYTICS: {
    MATCH_STATS: `${environment.baseUrl}/analytics/match/stats`,
  },
  COURT: {
    DETAILS: `${environment.baseUrl}/court/v1/get_court_details`,
  },
};

export default URL;
