const PATHS = {
  SIGN_IN: "/sign-in",
  ALL_MATCHES: "/matches",
  NAME: "/name",
  AVATAR: "/avatar",
  EMAIL: "/email",
  RECORD_MATCH: "/record",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  TERMS_AND_CONDITIONS: "/terms_and_conditions",
  PRIVACY: "/privacy_policy",
  MATCH: (id: number) => `/matches/${id}`,
};

export default PATHS;
