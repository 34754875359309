import React, { useEffect, useState } from "react";
import "./AvatarSelect.scss";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";
import { useDispatch, useSelector } from "react-redux";
import {
  isUserLoading,
  selectUserAvatar,
  selectUserName,
} from "../../redux/user/userSelectors";
import { updateUserDetails } from "../../redux/user/userActions";
import { AVATAR_IMAGES } from "../../data/Avatar.data";
import ApiLoader from "../../components/ApiLoader/ApiLoader";
import ApiService from "../../services/apiService";

const AvatarSelect: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userName = useSelector(selectUserName);
  const currentAvatar = useSelector(selectUserAvatar);
  const isLoading = useSelector(isUserLoading);
  const [avatar, setAvatar] = useState(AVATAR_IMAGES[0]);
  const [apiloading, seApiLoading] = useState(false);

  useEffect(() => {
    const loadingSub = ApiService().loadingSubject.subscribe((res) => {
      seApiLoading(res > 0);
    });
    return () => {
      loadingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!isLoading && currentAvatar != null) {
      navigate(PATHS.DASHBOARD);
    }
  }, [isLoading, currentAvatar]);

  const nextHandler = () => {
    dispatch(updateUserDetails({ image_url: avatar }));
  };

  const handleAvatarUpdate = (url: string) => {
    setAvatar(url);
  };

  const skipHandler = () => {
    navigate(PATHS.DASHBOARD);
  };

  return (
    <div className="sign-in-container overflow-hidden">
      {apiloading && <ApiLoader position="top" />}
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617220257-Topology-1.svg"
        alt="top-right-pattern"
        className="top-right-pattern"
      />
      <img
        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617220056-Topology-2.svg"
        alt="bottom-left-pattern"
        className="bottom-left-pattern"
      />
      <div className="top-row-details name-found">
        <div className="user-found-container">
          <p className="user-name">Hi {userName},</p>
          <p className="avatar-msg">AVATAR UP!</p>
        </div>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240705110302-Logo.webp"
          alt="stupa-logo"
          width={54}
        />
      </div>
      {/* <div className="skip-container" onClick={skipHandler}>
        <p className="avatar-sub-msg">{`Be anyone you want, don't hold back`}</p>
        <p className="skip">Skip</p>
      </div> */}
      <div className="avatar-select-container flex-column justify-content-space-between">
        <div className="avatar-container">
          {AVATAR_IMAGES.map((url, index) => (
            <div
              key={`user-avatar-${index}`}
              className={`user-avatar ${avatar === url && "selected-avatar"}`}
              onClick={() => handleAvatarUpdate(url)}
            >
              <img src={url} alt={`avatar-${index}`} width={70} height={70} />
            </div>
          ))}
        </div>
        <div className="next-container">
          <div
            className="next-btn flex-row justify-content-center"
            onClick={nextHandler}
          >
            <p>Next</p>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617193647-Frame.svg"
              alt="right"
              width={20}
              height={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarSelect;
