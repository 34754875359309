import axios, { AxiosInstance } from "axios";
import { JWT_TOKEN, getCookie } from "./cookieService";
import { Subject } from "rxjs";
import environment from "../environment/environment";
import { useDispatch } from "react-redux";
import { logOutUser } from "../redux/user/userActions";

// Created a Singleton Api Service class so we use a single Instance always
const ApiService = (() => {
  let instance: {
    loadingSubject: Subject<number>;
    statusSubject: Subject<number>;
    client: AxiosInstance;
  };

  function createInstance() {
    const loadingSubject = new Subject<number>();
    const statusSubject = new Subject<number>();
    let counter = 0;
    const customApiService = axios.create({
      baseURL: environment.baseUrl,
    });
    customApiService.interceptors.request.use((config) => {
      counter++;
      loadingSubject.next(counter);
      config.headers["token"] = getCookie(JWT_TOKEN);
      return config;
    });
    customApiService.interceptors.response.use(
      (response) => {
        setTimeout(() => {
          counter--;
          loadingSubject.next(counter);
        }, 1000);
        statusSubject.next(response.data.code);
        return response;
      },
      (error) => {
        counter--;
        loadingSubject.next(counter);
        return Promise.reject(error);
      },
    );
    return {
      loadingSubject,
      statusSubject,
      client: customApiService,
    };
  }

  return () => {
    if (!instance) {
      instance = createInstance();
    }
    return instance;
  };
})();

export default ApiService;
