import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import CircleGraph from "../../components/CircleGraph/CircleGraph";
import SportsFilter from "../../components/SportsFilter/SportsFilter";
import WinPercentGraph from "../../components/WinPercentGraph/WinPercentGraph";
import WinningTrend from "../../components/WinningTrend/WinningTrend";
import EngagementRate from "../../components/EngagementRate/EngagementRate";
import UserPlayStats from "../../components/UserPlayStats/UserPlayStats";
import MatchSummary from "../../components/MatchSummary/MatchSummary";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";
import { useSelector } from "react-redux";
import {
  isUserLoading,
  selectUserAvatar,
  selectUserName,
} from "../../redux/user/userSelectors";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import EngagementRateInfo from "../../components/EngagementRateInfo/EngagementRateInfo";
import WinPercentInfo from "../../components/WinPercentInfo/WinPercentInfo";
import { MatchDetails } from "../../models/Match";
import { PlayerStats } from "../../models/Player";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState(0);
  const userName = useSelector(selectUserName);
  const userAvatar = useSelector(selectUserAvatar);
  const [completionPercent, setCompletionPercent] = useState(0);
  const [infoPopup, setInfoPopup] = useState("");
  const [sportId, setSportId] = useState("");
  const [matches, setMatches] = useState<MatchDetails[]>([]);
  const [playerStats, setPlayerStats] = useState<PlayerStats>();
  const isLoading = useSelector(isUserLoading);

  useEffect(() => {
    if (!isLoading) {
      if (userName == null || userName === "") {
        navigate(PATHS.NAME);
      } else if (userAvatar == null || userAvatar === "") {
        navigate(PATHS.AVATAR);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    getProfileCompletionPercent();
    getPlayerStats();
    getMatchListing();
  }, []);

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    const elm = document
      .getElementById("start-match-container")
      ?.getBoundingClientRect();
    if (elm) {
      const left = elm.left;
      if (position > left - 32) {
        navigate(PATHS.RECORD_MATCH);
      } else {
        setPosition(0);
      }
    }
  };

  const handleMouseMove = (e: React.TouchEvent) => {
    if (isDragging) {
      const pos = e.touches[0].clientX - 12;
      const elm = document
        .getElementById("start-match-container")
        ?.getBoundingClientRect();
      if (elm) {
        const left = elm.left;
        if (pos > 0 && pos < left) {
          setPosition(pos);
        }
      }
    }
  };

  const getProfileCompletionPercent = async () => {
    const res = await ApiService().client.get(URL.USERS.GET_USER_COMPLETION);
    if (res.status === 200) {
      setCompletionPercent(res.data.data.completion_percentage);
    }
  };

  const getPlayerStats = async () => {
    const params = sportId === "" ? {} : { params: { sport_id: sportId } };
    const res = await ApiService().client.get(URL.USERS.PLAYER_STATS, params);
    if (res.status === 200) {
      const response = res.data.data;
      setPlayerStats({
        ...response,
        win_rate: response["win rate"],
        total_matches_played: response["total matches played"],
        total_play_time: Math.round(response["total play time"] / 60),
        total_points: response["total points"],
      });
    }
  };

  const getMatchListing = async () => {
    try {
      const res = await ApiService().client.get(URL.MATCH.LIST, {
        params: {
          per_page: 5,
          page: 1,
        },
      });
      if (res.status === 200 && res.data.data?.matches) {
        setMatches(res.data.data.matches);
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  return (
    <div className="dashboard-details-container flex-column">
      <DashboardHeader />
      <div
        className="flex-column flex-1 full-width gap-14"
        style={{ overflowY: `${matches.length > 0 ? "scroll" : "hidden"}` }}
      >
        <div className="flex-column gap-16 px-20 py-10 full-width border-box">
          <div
            className="user-profile-container px-12 py-16 flex-row align-items-center justify-content-space-between"
            onClick={() => navigate(PATHS.PROFILE)}
          >
            <div className="flex-row align-items-center gap-8">
              <img
                src={userAvatar || ""}
                alt="user"
                width={56}
                height={56}
                className="border-round"
              />
              <div className="flex-column">
                <p className="font-large bold" style={{ color: "#5F5F5F" }}>
                  Hi,
                </p>
                <p className="font-large bold" style={{ color: "#000000" }}>
                  {userName}
                </p>
              </div>
            </div>
            <div className="completion-percent-container flex-column gap-4 align-items-center">
              <div className="completion-graph-container">
                <CircleGraph
                  width={48}
                  strokeWidth={5}
                  color="#000000"
                  percent={completionPercent}
                  animate={false}
                />
                <p className="font-small bold user-percent">
                  {completionPercent}%
                </p>
              </div>
              <p className="px-6 py-2 user-status font-xsmall bold">
                Completed
              </p>
            </div>
          </div>
          <p className="font-xlarge bold" style={{ color: "#000000" }}>
            Checkout your stats
          </p>
          <SportsFilter
            calendar={false}
            sportId={sportId}
            setSportId={setSportId}
          />
          <WinPercentGraph
            openInfo={() => setInfoPopup("WIN_PERCENT")}
            completionPercent={playerStats?.win_rate || 0}
          />
          <WinningTrend
            weeklyWinningTrendData={playerStats?.weekly_WT_data || []}
            monthlyWinningTrendData={playerStats?.monthly_WT_data || []}
          />
          <EngagementRate
            openInfo={() => setInfoPopup("ENGAGEMENT")}
            weeklyEngagementRateData={playerStats?.weekly_EB_data || []}
            monthlyEngagementRateData={playerStats?.monthly_EB_data || []}
          />
          <UserPlayStats
            totalMatches={playerStats?.total_matches_played || 0}
            totalPlayTime={playerStats?.total_play_time || 0}
            totalScore={playerStats?.total_points || 0}
          />
        </div>
        <div className="flex-column gap-16 px-20 py-10 flex-1 full-width border-box">
          <p className="font-xlarge bold" style={{ color: "#000000" }}>
            Latest Matches
          </p>
          {matches.map((match, index) => (
            <MatchSummary match={match} key={index} />
          ))}
          <div
            className="flex-row gap-16 px-14 py-9 view-all-btn"
            onClick={() => navigate(PATHS.ALL_MATCHES)}
          >
            <p className="font-small bold" style={{ color: "#eeeeee" }}>
              View All
            </p>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240627194829-Vector+%281%29.svg"
              alt="right"
              width={6}
              height={10}
            />
          </div>
        </div>
      </div>
      <div className="start-match-container">
        <div className="start-recording-container flex-row align-items-center justify-content-space-between">
          <div
            className="recorder-container flex-row align-items-center justify-content-center"
            style={{
              transform: `translateX(${position}px)`,
            }}
            onTouchStart={handleMouseDown}
            onTouchEnd={handleMouseUp}
            onTouchMove={handleMouseMove}
          >
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240628071047-Ping+Pong.svg"
              alt="ping-pong"
              width={26}
              height={26}
            />
          </div>
          <p className="start-match-msg">Slide to Play</p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240617214428-three_arrow.svg"
            alt="arrow"
          />
          <div
            className="start-container flex-row align-items-center justify-content-center"
            id="start-match-container"
          >
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240628071047-Vector+%282%29.svg"
              alt="recorder"
              width={18}
              height={12}
            />
          </div>
        </div>
      </div>
      {matches.length === 0 && (
        <div className="blur-backdrop flex-column">
          <p className="font-xlarge first-match-msg bolder">
            Record your <span style={{ color: "#79EC63" }}>first match</span> to
            get your stats
          </p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240628123202-double+down+arrow+grey.svg"
            alt="down-1"
            width={26}
            height={28}
            style={{ marginTop: "66px" }}
          />
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240628123202-dowble+down+arrow+black.svg"
            alt="down-1"
            width={26}
            height={28}
            style={{ marginTop: "24px" }}
          />
        </div>
      )}
      {infoPopup === "ENGAGEMENT" && (
        <EngagementRateInfo closeInfo={() => setInfoPopup("")} />
      )}
      {infoPopup === "WIN_PERCENT" && (
        <WinPercentInfo closeInfo={() => setInfoPopup("")} />
      )}
    </div>
  );
};

export default Dashboard;
