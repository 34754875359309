import React, { useEffect, useState } from "react";
import "./UserPlayStats.scss";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";

interface UserPlayStatsProps {
  totalMatches: number;
  totalPlayTime: number;
  totalScore: number;
}

const UserPlayStats: React.FC<UserPlayStatsProps> = ({
  totalMatches,
  totalPlayTime,
  totalScore,
}) => {
  return (
    <div className="user-stats-container">
      <div className="all-stats flex-row gap-16">
        <div className="flex-column stats-container px-16 py-10 gap-40">
          <p
            className="font-xsmall bold"
            style={{ color: "#0000000", wordBreak: "keep-all" }}
          >
            Total Matches
          </p>
          <div className="flex-row gap-28 align-items-center">
            <div className="stats-icon flex-row align-items-center justify-content-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626192237-Tennis+Player.svg"
                alt="player"
                width={15}
                height={15}
              />
            </div>
            <p className="font-xxlarge bolder" style={{ color: "#73C58A" }}>
              {totalMatches}
            </p>
          </div>
        </div>
        <div className="flex-column stats-container px-16 py-10 gap-40">
          <p className="font-xsmall bold" style={{ color: "#0000000" }}>
            Total Play Time
          </p>
          <div className="flex-row gap-28 align-items-center">
            <div className="stats-icon flex-row align-items-center justify-content-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626192237-Time.svg"
                alt="player"
                width={15}
                height={15}
              />
            </div>
            <p className="font-xxlarge bolder" style={{ color: "#6FA8A8" }}>
              {totalPlayTime}h
            </p>
          </div>
        </div>
        <div className="flex-column stats-container px-16 py-10 gap-40">
          <p className="font-xsmall bold" style={{ color: "#0000000" }}>
            Total Score
          </p>
          <div className="flex-row gap-28 align-items-center">
            <div className="stats-icon flex-row align-items-center justify-content-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240626192237-Scoreboard.svg"
                alt="player"
                width={15}
                height={15}
              />
            </div>
            <p className="font-xxlarge bolder" style={{ color: "#79EC63" }}>
              {totalScore}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPlayStats;
