import { User } from "../../models/User";

export const UserActions = {
  FETCH_USER: "FETCH_USER",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE: "FETCH_USER_FAILURE",
  SET_USER: "SET_USER",
  LOGOUT_USER: "LOGOUT_USER",
  VERIFY_OTP: "VERIFY_OTP",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
  GET_USER_DETAILS: "GET_USER_DETAILS",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",
  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
  UPDATE_USER_DETAILS_SUCCESS: "UPDATE_USER_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS_FAILURE: "UPDATE_USER_DETAILS_FAILURE",
};

export interface VerifyOtpPayload {
  phone_number: string;
  country_code: string;
  otp: string;
  court_id: number;
}

export interface UserDetails {
  first_name: string;
  last_name: string;
  image_url: string;
  id: number;
  age: number;
  gender: "M" | "F";
  weight: number;
  height: number;
  phone_number: string;
  country_code: string;
}

export interface UpdateUserDetailsPayload {
  first_name?: string;
  last_name?: string;
  image_url?: string;
  email?: string;
  age?: number | null;
  gender?: "M" | "F" | null;
  weight?: number;
  height?: number;
  phone_number?: string;
  country_code?: string;
}

export interface FetchUserAction {
  type: typeof UserActions.FETCH_USER;
  payload?: null;
  error?: null;
}

export interface FetchUserSuccessAction {
  type: typeof UserActions.FETCH_USER_SUCCESS;
  payload: User;
  error?: null;
}

export interface FetchUserFailureAction {
  type: typeof UserActions.FETCH_USER_FAILURE;
  payload?: null;
  error: string;
}

export type SetUserAction = {
  type: typeof UserActions.SET_USER;
  payload: User | null;
  error?: string | null;
};

export type LogOutUserAction = {
  type: typeof UserActions.LOGOUT_USER;
  payload?: null;
  error?: string | null;
};

export interface VerifyOtpAction {
  type: typeof UserActions.VERIFY_OTP;
  payload?: VerifyOtpPayload;
  error?: null;
}

export interface VerifyOtpSuccessAction {
  type: typeof UserActions.VERIFY_OTP_SUCCESS;
  payload: string;
  error?: null;
}

export interface VerifyOtpFailureAction {
  type: typeof UserActions.VERIFY_OTP_FAILURE;
  payload?: null;
  error: string;
}

export interface GetUserDetailsAction {
  type: typeof UserActions.GET_USER_DETAILS;
  payload?: null;
  error?: null;
}

export interface GetUserDetailsSuccessAction {
  type: typeof UserActions.GET_USER_DETAILS_SUCCESS;
  payload: UserDetails;
  error?: null;
}

export interface GetUserDetailsFailureAction {
  type: typeof UserActions.GET_USER_DETAILS_FAILURE;
  payload?: null;
  error: string;
}

export interface UpdateUserDetailsAction {
  type: typeof UserActions.UPDATE_USER_DETAILS;
  payload: UpdateUserDetailsPayload;
  error?: null;
}

export interface UpdateUserDetailsSuccessAction {
  type: typeof UserActions.UPDATE_USER_DETAILS_SUCCESS;
  payload: UpdateUserDetailsPayload;
  error?: null;
}

export interface UpdateUserDetailsFailureAction {
  type: typeof UserActions.UPDATE_USER_DETAILS_FAILURE;
  payload?: null;
  error: string;
}

export type UserActionTypes =
  | FetchUserAction
  | FetchUserSuccessAction
  | FetchUserFailureAction
  | SetUserAction
  | LogOutUserAction
  | VerifyOtpAction
  | VerifyOtpSuccessAction
  | VerifyOtpFailureAction
  | GetUserDetailsAction
  | GetUserDetailsSuccessAction
  | GetUserDetailsFailureAction
  | UpdateUserDetailsAction
  | UpdateUserDetailsSuccessAction
  | UpdateUserDetailsFailureAction;
