import React, { useEffect, useState } from "react";
import "./Highlights.scss";
import { timeDisplay } from "../../utils/Helper";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { MatchScoreCard } from "../../models/Analytics";

interface HighlightsProps {
  toggleShare: () => void;
  share: boolean;
  videoUrl: string;
  scorecard: MatchScoreCard;
  teamInitials: { A: string; B: string };
  teamDetails: {
    A: { first_name: string; last_name: string; imager_url: string }[];
    B: { first_name: string; last_name: string; imager_url: string }[];
  };
}

const Highlights: React.FC<HighlightsProps> = ({
  toggleShare,
  share,
  videoUrl,
  scorecard,
  teamInitials,
  teamDetails,
}) => {
  const [winner, setWinner] = useState("");
  const [messageTimer, setMessageTimer] = useState(10);

  useEffect(() => {
    if (scorecard.score_details) {
      const aWon = scorecard.score_details.set_scores.filter(
        (row) => row.team_A_points > row.team_B_points,
      ).length;
      const bWon = scorecard.score_details.set_scores.filter(
        (row) => row.team_B_points > row.team_A_points,
      ).length;
      if (aWon > bWon) {
        setWinner("A");
      }
      if (bWon > aWon) {
        setWinner("B");
      }
    }
    const options: any = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    let ct = 0;
    const interval = setInterval(() => {
      if (ct < 10) {
        setMessageTimer((val) => val - 1);
        ct++;
      } else {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className="highlights-content-container flex-column"
      style={{ gap: `${share ? "0px" : "16px"}` }}
    >
      <div style={{ padding: `0px ${share ? "16px" : "0px"}` }}>
        <VideoPlayer
          src={videoUrl}
          poster="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240711054712-Placeholder.webp"
        />
      </div>
      <div className="flex-column flex-1 highlight-detail-container gap-16">
        <div
          className={`flex-column gap-8 px-10 py-8 intro-container ${
            share && "share-on-intro"
          }`}
        >
          <div className="court-name-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 10 20"
              preserveAspectRatio="none"
              className="triangle-border"
            >
              <polygon points="0 0 10 0 10 20" className="triangle" />
            </svg>
            <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
              {scorecard.sport}
            </p>
          </div>
          <p className="font-small" style={{ color: "#222222" }}>
            {teamInitials.A} vs {teamInitials.B}
          </p>
          <p className="font-xsmall" style={{ color: "#7F7F7F" }}>
            {scorecard.date}
          </p>
          <div className="flex-row gap-8">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624114542-3-people.svg"
              alt="people"
              width={14}
              height={14}
            />
            {teamDetails.A.map((player, index) => (
              <div className="flex-row gap-2" key={`winner-${index}`}>
                <img
                  src={player.imager_url}
                  alt="team-a-1"
                  width={12}
                  height={12}
                  className="border-round"
                />
                <p className="font-xsmall" style={{ color: "#7F7F7F" }}>
                  {player.first_name}
                </p>
              </div>
            ))}

            {teamDetails.B.map((player, index) => (
              <div className="flex-row gap-2" key={`loser-${index}`}>
                <img
                  src={player.imager_url}
                  alt="team-a-1"
                  width={12}
                  height={12}
                  className="border-round"
                />
                <p className="font-xsmall" style={{ color: "#7F7F7F" }}>
                  {player.first_name}
                </p>
              </div>
            ))}
          </div>
        </div>
        {!share && (
          <div className="flex-row gap-18">
            <div className="action-btn flex-1 flex-row gap-12 align-items-center p-8 justify-content-center">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624120337-download.svg"
                alt="download"
                width={20}
                height={20}
              />
              <a
                href="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                download="highlights"
                className="font-small"
                style={{ color: "#3A3A3A", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            </div>
            <div
              className="action-btn flex-1 flex-row gap-12 align-items-center p-8 justify-content-center"
              onClick={toggleShare}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624120337-share.svg"
                alt="download"
                width={20}
                height={20}
              />
              <p className="font-small" style={{ color: "#3A3A3A" }}>
                Share
              </p>
            </div>
          </div>
        )}
        {scorecard.score && (
          <div
            className="scorecard-container px-24 py-16 flex-column gap-8"
            style={{ background: `${share ? "none" : "#ffffff"}` }}
          >
            <div className="scorecard-grid">
              <p
                className="team-name"
                style={{
                  background: `${winner === "A" ? "#666666" : "#AFAFAF"}`,
                }}
              >
                {teamInitials.A}
              </p>
              <div className="flex-row gap-16 justify-content-center">
                <p
                  className="font-xxlarge bolder"
                  style={{ color: `${winner === "A" ? "#3A3A3A" : "#AFAFAF"}` }}
                >
                  {scorecard.score.split(":")[0]}
                </p>
                <p className="font-xxlarge bolder" style={{ color: "#3A3A3A" }}>
                  :
                </p>
                <p
                  className="font-xxlarge bolder"
                  style={{ color: `${winner === "B" ? "#3A3A3A" : "#AFAFAF"}` }}
                >
                  {scorecard.score.split(":")[1]}
                </p>
              </div>
              <p
                className="team-name"
                style={{
                  background: `${winner === "B" ? "#666666" : "#AFAFAF"}`,
                }}
              >
                {teamInitials.B}
              </p>
            </div>
            <div className="scorecard-grid">
              <div
                className="flex-column"
                style={{ color: `${winner === "A" ? "#000000" : "#757575"}` }}
              >
                {teamDetails.A.map((player, index) => (
                  <p className="font-small bold" key={`winner-name-${index}`}>
                    {player.first_name} {player.last_name}
                  </p>
                ))}
              </div>
              <div></div>
              <div
                className="flex-column"
                style={{ color: `${winner === "B" ? "#000000" : "#757575"}` }}
              >
                {teamDetails.B.map((player, index) => (
                  <p className="font-small bold" key={`loser-name-${index}`}>
                    {player.first_name} {player.last_name}
                  </p>
                ))}
              </div>
            </div>
            <div className="scorecard-sheet-container px-30 py-10 flex-column gap-8">
              {scorecard.score_details.set_scores.map((row, index) => {
                return (
                  <div className="set-grid" key={`scorecard-sheet-${index}`}>
                    <p
                      className="font-large"
                      style={{
                        fontWeight: `${
                          row.team_A_points > row.team_B_points ? "700" : "300"
                        }`,
                        textAlign: "center",
                      }}
                    >
                      {row.team_A_points}
                    </p>
                    <p
                      className="font-small bold"
                      style={{ color: "#AFAFAF", textAlign: "center" }}
                    >
                      SET {index + 1}
                    </p>
                    <p
                      className="font-large"
                      style={{
                        fontWeight: `${
                          row.team_B_points > row.team_A_points ? "700" : "300"
                        }`,
                        textAlign: "center",
                      }}
                    >
                      {row.team_B_points}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="snip-entry-container flex-row align-items-center justify-content-space-between">
              <div className="snip-entry flex-row gap-4 align-items-center ">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-calendar.svg"
                  alt="calendar"
                  width={12}
                  height={12}
                />
                <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                  {scorecard.date}
                </p>
              </div>
              <div className="dot"></div>
              <div className="snip-entry flex-row gap-4 align-items-center">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618123849-clock.svg"
                  alt="clock"
                  width={12}
                  height={12}
                />
                <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                  {scorecard.duration}
                </p>
              </div>
              <div className="dot"></div>
              <div className="snip-entry flex-row gap-4 align-items-center">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240618190709-people.svg"
                  alt="people"
                  width={20}
                  height={20}
                />
                <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
                  Mixed Doubles
                </p>
              </div>
            </div>
          </div>
        )}
        {messageTimer > 0 && !share && (
          <div className="record-message-container px-18 py-8 flex-row gap-18">
            <p className="font-xsmall bold" style={{ color: "#AFAFAF" }}>
              This recording will be valid till 7 days i.e.{" "}
              <span style={{ color: "#ffffff" }}>17/12/24</span>. You won’t be
              able to access it here afterwards.
            </p>
            <p className="timer-container font-xsmall bold">{messageTimer}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Highlights;
