export const AVATAR_IMAGES = [
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003651-14.png",
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003651-13.png",
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003651-12.png",
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003651-9.png",
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003651-8.png",
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003651-7.png",
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003650-6.png",
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003650-5.png",
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003650-4.png",
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003650-3.png",
  "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240712003650-2.png",
];
