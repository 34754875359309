import React, { useEffect } from "react";
import "./ProfileInfo.scss";
import CountryCodeSelector from "../CountryCodeSelector/CountryCodeSelector";
import { UserProfile } from "../../models/User";
import { useSelector } from "react-redux";
import { selectUserEmail } from "../../redux/user/userSelectors";

interface ProfileInfoProps {
  details: UserProfile;
  editDetail: (key: string, value: string) => void;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ details, editDetail }) => {
  const numbers = Array.from({ length: 76 }, (_, i) => i + 5);
  const userEmail = useSelector(selectUserEmail);

  useEffect(() => {
    scrollAge(parseInt(details.age) || 24);
  }, [details]);

  const scrollAge = (age: number | null) => {
    if (age != null) {
      const ageContainer = document.getElementById("scroll-age-container");
      const ageElem = document.getElementById(`age-${age}`);
      if (ageElem && ageContainer) {
        const left = ageElem.offsetLeft;
        ageContainer.scrollTo({ left: left - ageContainer.clientWidth / 2 });
      }
    }
  };

  return (
    <div className="profile-info-container">
      <div className="info-header bold">Personal Information</div>
      <input
        value={details.first_name}
        onChange={(e) => editDetail("first_name", e.target.value)}
        className="input-name"
        placeholder="Enter your first name"
      />

      <input
        value={details.last_name}
        onChange={(e) => editDetail("last_name", e.target.value)}
        className="input-name"
        placeholder="Enter your last name"
      />

      <div className="user-input-container">
        <CountryCodeSelector
          countryCode={details.country_code}
          disabled={true}
          customClass="profile-country-code"
        />
        <input
          value={details.phone_number}
          onChange={(e) => editDetail("phone_number", e.target.value)}
          className="phonenumber"
          placeholder="Enter phone number"
          disabled
        />
      </div>

      <input
        value={details.email}
        onChange={(e) => editDetail("email", e.target.value)}
        className="input-name"
        placeholder="E-mail"
        disabled={!!userEmail}
      />
      <div className="info-desc">
        <div className="left-info-desc">Age</div>
        <div className="right-info-desc">Optional</div>
      </div>

      <div className="scrollable-numbers" id="scroll-age-container">
        {numbers.map((number) => (
          <div
            key={number}
            id={`age-${number}`}
            className={`number ${
              details.age === number.toString() ? "selected" : ""
            }`}
            onClick={() => editDetail("age", number.toString())}
          >
            {number}
          </div>
        ))}
      </div>
      <div className="info-desc">
        <div className="left-info-desc">Gender</div>
      </div>
      <div className="user-gender-container">
        <div
          className={`user-gender ${details.gender === "M" ? "selected" : ""}`}
          onClick={() => editDetail("gender", "M")}
        >
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240701150840-Group+1000004664.svg"
            alt="male-sign"
            className="gender-sign"
          />
          <p className="gender-text">M</p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620200839-User+Male.svg"
            className="camera"
            alt="male"
          />
        </div>
        <div
          className={`user-gender ${details.gender === "F" ? "selected" : ""}`}
          onClick={() => editDetail("gender", "F")}
        >
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240701150848-Group+1000004665.svg"
            alt="female-sign"
            className="gender-sign"
          />
          <p className="gender-text">F</p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240620200839-Female+User.svg"
            className="camera"
            alt="female"
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
