import React, { useState } from "react";
import "./ShareHighlights.scss";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

interface ShareHighlightsProps {
  toggleShare: () => void;
}

const ShareHighlights: React.FC<ShareHighlightsProps> = ({ toggleShare }) => {
  const [url, _setUrl] = useState(window.location.href);
  const [title, _setTitle] = useState("Check this out!!");

  return (
    <div className="share-options-container px-32 py-8 flex-column gap-24">
      <div className="flex-row justify-content-center align-items-center position-relative">
        <p className="font-large bolder" style={{ color: "#000000" }}>
          Share
        </p>
        <img
          className="close-share"
          onClick={toggleShare}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-close+2.svg"
          alt="close"
          width={10}
          height={10}
        />
      </div>
      <div className="flex-row gap-20 flex-wrap justify-content-space-between">
        <div
          className="flex-column gap-6"
          onClick={() => navigator.clipboard.writeText(url)}
        >
          <div className="grey-highlight">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-copy_link.svg"
              alt="link"
              width={22}
              height={22}
            />
          </div>
          <p className="font-xsmall bold" style={{ color: "#3A3A3A" }}>
            Copy Link
          </p>
        </div>
        <a
          href={`https://www.instagram.com/?url=${encodeURIComponent(url)}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <div className="flex-column gap-6 align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-insta.svg"
              alt="insta"
              width={42}
              height={42}
            />
            <p className="font-xsmall bold" style={{ color: "#3A3A3A" }}>
              Instagram
            </p>
          </div>
        </a>
        <WhatsappShareButton url={url} title={title}>
          <div className="flex-column gap-6 align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-whatsapp_symbol.svg"
              alt="insta"
              width={42}
              height={42}
            />
            <p className="font-xsmall bold" style={{ color: "#3A3A3A" }}>
              Whatsapp
            </p>
          </div>
        </WhatsappShareButton>
        <TwitterShareButton url={url} title={title}>
          <div className="flex-column gap-6 align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-x_icon.svg"
              alt="x"
              width={42}
              height={42}
            />
            <p className="font-xsmall bold" style={{ color: "#3A3A3A" }}>
              X
            </p>
          </div>
        </TwitterShareButton>
        <TelegramShareButton url={url} title={title}>
          <div className="flex-column gap-6 align-items-center">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-telegram_symbol.svg"
              alt="telegram"
              width={42}
              height={42}
            />
            <p className="font-xsmall bold" style={{ color: "#3A3A3A" }}>
              Telegram
            </p>
          </div>
        </TelegramShareButton>
        <div className="flex-column gap-6">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624162818-text.svg"
            alt="text"
            width={42}
            height={42}
          />
          <p
            className="font-xsmall bold center-text"
            style={{ color: "#3A3A3A" }}
          >
            Text
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShareHighlights;
