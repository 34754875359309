import React, { useEffect, useState } from "react";
import "./RallyAnalysis.scss";
import { getTotalTime, timeDisplay } from "../../utils/Helper";
import { MatchAnalyticStats, TeamAnalyticsStats } from "../../models/Analytics";
import NoAnalyticsData from "../NoAnalyticsData/NoAnalyticsData";

interface RallyAnalysisProps {
  noData: boolean;
  matchStats: MatchAnalyticStats | undefined;
}

const RallyAnalysis: React.FC<RallyAnalysisProps> = ({
  matchStats,
  noData,
}) => {
  const [mini, setMini] = useState(true);
  const [stats, setStats] = useState({
    average: matchStats?.avg_rally_duration || 0,
    duration: {
      total: getTotalTime(matchStats?.total_recording_time || ""),
      shortest: matchStats?.shortest_rally_duration || 0,
      longest: matchStats?.longest_rally_duration || 0,
      average: matchStats?.avg_rally_duration || 0,
    },
    length: {
      total: matchStats?.total_rally_length || 0,
      shortest: matchStats?.shortest_rally_length || 0,
      longest: matchStats?.longest_rally_length || 0,
      average: matchStats?.avg_rally_length || 0,
    },
  });

  useEffect(() => {
    setStats({
      average: matchStats?.avg_rally_duration || 0,
      duration: {
        total: getTotalTime(matchStats?.total_recording_time || ""),
        shortest: matchStats?.shortest_rally_duration || 0,
        longest: matchStats?.longest_rally_duration || 0,
        average: matchStats?.avg_rally_duration || 0,
      },
      length: {
        total: matchStats?.total_rally_length || 0,
        shortest: matchStats?.shortest_rally_length || 0,
        longest: matchStats?.longest_rally_length || 0,
        average: matchStats?.avg_rally_length || 0,
      },
    });
  }, [matchStats]);

  return (
    <div className="rally-content flex-column gap-16">
      <div
        className="top-row flex-row align-items-center gap-16"
        onClick={() => setMini((val) => !val)}
      >
        <div className="logo-container align-items-center flex-row justify-content-center">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240705111510-Group+1000004707.svg"
            alt="shot"
            width={34}
            height={34}
          />
        </div>
        <div className="flex-column gap-4 flex-1">
          <p className="title font-large bold" style={{ color: "#222222" }}>
            Rally Analysis
          </p>
          <p className="title font-mid " style={{ color: "#AFAFAF" }}>
            {noData ? "No Data" : "Rally duration and length"}
          </p>
        </div>
        <div
          className="flex-row align-items-center justify-content-center"
          style={{ height: "100%" }}
        >
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240622171408-top-black-row.svg"
            alt="top-row"
            width={12}
            className="px-8"
            style={{ transform: `${mini ? "rotate(180deg)" : "rotate(0deg)"}` }}
          />
        </div>
      </div>
      {!mini &&
        (noData ? (
          <NoAnalyticsData />
        ) : (
          <>
            <div className="separator"></div>
            <div
              className="px-10 py-5 flex-row align-items-center justify-content-space-between"
              style={{
                background: "#EEEEEE",
                borderRadius: "10px",
              }}
            >
              <div className="flex-row gap-6 align-items-center">
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240623085231-Countdown.svg"
                  alt="countdown"
                  width={40}
                  height={40}
                />
                <p className="font-xsmall bold" style={{ color: "#000000" }}>
                  Avg. shots/min
                </p>
              </div>
              <p className="font-large bolder" style={{ color: "#4E94FF" }}>
                {stats.average}
              </p>
            </div>
            <p className="font-xlarge bolder" style={{ color: "#222222" }}>
              Rally Details
            </p>
            <div className="flex-column gap-10">
              <div className="flex-row justify-content-space-between">
                <p className="font-xsmall bold" style={{ color: "#000000" }}>
                  Duration
                </p>
                <p className="font-xsmall bold" style={{ color: "#CACACA" }}>
                  {Math.floor(stats.duration.total / 3600)}:
                  {Math.floor((stats.duration.total % 3600) / 60)}{" "}
                  {Math.floor(stats.duration.total / 3600) > 1 ? "hrs" : "hr"}
                </p>
              </div>
              <div className="line-indicator flex-row align-items-center">
                <div
                  className="longest-duration"
                  style={{
                    width: `${
                      (stats.duration.longest * 100) / stats.duration.total
                    }px`,
                  }}
                ></div>
                <div
                  className="average-duration"
                  style={{
                    width: `${
                      (stats.duration.average * 100) / stats.duration.total
                    }px`,
                  }}
                ></div>
                <div
                  className="shortest-duration"
                  style={{
                    width: `${
                      (stats.duration.shortest * 100) / stats.duration.total
                    }px`,
                  }}
                ></div>
              </div>
              <div className="flex-row justify-content-space-between">
                <div className="flex-column">
                  <p
                    className="font-xxlarge bolder"
                    style={{ color: "#73C58A" }}
                  >
                    {timeDisplay(stats.duration.longest, "short")}
                  </p>
                  <p className="font-xsmall bold" style={{ color: "#5F5F5F" }}>
                    Longest
                  </p>
                </div>
                <div className="flex-column">
                  <p
                    className="font-xxlarge bolder"
                    style={{ color: "#000000" }}
                  >
                    {timeDisplay(stats.duration.average, "short")}
                  </p>
                  <p className="font-xsmall bold" style={{ color: "#5F5F5F" }}>
                    Average
                  </p>
                </div>
                <div className="flex-column">
                  <p
                    className="font-xxlarge bolder"
                    style={{ color: "#757575" }}
                  >
                    {timeDisplay(stats.duration.shortest, "short")}
                  </p>
                  <p className="font-xsmall bold" style={{ color: "#5F5F5F" }}>
                    Shortest
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-column gap-10">
              <div className="flex-row justify-content-space-between">
                <p className="font-xsmall bold" style={{ color: "#000000" }}>
                  Length (Shots)
                </p>
                <p className="font-xsmall bold" style={{ color: "#CACACA" }}>
                  {stats.length.total} Shots
                </p>
              </div>
              <div className="line-indicator flex-row align-items-center">
                <div
                  className="longest-duration"
                  style={{
                    width: `${
                      (stats.length.longest * 100) / stats.length.total
                    }px`,
                  }}
                ></div>
                <div
                  className="average-duration"
                  style={{
                    width: `${
                      (stats.length.average * 100) / stats.length.total
                    }px`,
                  }}
                ></div>
                <div
                  className="shortest-duration"
                  style={{
                    width: `${
                      (stats.length.shortest * 100) / stats.length.total
                    }px`,
                  }}
                ></div>
              </div>
              <div className="flex-row justify-content-space-between">
                <div className="flex-column">
                  <p
                    className="font-xxlarge bolder"
                    style={{ color: "#73C58A" }}
                  >
                    {stats.length.longest}
                  </p>
                  <p className="font-xsmall bold" style={{ color: "#5F5F5F" }}>
                    Longest
                  </p>
                </div>
                <div className="flex-column">
                  <p
                    className="font-xxlarge bolder"
                    style={{ color: "#000000" }}
                  >
                    {stats.length.average}
                  </p>
                  <p className="font-xsmall bold" style={{ color: "#5F5F5F" }}>
                    Average
                  </p>
                </div>
                <div className="flex-column">
                  <p
                    className="font-xxlarge bolder"
                    style={{ color: "#757575" }}
                  >
                    {stats.length.shortest}
                  </p>
                  <p className="font-xsmall bold" style={{ color: "#5F5F5F" }}>
                    Shortest
                  </p>
                </div>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default RallyAnalysis;
