import React, { useEffect, useState } from "react";
import "./EnterOTP.scss";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtp } from "../../redux/user/userActions";
import { useNavigate } from "react-router-dom";
import PATHS from "../../urls/Path";
import {
  isUserLoading,
  selectAuthToken,
  selectUserAvatar,
  selectUserName,
} from "../../redux/user/userSelectors";
import { getCookie } from "../../services/cookieService";
interface EnterOTPProps {
  phoneNumber: string;
  countryCode: string;
  currentOtp: string;
  editNumber: () => void;
}

const EnterOTP: React.FC<EnterOTPProps> = ({
  phoneNumber,
  countryCode,
  currentOtp,
  editNumber,
}) => {
  const [timer, setTimer] = useState(15);
  const [otp, setOTP] = useState<string[]>(["", "", "", ""]);
  const [otpIndex, setOTPIndex] = useState<number>(-1);
  const [otpValid, setOtpValid] = useState<boolean>(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const otpLoading = useSelector(isUserLoading);
  const authToken = useSelector(selectAuthToken);
  const username = useSelector(selectUserName);
  const userAvatar = useSelector(selectUserAvatar);

  useEffect(() => {
    let timesRun = 15;
    const interval = setInterval(() => {
      timesRun--;
      if (timesRun < 0) {
        clearInterval(interval);
      } else {
        setTimer((val) => val - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (otpFilled()) {
      submitOTP(otp.join(""));
    }
  }, [otp]);

  useEffect(() => {
    if (!otpLoading && otpFilled()) {
      if (!authToken) {
        setOtpValid(false);
      }
    }
  }, [otpLoading]);

  const otpFilled = () => {
    return otp.filter((val) => val === "" || val == null).length === 0;
  };

  const clearOTP = () => {
    setOTP(["", "", "", ""]);
  };

  const otpEntrySelected = (index: number) => {
    setOTPIndex(index);
    setTimeout(() => {
      document.getElementById(`otp-input-${index}`)?.focus();
    }, 100);
    if (otpFilled() && !otpValid) {
      clearOTP();
    }
  };

  const handleOTPEntry = (val: string, index: number) => {
    setOTP((arr) => {
      arr[index] = val[0];
      return [...arr];
    });
    if (val !== "") {
      setOTPIndex(index + 1);
      setTimeout(() => {
        document.getElementById(`otp-input-${index + 1}`)?.focus();
      }, 100);
    }
  };

  const submitOTP = (otp: string) => {
    dispatch(
      verifyOtp({
        country_code: countryCode,
        phone_number: phoneNumber,
        otp,
        court_id: parseInt(getCookie("MATCH_COURT_ID") || ""),
      }),
    );
  };

  const handleDelete = (event: React.KeyboardEvent, index: number) => {
    const key = event.keyCode || event.charCode;
    if ((key == 8 || key == 46) && index > 0) {
      setOTPIndex(index - 1);
      setTimeout(() => {
        document.getElementById(`otp-input-${index - 1}`)?.focus();
      }, 100);
    }
  };

  return (
    <>
      <div className="enter-otp-container">
        <p className="title">JUST A QUICK VERIFICATION</p>
        <p className="subtitle">{"AND YOU'RE DONE!"}</p>
        <p className="placeholder">Enter the 4 digit code sent to</p>
        <div className="mobile-value-container">
          <p className="mobile-value">
            {countryCode} {phoneNumber}
          </p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240612170253-black-sign.svg"
            alt="black-sign"
            width={8}
            height={8}
            onClick={editNumber}
          />
        </div>
        <div className="otp-container">
          <div
            className={`otp-blank ${
              (otpIndex === 0 || otp[0] !== "") && "active-otp-blank"
            } ${!otpValid && "wrong-otp-blank"}`}
            onClick={() => otpEntrySelected(0)}
          >
            {otpIndex !== 0 && otp[0] === "" && <div className="no-val"></div>}
            {(otpIndex === 0 || otp[0] !== "") && (
              <input
                type="number"
                id="otp-input-0"
                className="otp-value"
                value={otp[0]}
                onChange={(e) => handleOTPEntry(e.target.value, 0)}
                onKeyDown={(e) => handleDelete(e, 0)}
                autoComplete="off"
              />
            )}
          </div>
          <div
            className={`otp-blank ${
              (otpIndex === 1 || otp[1] !== "") && "active-otp-blank"
            } ${!otpValid && "wrong-otp-blank"}`}
            onClick={() => otpEntrySelected(1)}
          >
            {otpIndex !== 1 && otp[1] === "" && <div className="no-val"></div>}
            {(otpIndex === 1 || otp[1] !== "") && (
              <input
                type="number"
                id="otp-input-1"
                className="otp-value"
                value={otp[1]}
                onChange={(e) => handleOTPEntry(e.target.value, 1)}
                onKeyDown={(e) => handleDelete(e, 1)}
                autoComplete="off"
              />
            )}
          </div>
          <div
            className={`otp-blank ${
              (otpIndex === 2 || otp[2] !== "") && "active-otp-blank"
            } ${!otpValid && "wrong-otp-blank"}`}
            onClick={() => otpEntrySelected(2)}
          >
            {otpIndex !== 2 && otp[2] === "" && <div className="no-val"></div>}
            {(otpIndex === 2 || otp[2] !== "") && (
              <input
                type="number"
                id="otp-input-2"
                className="otp-value"
                value={otp[2]}
                onChange={(e) => handleOTPEntry(e.target.value, 2)}
                onKeyDown={(e) => handleDelete(e, 2)}
                autoComplete="off"
              />
            )}
          </div>
          <div
            className={`otp-blank ${
              (otpIndex === 3 || otp[3] !== "") && "active-otp-blank"
            } ${!otpValid && "wrong-otp-blank"}`}
            onClick={() => otpEntrySelected(3)}
          >
            {otpIndex !== 3 && otp[3] === "" && <div className="no-val"></div>}
            {(otpIndex === 3 || otp[3] !== "") && (
              <input
                type="number"
                id="otp-input-3"
                className="otp-value"
                value={otp[3]}
                onChange={(e) => handleOTPEntry(e.target.value, 3)}
                onKeyDown={(e) => handleDelete(e, 3)}
                autoComplete="off"
              />
            )}
          </div>
        </div>
        <p className="error-otp">
          {!otpValid && "Incorrect OTP. Please try again"}
        </p>
        <div className="resend-container">
          <p className={`resend-text ${timer === 0 && "active-resend"}`}>
            Resend Code
          </p>
          {timer > 0 && (
            <p className="timer-value">
              00: {timer < 10 && "0"}
              {timer}
            </p>
          )}
        </div>
      </div>
      {/* <div className="otp-message-container px-18 py-8 flex-row gap-18">
        <p
          className="font-small bold full-width"
          style={{ color: "#AFAFAF", textAlign: "center" }}
        >
          Your OTP is <span style={{ color: "#ffffff" }}>{currentOtp}</span>
        </p>
      </div> */}
    </>
  );
};

export default EnterOTP;
